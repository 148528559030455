import React, { useEffect, useState } from "react"
import Select from "react-select"

import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Row,
  Col,
  Label,
  Input,
  navigate,
  Toast,
  ToastHeader,
  ToastBody,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
// import { addNewClient } from "store/actions"
import Swal from "sweetalert2"
import {
  addNewClient,
  getActiveClientProjectUnit,
} from "helpers/fakebackend_helper"
import {
  addNewClientUser,
  getCities,
  getClientCode,
  getCountries,
  getStates,
} from "store/actions"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
const AddNewClientUser = props => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [toast2, setToast2] = useState(false)
  const [customerId, setCustomerId] = useState()
  const [clientProjectUnits, setClientProjectUnits] = useState([])

  document.title = "Add New Client User"
  const options = (clientProjectUnits || []).map(item => ({
    value: item.id,
    label: `${item.unit_code} - ${item.project_name}`,
  
  }))

  const dispatch = useDispatch()

  const [selectedFile, setSelectedFile] = useState()
  const [imageURL, setImageURL] = useState(null)
  const [imageTouched, setImageTouched] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      image: "",
      client_id: "",
      client_project_unit_id: "",
      user_type: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      customer_code: "",
      email_id: "",
      mobile_no: "",
      designation: "",
      login_username: "",
      // login_password: "",
      tagged_project_units: "",
    },
    validationSchema: Yup.object().shape({
      image: Yup.string(),
      user_type: Yup.string().required("Please Select User Type"),
      first_name: Yup.string().required("Please Enter Your first Name"),
      middle_name: Yup.string(),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email_id: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Email Address"),
      mobile_no: Yup.string()
        .matches(
          /^[0-9]{10,12}$/,
          "Mobile Number must be between 10 to 12 digits"
        )
        .required("Please Enter Mobile Number "),
      designation: Yup.string().required("Please Enter Designation"),

      login_username: Yup.string().required("Please enter Login Username"),
      // login_password: Yup.string().required("Please enter Login Password"),
    }),

    onSubmit: async values => {
      dispatch(addNewClientUser(values, props.router.navigate))
    },
  })

  const navigate = useNavigate()

  useEffect(() => {
    //check if the current path includes 'edit-customer'
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedFile(file)
    validation.setFieldValue("image", selectedFile)
    if (typeof file === "object") {
      setImageURL(URL.createObjectURL(file))
    } else {
      setImageURL(file)
    }
  }

  useEffect(() => {
    if (selectedFile && typeof selectedFile === "object") {
      if (validation.touched.image && !imageTouched) {
        validation.setFieldValue("image", selectedFile)
        setImageTouched(true)
      }
    }
  }, [
    selectedFile,
    validation.touched.image,
    imageTouched,
    validation.setFieldValue,
  ])

  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      const url = new URL(window.location.href)
      const clientId = url.pathname.split("/add-new-client-user/")[1]
      setCustomerId(clientId)
      const response = await getActiveClientProjectUnit(clientId)
      setClientProjectUnits(response.data)
    }

    fetchData()
  }, [])
  useEffect(() => {
    if (customerId) {
      validation.setFieldValue("client_id", customerId)
    }
  }, [customerId])

  useEffect(() => {
    dispatch(getClientCode())
  }, [dispatch])
  return (
    <div className="page-content">
      <Row>
        <Col>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey", marginRight: "15px" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            <span
              style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              {" "}
              New Client User
            </span>
          </div>
        </Col>
        <Col>
          <Breadcrumbs title="Clients / Add New Client User" />
        </Col>
      </Row>
      <div className="mt-3"></div>

      <Form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Card>
          <CardBody>
            <Row>
              <Col>
                <label
                  className="rounded-2"
                  style={{
                    width: "150px",
                    display: "flex",
                    // padding: "30px",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.10)",
                    flexDirection: "column",
                  }}
                >
                  <>
                    {imageURL ? (
                      <img
                        className="object-fit-fill rounded-2"
                        src={imageURL}
                        alt=""
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          maxHeight: "150px",
                          minHeight: "150px",
                        }}
                      />
                    ) : (
                      <i
                        className="mdi mdi-image-outline"
                        style={{
                          fontSize: "60px",
                          padding: "30px",
                        }}
                      ></i>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </>
                </label>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="first_name">First Name*</Label>
                  <Input
                    type="text"
                    name="first_name"
                    className="form-control"
                    id="first_name"
                    placeholder="Enter First Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={
                      validation.touched.first_name &&
                      validation.errors.first_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.first_name &&
                  validation.errors.first_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.first_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="middle_name">Middle Name</Label>
                  <Input
                    type="text"
                    name="middle_name"
                    className="form-control"
                    id="middle_name"
                    placeholder="Enter Middle Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.middle_name || ""}
                    invalid={
                      validation.touched.middle_name &&
                      validation.errors.middle_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.middle_name &&
                  validation.errors.middle_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.middle_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col>
                <div className="mb-3">
                  <Label htmlFor="last_name">Last Name*</Label>
                  <Input
                    type="text"
                    name="last_name"
                    className="form-control"
                    id="last_name"
                    placeholder="Enter Last Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name || ""}
                    invalid={
                      validation.touched.last_name &&
                      validation.errors.last_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.last_name &&
                  validation.errors.last_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.last_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="email_id">Email *</Label>
                  <Input
                    type="text"
                    name="email_id"
                    className="form-control"
                    id="email_id"
                    placeholder="Enter Customer's Email Address"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email_id || ""}
                    invalid={
                      validation.touched.email_id && validation.errors.email_id
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email_id && validation.errors.email_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col>
                <div className="mb-3">
                  <Label htmlFor="mobile_no">Mobile No.*</Label>
                  <Input
                    type="text"
                    name="mobile_no"
                    className="form-control"
                    id="mobile_no"
                    placeholder="Enter Customer's Mobile Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.mobile_no || ""}
                    invalid={
                      validation.touched.mobile_no &&
                      validation.errors.mobile_no
                        ? true
                        : false
                    }
                  />
                  {validation.touched.mobile_no &&
                  validation.errors.mobile_no ? (
                    <FormFeedback type="invalid">
                      {validation.errors.mobile_no}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="designation">Designation*</Label>
                  <Input
                    type="text"
                    name="designation"
                    className="form-control"
                    id="designation"
                    placeholder="Enter Designation"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.designation || ""}
                    invalid={
                      validation.touched.designation &&
                      validation.errors.designation
                        ? true
                        : false
                    }
                  />
                  {validation.touched.designation &&
                  validation.errors.designation ? (
                    <FormFeedback type="invalid">
                      {validation.errors.designation}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <div className="mb-3">
                  <Label htmlFor="user_type">User Type*</Label>
                  <select
                    name="user_type"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.user_type}
                    className={`form-select ${
                      validation.touched.user_type &&
                      validation.errors.user_type
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {!validation.values.user_type && (
                      <option>Select User Type</option>
                    )}
                    {/* <option value="1">Admin</option> */}
                    <option value="2">Manager</option>
                  </select>
                  {validation.touched.user_type &&
                  validation.errors.user_type ? (
                    <FormFeedback type="invalid">
                      {validation.errors.user_type}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {String(validation.values.user_type) === "2" && (
                <Col lg="4">
                  <div className="mb-3">
                    <Label className="control-label">
                      Select Client Projects Unit*
                    </Label>
                    <Select
                      placeholder="Please Select Client Projects Unit"
                      options={options}
                      isMulti={true}
                      id="tagged_project_units"
                      name="tagged_project_units"
                      onChange={selectedOptions => {
                        const selectedValues = selectedOptions.map(
                          option => option.value
                        )
                        validation.setFieldValue(
                          "tagged_project_units",
                          selectedValues.join(",")
                        )
                      }}
                      value={options.filter(option =>
                        ((validation.values.tagged_project_units || "") + "")
                          .split(",")
                          .includes(option.value.toString())
                      )}
                      // value={validation.values.tagged_project_units}
                      onBlur={validation.handleBlur}
                      className={`${
                        validation.touched.tagged_project_units &&
                        validation.errors.tagged_project_units
                          ? " form-control is-invalid "
                          : ""
                      }`}
                    />
                    {validation.touched.tagged_project_units &&
                      validation.errors.tagged_project_units && (
                        <FormFeedback type="invalid">
                          {validation.errors.tagged_project_units}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className="mb-4">Sign in details</CardTitle>
            <Row>
              <Col lg='4'>
                <div className="mb-3">
                  <Label htmlFor="login_username">Login Username*</Label>
                  <Input
                    type="text"
                    name="login_username"
                    className="form-control"
                    id="login_username"
                    placeholder="Enter Login Username"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.login_username || ""}
                    invalid={
                      validation.touched.login_username &&
                      validation.errors.login_username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.login_username &&
                  validation.errors.login_username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.login_username}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-md">
                    Submit{" "}
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </div>
  )
}

export default withRouter(AddNewClientUser)

AddNewClientUser.propTypes = {
  history: Proptypes.object,
}
