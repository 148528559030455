import React, { useEffect, useState } from "react"
import formatNumberWithCommas from "components/Common/CommaSeparator"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  getProjectUnitDetail,
  getProposalDetail,
  getProposals,
} from "store/actions"
import { AcceptProposal } from "helpers/fakebackend_helper"
import LargeModal from "./rejectApprovalModal"
import Swal from "sweetalert2"

const ProposalView = () => {
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  document.title = "Proposal"

  const path = useLocation()
  const [modalLarge1, setModalLarge1] = useState(false)
  const toggleModalLarge1 = () => setModalLarge1(!modalLarge1)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const projectUnitDetailState = state =>
    state.proposals.proposalDetail.proposal
  const data = useSelector(projectUnitDetailState) || []
  const [proposalId, setProposalId] = useState()

  const fetchData = () => {
    const proposalId = path.pathname.split("/view-vendor-proposal/")[1]
    setProposalId(proposalId)
    dispatch(getProposalDetail(proposalId))
  }

  useEffect(() => {
    fetchData()
  }, [dispatch, useLocation])

  const handleAccept = async status => {
    let loadingSwal
    const value = {
      proposal_id: proposalId,
      proposal_status: 1,
    }

    try {
      loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const response = await AcceptProposal(value)

      if (response && response.status === true) {
        loadingSwal.close()

        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Proposal has been Approved",
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(getProposalDetail(proposalId))
          }
        })
      } else {
        loadingSwal.close()

        Swal.fire({
          title: "Failure",
          icon: "error",
          text: " Proposal Approval request has not been Submitted.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      if (loadingSwal) {
        loadingSwal.close()
      }

      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response?.data?.message ||
          "An error occurred while Submitting Request.",
      })
    }
  }

  const handleReject = () => {
    toggleModalLarge1()
  }
  const printInvoice = () => {
    window.print()
  }
  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge1}
        toggle={toggleModalLarge1}
        id={proposalId}
      />

      <div className="page-content">
        <Row className="no-print">
          <Col>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey", marginRight: "15px" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              <span
                style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
              >
                {" "}
                Vendor Proposal
              </span>
            </div>
          </Col>
        </Row>
        <Container fluid={true}>
          <Card>
            <CardBody>
              {data && (
                <Row>
                  <Col>
                    <CardTitle className="mb-3">
                      {" "}
                      <div className="d-flex gap-2 mb-3">
                        <i
                          className="bx bx-receipt font-size-20"
                          style={{ color: "#3366cc", marginTop: "5px" }}
                        ></i>
                        <span
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          {data.vendor_name}{" "}
                          {data.vendor_code && `(${data.vendor_code})`}
                        </span>
                      </div>
                    </CardTitle>
                    <CardSubtitle className="mb-3">
                      {" "}
                      <Row>
                        <Col>
                          <span>Status : </span>
                          <span
                            style={{
                              color:
                                data && data.proposal_status === 1
                                  ? "green"
                                  : data.proposal_status === 0
                                  ? "blue"
                                  : data.proposal_status === 2
                                  ? "red"
                                  : data.proposal_status === 3
                                  ? "orange"
                                  : "black",
                              fontWeight: "500",
                              fontSize: "13px",
                            }}
                          >
                            {data && data.proposal_status === 1
                              ? "Approved"
                              : data.proposal_status === 2
                              ? "Rejected"
                              : data.proposal_status === 3
                              ? "Billed"
                              : "Pending"}
                          </span>
                        </Col>
                        <Col>
                          <span>
                            {data && data.proposal_status === 1
                              ? "Approved By : "
                              : data.proposal_status === 2
                              ? "Rejected By : "
                              : data.proposal_status === 3
                              ? "Approved By : "
                              : null}
                          </span>
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "13px",
                            }}
                          >
                            {data && data.approved_by_name}
                          </span>
                          <br />
                          <span>
                            {data && data.proposal_status === 1
                              ? "Approved On : "
                              : data.proposal_status === 2
                              ? "Rejected On : "
                              : data.proposal_status === 3
                              ? "Approved On : "
                              : null}
                          </span>
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "13px",
                            }}
                          >
                            {data && data.formatted_approved_on}
                          </span>
                        </Col>
                      </Row>
                    </CardSubtitle>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={4}>
                        <Label className="form-label ">Project</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.project_name}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Proposal No.</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.proposal_no}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label ">Proposal Date</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.formatted_proposal_date}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={4}>
                        <Label className="form-label">Proposal Purpose</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.proposal_title}
                        </p>
                      </Col>

                      <Col lg={4}>
                        <Label className="form-label ">
                          Goods & Services Category
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.proposal_type}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label ">Taxable Amount</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.taxable_amount}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={4}>
                        <Label className="form-label">GST Rate(%)</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.gst_percentage}
                        </p>
                      </Col>

                      <Col lg={4}>
                        <Label className="form-label ">GST Amount</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.tax_amount}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Total Amount</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data?.invoice_amount}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Status</Label>
                        <p
                          style={{
                            fontWeight: 500,
                            color:
                              data.proposal_status === 0
                                ? "blue"
                                : data.proposal_status === 1
                                ? "green"
                                : data.proposal_status === 2
                                ? "red"
                                : data.proposal_status === 3
                                ? "orange"
                                : "black",
                          }}
                        >
                          {data.proposal_status === 0
                            ? "Pending"
                            : data.proposal_status === 1
                            ? "Approved"
                            : data.proposal_status === 2
                            ? "Rejected"
                            : data.proposal_status === 3
                            ? "Billed"
                            : "NA"}
                        </p>
                      </Col>
                      {data.proposal_status === 2 && (
                        <Col lg={4}>
                          <Label className="form-label">Rejected Reason</Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data?.reject_reason}
                          </p>
                        </Col>
                      )}
                    </Row>
                    {data.proposal_status === 0 &&
                      permission
                        .filter(
                          item =>
                            item.page === "approve-reject-vendor-bill" &&
                            item.access === 1
                        )
                        .map((item, index) => (
                          <Row key={index}>
                            <Col lg={12} className="text-end">
                              <button
                                className="btn btn-warning l-md me-2"
                                onClick={() => {
                                  const proposalFilePath =
                                    data.proposal_file_path
                                  if (proposalFilePath) {
                                    window.open(proposalFilePath, "_blank")
                                  }
                                }}
                              >
                                View Proposal
                              </button>

                              {data && data.proposal_status === 0 ? (
                                <>
                                  {permission
                                    .filter(
                                      item =>
                                        item.page ===
                                          "vendor-proposal-accept-reject" &&
                                        item.access === 1
                                    )
                                    .map((item, index) => (
                                      <button
                                        key={index}
                                        className="btn btn-success l-md me-2"
                                        onClick={() => {
                                          handleAccept()
                                        }}
                                      >
                                        Approve
                                      </button>
                                    ))}
                                  {permission
                                    .filter(
                                      item =>
                                        item.page ===
                                          "vendor-proposal-accept-reject" &&
                                        item.access === 1
                                    )
                                    .map((item, index) => (
                                      <button
                                        key={index}
                                        className=" btn btn-danger l-md"
                                        onClick={() => {
                                          handleReject()
                                        }}
                                      >
                                        Reject
                                      </button>
                                    ))}
                                </>
                              ) : null}
                            </Col>
                          </Row>
                        ))}
                  </Col>
                </Row>
              )}
            </CardBody>
            <div className="d-print-none mt-5">
              <div className="float-end">
                <Link
                  to="#"
                  onClick={printInvoice}
                  className="btn btn-success me-2"
                >
                  <i className="fa fa-print" />
                </Link>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProposalView
