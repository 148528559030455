import React, { useEffect } from "react"
import getChartColorsArray from "components/Common/ChartsDynamicColor"
import ReactEcharts from "echarts-for-react"
import * as Yup from "yup"

import { Col, CardBody, FormFeedback, Row } from "reactstrap"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { getTicketPriorityPieDigram } from "store/actions"

const TicketPriorityWisePieChart = () => {
  const dispatch = useDispatch()

  const dataColors =
    '["#FF0000", "#FF8C00", "#FFD700", "#32CD32"]'

  const PieEChartColors = getChartColorsArray(dataColors)

  const dataState = state => state.ticketsDashboard?.ticketPriorityPieDigram
  const data = useSelector(dataState) || []

  const validation = useFormik({
    initialValues: {
      filter: "This Year",
    },
    validationSchema: Yup.object({}),
  })

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: [
        "Critical",
        "High",
        "Medium",
        "Low",
       
      ],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: PieEChartColors,
    series: [
      {
        name: "Tickets",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: [
         
          {
            value:
              data && data.priority_one_count !== 0
                ? data.priority_one_count
                : null,
            name: "Critical",
          },
          {
            value:
              data && data.priority_two_count !== 0
                ? data.priority_two_count
                : null,
            name: "High",
          },
          {
            value:
              data && data.priority_three_count !== 0
                ? data.priority_three_count
                : null,
            name: "Medium",
          },
          {
            value:
              data && data.priority_four_count !== 0 ? data.priority_four_count : null,
            name: "Low",
          },
         
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  useEffect(() => {
    dispatch(getTicketPriorityPieDigram({ filter: validation.values.filter }))
  }, [validation.values.filter])
  return (
    <CardBody>
      <Row>
        <Col className="d-flex align-items-center">
          <h4 className="card-title mb-4">
            Statistic Analysis of Tickets By Priority
          </h4>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <select
              name="filter"
              className={`form-select ${
                validation.touched.filter && validation.errors.filter
                  ? "is-invalid"
                  : ""
              }`}
              onChange={validation.handleChange} // Ensure onChange is linked to handleChange
              onBlur={validation.handleBlur}
              value={validation.values.filter} // Ensure value is bound to Formik's values.filter
            >
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="This Year">This Year</option>
              <option value="Previous Week">Previous Week</option>
              <option value="Previous Month">Previous Month</option>
              <option value="Previous Year">Previous Year</option>
            </select>
            {validation.touched.filter && validation.errors.filter ? (
              <FormFeedback type="invalid">
                {validation.errors.filter}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Col>
        <ReactEcharts style={{ height: "350px" }} option={options} />
      </Col>
      <Col className="text-center">
        <b style={{ color: "gray", fontSize: "12px" }}>
          {validation.values.filter}
        </b>
      </Col>
    </CardBody>
  )
}

export default TicketPriorityWisePieChart
