import React, { useEffect } from "react"
import getChartColorsArray from "components/Common/ChartsDynamicColor"
import ReactEcharts from "echarts-for-react"
import * as Yup from "yup"

import { Col, CardBody, FormFeedback, Row } from "reactstrap"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { getTicketStatusPieDigram } from "store/actions"

const TicketStatusWisePieChart = () => {
  const dispatch = useDispatch()

  const dataColors =
    '["#56E289", "#56E2CF", "#56AEE2", "#5668E2", "#E2CF56", "#CF56E2", "#E256AE", "#E28956","#E25668"]'

  const PieEChartColors = getChartColorsArray(dataColors)

  const dataState = state => state.ticketsDashboard?.ticketStatusPieDigram
  const data = useSelector(dataState) || []

  const validation = useFormik({
    initialValues: {
      filter: "This Year",
    },
    validationSchema: Yup.object({}),
  })

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: [
        "New",
        "Assigned",
        "Submitted For Approval",
        "PO Stage",
        "Work-In-Progress",
        "Under Review",
        "Rework",
        "Closed",
      ],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: PieEChartColors,
    series: [
      {
        name: "Tickets",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: [
          {
            value: data && data.new_status !== 0 ? data.new_status : null,
            name: "New",
          },
          {
            value:
              data && data.assigned_status !== 0
                ? data.assigned_status
                : null,
            name: "Assigned",
          },
          {
            value:
              data && data.submit_for_approval_status !== 0
                ? data.submit_for_approval_status
                : null,
            name: "Submitted For Approval",
          },
          {
            value:
              data && data.po_stage_status !== 0
                ? data.po_stage_status
                : null,
            name: "PO Stage",
          },
          {
            value:
              data && data.work_in_progress_status !== 0 ? data.work_in_progress_status : null,
            name: "Work-In-Progress",
          },
          {
            value:
              data && data.under_review_status !== 0 ? data.under_review_status : null,
            name: "Under Review",
          },
          {
            value:
              data && data.rework_status !== 0 ? data.rework_status : null,
            name: "Rework",
          },
          {
            value:
              data && data.close_status !== 0 ? data.close_status : null,
            name: "Closed",
          },
          // {
          //   value:
          //     data && data.declined_status !== 0 ? data.declined_status : null,
          //   name: "Cancelled",
          // },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  useEffect(() => {
    dispatch(getTicketStatusPieDigram({ filter: validation.values.filter }))
  }, [validation.values.filter])
  return (
    <CardBody>
      <Row>
        <Col className="d-flex align-items-center">
          <h4 className="card-title mb-4">
            Statistic Analysis of Tickets By Status
          </h4>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <select
              name="filter"
              className={`form-select ${
                validation.touched.filter && validation.errors.filter
                  ? "is-invalid"
                  : ""
              }`}
              onChange={validation.handleChange} // Ensure onChange is linked to handleChange
              onBlur={validation.handleBlur}
              value={validation.values.filter} // Ensure value is bound to Formik's values.filter
            >
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="This Year">This Year</option>
              <option value="Previous Week">Previous Week</option>
              <option value="Previous Month">Previous Month</option>
              <option value="Previous Year">Previous Year</option>
            </select>
            {validation.touched.filter && validation.errors.filter ? (
              <FormFeedback type="invalid">
                {validation.errors.filter}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Col>
        <ReactEcharts style={{ height: "350px" }} option={options} />
      </Col>
      <Col className="text-center">
        <b style={{ color: "gray", fontSize: "12px" }}>
          {validation.values.filter}
        </b>
      </Col>
    </CardBody>
  )
}

export default TicketStatusWisePieChart
