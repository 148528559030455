import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"  // Import swal
import { getProjectFilterForCustomerIndex, getServiceRequestTicketList } from "store/actions"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const serviceTicketListState = state => state.ServiceTicket.serviceTicket.data
  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const ticketList = useSelector(serviceTicketListState) || []
  const allProjectState = state => state.CustomerIndex.projectFilter.project
  const projectList = useSelector(allProjectState) || []

  const [statusValue, setStatusValue] = useState("")
  const [project, setProject] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [serviceType, setServiceType] = useState("") // New state for service type

  // Handler for service type selection
  const handleServiceType = ele => {
    setServiceType(ele.value || "")
  }

  const handleSelectStatus = ele => {
    setStatusValue(ele.value || "")
  }

  const handleProject = ele => {
    let data = ele.value
    setProject(data || "")
  }

  const handleSearch = () => {
    // Validate that From Date is not greater than To Date
    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Date Range",
        text: "From Date should not be greater than To Date",
        confirmButtonText: "OK"
      })
      return
    }

    const value = {
      project_id: project,
      user_id: user.user_id,
      status: statusValue,
      from_date: fromDate,
      to_date: toDate,
      types_of_service: serviceType // Send service type with the request
    }
    dispatch(getServiceRequestTicketList(value))
  }

  useEffect(() => {
    if (user) {
      dispatch(getProjectFilterForCustomerIndex(user.user_id))
    }
  }, [dispatch, user])

  return (
    <React.Fragment>
      <Col xxl={2} lg={6} className="mb-3">
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Status"
          onChange={e => handleSelectStatus(e.target)}
        >
          <option disabled>Status</option>
          <option value="">All</option>
          <option value="1">New</option>
          <option value="2">Assigned</option>
          <option value="3">Submitted for Approval</option>
          <option value="4">Work in Progress</option>
          <option value="5">Under Review</option>
          <option value="6">Rework</option>
          <option value="7">Closed</option>
          <option value="8">PO Stage</option>
          <option value="9">Processed By Project Manager</option>
          <option value="10">Additional Requests</option>
          <option value="11">Estimate Rejected</option>
          <option value="12">Sent to Project Manager for Approval</option>
        </select>
      </Col>

      <Col xxl={2} lg={4} className="mb-3">
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleProject(e.target)}
        >
          <option value="">Select Project</option>
          {projectList &&
            projectList.map((project, index) => (
              <option key={index} value={project.id}>
                {project.project_name}
              </option>
            ))}
        </select>
      </Col>

      <Col xxl={2} lg={4} className="mb-3">
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Service Type"
          onChange={e => handleServiceType(e.target)}
        >
          <option value="">Select Service Type</option>
          <option value="1">Looking for Additional Project Units</option>
          <option value="2">Facing Maintenance Issues</option>
          <option value="4">Finance,Accounting,Compliance and Legal Issue</option>
        </select>
      </Col>

      {/* Date Picker for From Date */}
      <Col xxl={2} lg={4} className="mb-3">
        <input
          type="date"
          className="form-control mb-3 mb-xxl-0"
          value={fromDate}
          onChange={e => setFromDate(e.target.value)}
          placeholder="From Date"
        />
      </Col>

      {/* Date Picker for To Date */}
      <Col xxl={2} lg={4} className="mb-3">
        <input
          type="date"
          className="form-control mb-3  mb-xxl-0"
          value={toDate}
          onChange={e => setToDate(e.target.value)}
          placeholder="To Date"
        />
      </Col>

      <Col xxl={2} lg={4} className="mb-3">
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default JobListGlobalFilter
