const getStatusColorTicket = (status) => {
  switch (status) {
    case 6:
      return "#E256AE";
    case 1:
      return "#56E289";
    case 2:
      return "#56E2CF";
    case 3:
      return "#56AEE2";
    case 4:
      return "#E2CF56";
    case 5:
      return "#CF56E2";
    case 7:
      return "#E28956";
    case 8:
      return "#5668E2";
    case 9:
      return "red";
    case 10:
      return "pink";
    case 11:
      return "#f46a6a"; 
    case 12:
      return "#17c7c0"; 
    default:
      return "black";
  }
};

export default getStatusColorTicket;
