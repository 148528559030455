import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./receiptListContainer"
import { API_URL } from "helpers/url_helper"
import LargeModal from "./EmailModal/emailModal"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { deleteBillingRecord, getBillingReceiptList } from "store/actions"
import ReceiptPreviewModal from "../ReceiptPreview/receiptPreviewModal"

const Receipt = () => {
  const [isLoading, setLoading] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [modalLarge, setModalLarge] = useState(false)
  const [modalLarge1, setModalLarge1] = useState(false)
  const [customerId, setCustomerID] = useState()
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const toggleModalLarge1 = () => setModalLarge1(!modalLarge1)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const navigate = useNavigate()
  document.title = "All Receipts"

  const dispatch = useDispatch()
  const billingReceiptState = state => state.billing.receiptList.data

  const BillingList = useSelector(billingReceiptState) || []

  useEffect(() => {
    if (user) {
      dispatch(getBillingReceiptList({ user_id: user.user_id }))
    }
  }, [user])

  const [receipt, setReceipt] = useState()
  const [Id, setId] = useState()
  const handleReceiptModal = (receipt, Id) => {
    setId(Id)
    toggleModalLarge()
  }

  const handleDownloadReceipt = paymentId => {
    // Construct the URL for download
    const downloadUrl = `${API_URL}/api/download-receipt-invoice-pdf/${paymentId}`

    // Create an anchor element
    const anchorElement = document.createElement("a")
    anchorElement.href = downloadUrl
    anchorElement.target = "_blank" // Open in a new tab
    anchorElement.download = "receipt.pdf"

    // Append the anchor element to the document body (required in some browsers)
    document.body.appendChild(anchorElement)

    // Trigger a click event on the anchor element
    anchorElement.click()

    // Clean up: remove the anchor element from the document body
    document.body.removeChild(anchorElement)
  }

  const [receipt_no, setReceiptNo] = useState()
  const sendMailModal = (value, receipt_no, receiptData) => {
    setCustomerID(value)
    setReceiptNo(receipt_no)
    toggleModalLarge1()
    setReceipt(receiptData)
    // dispatch(sendEmailToCustomer(value))
  }
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Receipt No",
        accessor: "receipt_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.receipt_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Date",
        accessor: "receipt_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.receipt_date}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.amount}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Remaining Credit",
        accessor: "unsettled_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.unsettled_amount}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Project</span>
            <span style={{ display: "block" }}>Name</span>
          </div>
        ),
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.customer_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Company",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.company_name}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Financial Year",
        accessor: "financial_year",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.financial_year}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        filterable: true,
        Cell: cellProps => {
          const handleActionChange = event => {
            const selectedAction = event.target.value

            // Perform any necessary actions based on the selected value
            if (selectedAction === "Edit") {
              navigate(
                `/billing/edit-receipt/${cellProps.row.original.payment_id}`
              )
            } else if (selectedAction === "Delete") {
              onClickDelete(cellProps.row.original.payment_id)
            } else if (selectedAction === "Record Payment") {
              navigate(
                `/billing/record-payment/${cellProps.row.original.payment_id}`
              )
            } else if (selectedAction === "Send Email to Customer") {
              sendMailModal(
                cellProps.row.original.customer_id,
                cellProps.row.original.receipt_no,
                cellProps.row.original
              )
            } else if (selectedAction === "Send Whatsapp") {
              // Handle Send Whatsapp action
            } else if (selectedAction === "Audit Trail") {
              // Handle Audit Trail action
            } else if (selectedAction === "Download Receipt") {
              handleDownloadReceipt(cellProps.row.original.payment_id)
            } else if (selectedAction === "Preview") {
              handleReceiptModal(
                cellProps.row.original,
                cellProps.row.original.payment_id
              )
            }
            event.target.value = "Action"
          }
          return (
            <div>
              <select
                className="form-select"
                name="action"
                onChange={handleActionChange}
              >
                <option>Action</option>
                {permission
                  .filter(
                    item => item.page === "/edit-receipt/:id" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Edit</option>
                  ))}
                {permission
                  .filter(
                    item => item.page === "delete-receipt" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Delete</option>
                  ))}
                {permission
                  .filter(
                    item => item.page === "download-receipt" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Download Receipt</option>
                  ))}
                {permission
                  .filter(
                    item => item.page === "billing-preview-receipt" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Preview</option>
                  ))}
                {permission
                  .filter(
                    item => item.page === "send-email" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Send Email to Customer</option>
                  ))}
                {/* <option>Send Whatsapp</option>
                <option>Audit Trail</option> */}
              </select>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteBillingRecord(deleteId.id))
      dispatch(getBillingReceiptList({ user_id: user.user_id }))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/billing/receipt")
    dispatch(getBillingReceiptList({ user_id: user.user_id }))
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <ReceiptPreviewModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        Id={Id}
      />
      <LargeModal
        isOpen={modalLarge1}
        toggle={toggleModalLarge1}
        customerId={customerId}
        receipt_no={receipt_no}
        data={receipt}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Receipts" breadcrumbItem="All Receipts" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={BillingList}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Receipt
