import React, { useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// FlatPickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"

import * as Yup from "yup"
import { useFormik } from "formik"
import moment from "moment"
import { dropRight } from "lodash"

const AddNewProjectRFQ = () => {
  //meta title
  document.title =
    "Create New Project | Skote - React Admin & Dashboard Template"

  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      projectname: "",
      goodsservicehead: "",
      goodsservicesubhead: "",
      projectbudget: "",
    },
    validationSchema: Yup.object({
      projectname: Yup.string().required("Please Select Your Project"),
      goodsservicehead: Yup.string().required("Please Select Head"),
      goodsservicesubhead: Yup.string().required("Please Enter Your Subhead"),
      projectbudget: Yup.string().required("Please Enter Your Budget"),
    }),
    onSubmit: values => {
      validation.resetForm()
      toggle()
      setselectedFiles("")
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Create New" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New RFQ</CardTitle>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col xs="12" md="6">
                        <div className="mb-3">
                          <Label htmlFor="project" className="fw-medium">
                            Project
                          </Label>
                          <Input
                            type="select"
                            id="projectname"
                            name="projectname"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.projectname || ""}
                            invalid={
                              validation.touched.projectname &&
                              validation.errors.projectname
                                ? true
                                : false
                            }
                          >
                            <option value="">Select Project</option>
                            <option value="project1">Project 1</option>
                            <option value="project2">Project 2</option>
                            <option value="project3">Project 3</option>
                            <option value="project4">Project 4</option>
                          </Input>
                          {validation.touched.projectname &&
                          validation.errors.projectname ? (
                            <FormFeedback type="invalid">
                              {validation.errors.projectname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs="12" md="6">
                        <div className="mb-3">
                          <Label
                            htmlFor="goods-service-head"
                            className="fw-medium"
                          >
                            Goods/Services Head
                          </Label>
                          <Input
                            type="select"
                            id="head"
                            name="head"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.goodsservicehead || ""}
                            invalid={
                              validation.touched.goodsservicehead &&
                              validation.errors.goodsservicehead
                                ? true
                                : false
                            }
                          >
                            <option value="">Select Head</option>
                            <option value="header1">Header 1</option>
                            <option value="header2">Header 2</option>
                            <option value="header3">Header 3</option>
                            <option value="header4">Header 4</option>
                          </Input>
                          {validation.touched.goodsservicehead &&
                          validation.errors.goodsservicehead ? (
                            <FormFeedback type="invalid">
                              {validation.errors.goodsservicehead}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <div className="mb-3">
                          <Label
                            htmlFor="goods-service-subhead"
                            className="fw-medium"
                          >
                            Goods/Services Subhead
                          </Label>
                          <Input
                            type="text"
                            name="customer_name"
                            className="form-control"
                            id="customer_name"
                            placeholder="Enter Subhead"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.goodsservicesubhead || ""}
                            invalid={
                              validation.touched.goodsservicesubhead &&
                              validation.errors.goodsservicesubhead
                                ? true
                                : false
                            }
                          />
                          {validation.touched.goodsservicesubhead &&
                          validation.errors.goodsservicesubhead ? (
                            <FormFeedback type="invalid">
                              {validation.errors.goodsservicesubhead}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs="12" md="6">
                        <div className="mb-3">
                          <Label htmlFor="projectbudget" className="fw-medium">
                            Budget
                          </Label>
                          <Input
                            id="projectbudget"
                            name="projectbudget"
                            type="text"
                            placeholder="Enter Project Budget..."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.projectbudget || ""}
                            invalid={
                              validation.touched.projectbudget &&
                              validation.errors.projectbudget
                                ? true
                                : false
                            }
                          />
                          {validation.touched.projectbudget &&
                          validation.errors.projectbudget ? (
                            <FormFeedback type="invalid">
                              {validation.errors.projectbudget}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col lg="12">
                        <Label className="col-form-label col-lg-2 fw-medium">
                          Tender Document
                        </Label>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <FormGroup className="mb-4" row>
                        <Col lg="12">
                          <label
                            htmlFor="requesttextarea"
                            className="col-form-label col-lg-2 fw-medium"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            Requirement Specification
                          </label>
                          <Input
                            id="request-textarea"
                            name="requesttextarea"
                            type="textarea"
                            rows="5"
                            placeholder="Enter Requirement Specification..."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.requesttextarea || ""}
                            invalid={
                              validation.touched.requesttextarea &&
                              validation.errors.requesttextarea
                                ? true
                                : false
                            }
                          />
                          {validation.touched.projectbudget &&
                          validation.errors.projectbudget ? (
                            <FormFeedback type="invalid">
                              {validation.errors.requesttextarea}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Row>
                    <Row className="mb-4">
                      <Col lg="12" className="d-flex justify-content-end">
                        <Button
                          type="submit"
                          color="primary"
                          style={{ marginRight: "12px" }}
                        >
                          Save
                        </Button>
                        <Button type="button" color="danger">
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  {/* <Row className="justify-content-end"> */}
                  {/* </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">RFQ Details</CardTitle>
                  <Row>
                  <span className="fw-bold">
                    Project: 
                  </span>
                  <span className="mb-3"> Project2</span>
                  </Row>
                  <Row>
                  <span className="fw-bold">
                    Goods/Services Head: 
                  </span>
                  <span className="mb-3"> Head2</span>
                  </Row>
                  <Row>
                  <span className="fw-bold">
                    Goods/Services Subhead: 
                  </span>
                  <span className="mb-3"> Subhead2</span>
                  </Row>
                  <Row>
                  <span className="fw-bold">
                    Budget: 
                  </span>
                  <span className="mb-3"> XXXX</span>
                  </Row>
                  <Row>
                  <span className="fw-bold">
                  Requirement Specification:  
                  </span>
                  <span className="mb-3"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</span>
                  </Row>
                  <Row>
                  <span className="fw-bold">
                  Tender Document:  
                  </span>
                     <div
                          className="dropzone-previews"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete d-inline"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col>
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
            <Card>
              <CardBody>
                
              </CardBody>
            </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">RFQ Details</CardTitle>
                  <Row>
                  <Col className="mb-4">
                  <span className="fw-bold">
                    Project: 
                  </span>
                  <span className="mb-3"> Project2</span>
                  </Col>
                  </Row>
                  <Row>
                  <Col className="mb-4">
                  <span className="fw-bold">
                    Goods/Services Head: 
                  </span>
                  <span className="mb-3"> Head2</span>
                  </Col>
                  </Row>
                  <Row>
                  <Col className="mb-4">
                  <span className="fw-bold">
                    Goods/Services Subhead: 
                  </span>
                  <span className="mb-3"> Subhead2</span>
                  </Col>
                  </Row>
                  <Row>
                  <Col className="mb-4">
                  <span className="fw-bold">
                    Budget: 
                  </span>
                  <span className="mb-3"> XXXX</span></Col>
                  </Row>
                  <Row>
                  <Col className="mb-4">
                  <span className="fw-bold">
                  Requirement Specification:  
                  </span>
                  <span className="mb-3"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</span>
                  </Col>
                  </Row>
                  <Row>
                  <Col className="mb-4">
                  <span className="fw-bold">
                  Tender Document:  
                  </span>
                     <div
                          className="dropzone-previews"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                  </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddNewProjectRFQ
