import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./billingRegisterContainer"
import Select from "react-select"
import { API_URL } from "helpers/url_helper"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  getBillingRegisterList,
  getServiceRequests,
  deleteBillingRegister,
  sendEmailToCustomer,
  downloadInvoice,
} from "store/actions"
import LargeModal from "./EmailModal/emailModal"
import JobListGlobalFilter from "./GlobalSearchFilter"

const BillingRegister = () => {
  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }
  const [isLoading, setLoading] = useState(false)

  const navigate = useNavigate()
  document.title = "All Billing List"

  const dispatch = useDispatch()
  const billingRegisterState = state => state.billing.billingRegister.data

  const billingRegisterList = useSelector(billingRegisterState) || []

  useEffect(() => {
    if (user) {
      const value = {
        user_id: user.user_id,
      }
      dispatch(getBillingRegisterList(value))
    }
  }, [user])

  const [customerId, setCustomerID] = useState()
  const [bill, setBillNo] = useState()
  const [billData, setBillData] = useState()
  const sendMailModal = (value, bill_no, billingData) => {
    setCustomerID(value)
    setBillNo(bill_no)
    setBillData(billingData)
    toggleModalLarge()
    // dispatch(sendEmailToCustomer(value))
  }
  const sendMail = value => {
    dispatch(sendEmailToCustomer(value))
  }

  const handleDownloadInvoice = billingId => {
    // Construct the URL for download
    const downloadUrl = `${API_URL}/api/download-invoice-pdf/${billingId}`

    // Create an anchor element
    const anchorElement = document.createElement("a")
    anchorElement.href = downloadUrl
    anchorElement.target = "_blank" // Open in a new tab
    anchorElement.download = "invoice.pdf" // Optional: specify a filename for the downloaded file

    // Append the anchor element to the document body (required in some browsers)
    document.body.appendChild(anchorElement)

    // Trigger a click event on the anchor element
    anchorElement.click()

    // Clean up: remove the anchor element from the document body
    document.body.removeChild(anchorElement)
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Invoice</span>
            <span style={{ display: "block" }}>No</span>
          </div>
        ),
        accessor: "bill_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  // to={`/service-request/view-service/${cellProps.row.original.id}`}
                >
                  {cellProps.row.original.bill_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ whiteSpace: "nowrap" }}>
              <span style={{ display: "block" }}>Invoice</span>
              <span style={{ display: "block" }}>Date</span>
            </div>
            {/* <div>
              <i className="mdi mdi-sort font-size-24"></i>
            </div> */}
          </div>
        ),
        accessor: "bill_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.bill_date}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ whiteSpace: "nowrap" }}>
              <span style={{ display: "block" }}>Billing</span>
              <span style={{ display: "block" }}>Company</span>
            </div>
            {/* <div>
              <i className="mdi mdi-sort font-size-24"></i>
            </div> */}
          </div>
        ),
        accessor: "company_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.company_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Project</span>
            <span style={{ display: "block" }}>Name</span>
          </div>
        ),
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Customer</span>
            <span style={{ display: "block" }}>Name</span>
          </div>
        ),
        accessor: "customer_name",

        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.customer_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Bill</span>
            <span style={{ display: "block" }}>Amount</span>
          </div>
        ),
        accessor: "all_total",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.all_total}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Payment</span>
            <span style={{ display: "block" }}>Received</span>
          </div>
        ),
        accessor: "amount_rcd",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.amount_rcd}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>TDS</span>
            <span style={{ display: "block" }}>Amount</span>
          </div>
        ),
        accessor: "amount_withheld",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.amount_withheld}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Outstanding</span>
            <span style={{ display: "block" }}>Amount</span>
          </div>
        ),
        accessor: "outstanding_amt",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.outstanding_amt}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Credit </span>
            <span style={{ display: "block" }}>Amount</span>
          </div>
        ),
        accessor: "credit_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.credit_amount}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "payment_status",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <span
                className={
                  cellProps.row.original.payment_status === "Paid"
                    ? "text-success"
                    : "text-danger"
                }
              >
                {cellProps.row.original.payment_status === "Paid"
                  ? "Paid "
                  : "Unpaid"}
              </span>
            </div>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Financial</span>
            <span style={{ display: "block" }}>Year</span>
          </div>
        ),
        accessor: "financial_year",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.financial_year}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Ledger</span>
            <span style={{ display: "block" }}>Text</span>
          </div>
        ),
        accessor: "ledger_text",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.ledger_text}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        filterable: true,
        Cell: cellProps => {
          const handleActionChange = event => {
            const selectedAction = event.target.value

            // Perform any necessary actions based on the selected value
            if (selectedAction === "Edit") {
              navigate(
                `/billing/edit-bill/${cellProps.row.original.billing_id}`
              )
            } else if (selectedAction === "Delete") {
              onClickDelete(cellProps.row.original.billing_id)
            } else if (selectedAction === "Record Payment") {
              navigate(
                `/billing/record-payment/${cellProps.row.original.billing_id}`
              )
            } else if (selectedAction === "Send Email to Customer") {
              sendMailModal(
                cellProps.row.original.billing_id,
                cellProps.row.original.bill_no,
                cellProps.row.original
              )
            } else if (selectedAction === "Send Whatsapp") {
              // Handle Send Whatsapp action
            } else if (selectedAction === "Audit Trail") {
              // Handle Audit Trail action
            } else if (selectedAction === "Download Invoice") {
              handleDownloadInvoice(cellProps.row.original.billing_id)
            } else if (selectedAction === "Preview") {
              navigate(
                `/billing/preview-invoice/${cellProps.row.original.billing_id}`
              )
            }
            event.target.value = "Action"
          }
          return (
            <div>
              <select
                className="form-select"
                name="action"
                onChange={handleActionChange}
              >
                <option disabled selected>
                  Action
                </option>
                {permission
                  .filter(
                    item => item.page === "/edit-bill/:id" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Edit</option>
                  ))}
                {/*  {permission
                  .filter(
                    item =>
                      item.page === "delete-bill" && item.access === 1
                  )
                  .map((item, index) => (<option>Duplicate Bill</option>))} */}
                {/*  {permission
                  .filter(
                    item =>
                      item.page === "billing-register-send-whatsapp" && item.access === 1
                  )
                  .map((item, index) => (<option>Send Whatsapp</option>))} */}
                {/*  {permission
                  .filter(
                    item =>
                      item.page === "billing-register-audit-trail" && item.access === 1
                  )
                  .map((item, index) => (<option>Audit Trail</option>))} */}
                {permission
                  .filter(
                    item => item.page === "delete-bill" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Delete</option>
                  ))}
                {permission
                  .filter(
                    item =>
                      item.page === "/record-payment/:id" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Record Payment</option>
                  ))}
                {permission
                  .filter(
                    item =>
                      item.page === "billing-register-send-email" &&
                      item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Send Email to Customer</option>
                  ))}
                {permission
                  .filter(
                    item =>
                      item.page === "billing-register-download-invoice" &&
                      item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Download Invoice</option>
                  ))}
                {permission
                  .filter(
                    item =>
                      item.page === "/preview-invoice/:id" && item.access === 1
                  )
                  .map((item, index) => (
                    <option key={index}>Preview</option>
                  ))}
              </select>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteBillingRegister(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    if (user) {
      const value = {
        user_id: user.user_id,
      }
      dispatch(getBillingRegisterList(value))
    }
    if (user) {
      const value = {
        user_id: user.user_id,
      }
      dispatch(getBillingRegisterList(value))
    }
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const [selectedStatus, setselectedStatus] = useState(null)
  function handleSelectGroup(selectedStatus) {
    setselectedStatus(selectedStatus)
  }
  const keyField = "id"

  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        customerId={customerId}
        bill={bill}
        data={billData}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Billing" breadcrumbItem="Billing Register" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={billingRegisterList}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BillingRegister
