export const GET_COUNT_FOR_TICKETS_DASHBOARD = "GET_COUNT_FOR_TICKETS_DASHBOARD"
export const GET_COUNT_FOR_TICKETS_DASHBOARD_SUCCESS = "GET_COUNT_FOR_TICKETS_DASHBOARD_SUCCESS"
export const GET_COUNT_FOR_TICKETS_DASHBOARD_FAIL = "GET_COUNT_FOR_TICKETS_DASHBOARD_FAIL"

export const GET_CRITICAL_PRIORITY_TICKET_LIST = "GET_CRITICAL_PRIORITY_TICKET_LIST"
export const GET_CRITICAL_PRIORITY_TICKET_LIST_SUCCESS = "GET_CRITICAL_PRIORITY_TICKET_LIST_SUCCESS"
export const GET_CRITICAL_PRIORITY_TICKET_LIST_FAIL = "GET_CRITICAL_PRIORITY_TICKET_LIST_FAIL"

export const GET_HIGH_PRIORITY_TICKET_LIST = "GET_HIGH_PRIORITY_TICKET_LIST"
export const GET_HIGH_PRIORITY_TICKET_LIST_SUCCESS = "GET_HIGH_PRIORITY_TICKET_LIST_SUCCESS"
export const GET_HIGH_PRIORITY_TICKET_LIST_FAIL = "GET_HIGH_PRIORITY_TICKET_LIST_FAIL"

export const GET_MEDIUM_PRIORITY_TICKET_LIST = "GET_MEDIUM_PRIORITY_TICKET_LIST"
export const GET_MEDIUM_PRIORITY_TICKET_LIST_SUCCESS = "GET_MEDIUM_PRIORITY_TICKET_LIST_SUCCESS"
export const GET_MEDIUM_PRIORITY_TICKET_LIST_FAIL = "GET_MEDIUM_PRIORITY_TICKET_LIST_FAIL"

export const GET_LOW_PRIORITY_TICKET_LIST = "GET_LOW_PRIORITY_TICKET_LIST"
export const GET_LOW_PRIORITY_TICKET_LIST_SUCCESS = "GET_LOW_PRIORITY_TICKET_LIST_SUCCESS"
export const GET_LOW_PRIORITY_TICKET_LIST_FAIL = "GET_LOW_PRIORITY_TICKET_LIST_FAIL"

export const GET_RECENT_TICKETS_LIST ="GET_RECENT_TICKETS_LIST"
export const GET_RECENT_TICKETS_LIST_SUCCESS ="GET_RECENT_TICKETS_LIST_SUCCESS"
export const GET_RECENT_TICKETS_LIST_FAIL ="GET_RECENT_TICKETS_LIST_FAIL"

export const GET_USER_VENDOR_WITH_MOST_TICKET = "GET_USER_VENDOR_WITH_MOST_TICKET"
export const GET_USER_VENDOR_WITH_MOST_TICKET_SUCCESS = "GET_USER_VENDOR_WITH_MOST_TICKET_SUCCESS"
export const GET_USER_VENDOR_WITH_MOST_TICKET_FAIL = "GET_USER_VENDOR_WITH_MOST_TICKET_FAIL"

export const GET_OPEN_CLOSE_TICKET_GRAPH = "GET_OPEN_CLOSE_TICKET_GRAPH"
export const GET_OPEN_CLOSE_TICKET_GRAPH_SUCCESS = "GET_OPEN_CLOSE_TICKET_GRAPH_SUCCESS"
export const GET_OPEN_CLOSE_TICKET_GRAPH_FAIL = "GET_OPEN_CLOSE_TICKET_GRAPH_FAIL"

export const GET_TICKETS_STATUS_PIE_DIGRAM ="GET_TICKETS_STATUS_PIE_DIGRAM"
export const GET_TICKETS_STATUS_PIE_DIGRAM_SUCCESS ="GET_TICKETS_STATUS_PIE_DIGRAM_SUCCESS"
export const GET_TICKETS_STATUS_PIE_DIGRAM_FAIL ="GET_TICKETS_STATUS_PIE_DIGRAM_FAIL"

export const GET_TICKETS_PRIORITY_PIE_DIGRAM ="GET_TICKETS_PRIORITY_PIE_DIGRAM"
export const GET_TICKETS_PRIORITY_PIE_DIGRAM_SUCCESS ="GET_TICKETS_PRIORITY_PIE_DIGRAM_SUCCESS"
export const GET_TICKETS_PRIORITY_PIE_DIGRAM_FAIL ="GET_TICKETS_PRIORITY_PIE_DIGRAM_FAIL"
