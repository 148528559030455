export const GET_VENDORTYPE_MASTER_LIST = "GET_VENDORTYPE_MASTER_LIST"
export const GET_VENDORTYPE_MASTER_LIST_SUCCESS = "GET_VENDORTYPE_MASTER_LIST_SUCCESS"
export const GET_VENDORTYPE_MASTER_LIST_FAIL = "GET_VENDORTYPE_MASTER_LIST_FAIL"

export const GET_VENDORTYPE_MASTER_DETAIL = "GET_VENDORTYPE_MASTER_DETAIL"
export const GET_VENDORTYPE_MASTER_DETAIL_SUCCESS = "GET_VENDORTYPE_MASTER_DETAIL_SUCCESS"
export const GET_VENDORTYPE_MASTER_DETAIL_FAIL = "GET_VENDORTYPE_MASTER_DETAIL_FAIL"

export const ADD_NEW_VENDORTYPE_MASTER = "ADD_NEW_VENDORTYPE_MASTER"
export const ADD_NEW_VENDORTYPE_MASTER_SUCCESS = "ADD_NEW_VENDORTYPE_MASTER_SUCCESS"
export const ADD_NEW_VENDORTYPE_MASTER_FAIL = "ADD_NEW_VENDORTYPE_MASTER_FAIL"

export const UPDATE_VENDORTYPE_MASTER = "UPDATE_VENDORTYPE_MASTER"
export const UPDATE_VENDORTYPE_MASTER_SUCCESS = "UPDATE_VENDORTYPE_MASTER_SUCCESS"
export const UPDATE_VENDORTYPE_MASTER_FAIL = "UPDATE_VENDORTYPE_MASTER_FAIL"

export const DELETE_VENDORTYPE_MASTER = "DELETE_VENDORTYPE_MASTER"
export const DELETE_VENDORTYPE_MASTER_SUCCESS = "DELETE_VENDORTYPE_MASTER_SUCCESS"
export const DELETE_VENDORTYPE_MASTER_FAIL = "DELETE_VENDORTYPE_MASTER_FAIL"
