import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  FormFeedback,
  Col,
  Row,
  Label,
  Form,
  Input,
  Card,
  CardBody,
  Modal,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import Swal from "sweetalert2"

import { acceptServiceRequest } from "helpers/fakebackend_helper"

const LargeModal = ({ isOpen, toggle, id }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: 5,
      declined_reason: "",
    },

    validationSchema: Yup.object({}),
    onSubmit: async values => {
      let loadingSwal
      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })

        const response = await acceptServiceRequest(id, values)

        if (
          response.success === true &&
          response.service_request.status === 5
        ) {
          Swal.fire({
            title: "Rejected",
            icon: "success",
            text: "Your Service Request has been Rejected.",
          }).then(result => {
            if (result.isConfirmed) {
              navigate("/service-requests/all-service-requests")
            }
          })
        }
      } catch (error) {
        console.error("Error:", error)
        if (loadingSwal) {
          loadingSwal.close()
        }

        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response?.data?.message ||
            "An error occurred while Rejecting Request .",
        })
      }
    },
  })

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Reject Request
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Card>
          <CardBody>
            <Form
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault()
                // validation.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col className="mb-3 mt-2" lg={6}>
                  <Label>Reason for Rejection:</Label>
                  <Input
                    type="textarea"
                    placeholder="Enter Reason for Rejection"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.declined_reason}
                    className={`form-control ${
                      validation.touched.declined_reason &&
                      validation.errors.declined_reason
                        ? "is-invalid"
                        : ""
                    }`}
                    name="declined_reason"
                  />
                  {validation.touched.declined_reason &&
                  validation.errors.declined_reason ? (
                    <FormFeedback type="invalid">
                      {validation.errors.declined_reason}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <div className="mb-3 mt-4 text-center">
                  <button
                    style={{ width: "100px" }}
                    className="btn btn-danger me-2 mt-2"
                    onClick={() => {
                      validation.handleSubmit()
                    }}
                  >
                    Reject
                  </button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  )
}

export default LargeModal
