import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_CLIENT_USER_LIST,
  GET_CLIENT_USER_DETAIL,
  ADD_NEW_CLIENT_USER,
  UPDATE_CLIENT_USER,
  DELETE_CLIENT_USER,
} from "./actionTypes"
import {
  getClientUserListSuccess,
  getClientUserListFail,
  getClientUserDetailSuccess,
  getClientUserDetailFail,
  addNewClientUserSuccess,
  addNewClientUserFail,
  updateClientUserSuccess,
  updateClientUserFail,
  deleteClientUserSuccess,
  deleteClientUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClientUserList,
  getClientUserDetail,
  addNewClientUser,
  updateClientUser,
  deleteClientUser,
} from "helpers/fakebackend_helper"

function* fetchClientUserList({ value }) {
  try {
    const response = yield call(getClientUserList, value)
    yield put(getClientUserListSuccess(response))
  } catch (error) {
    yield put(getClientUserListFail(error))
  }
}

function* fetchClientUserDetail({ userId }) {
  try {
    const response = yield call(getClientUserDetail, userId)
    yield put(getClientUserDetailSuccess(response))
  } catch (error) {
    yield put(getClientUserDetailFail(error))
  }
}

function* onAddNewClientUser({ payload: { user, history } }) {
  try {
    const response = yield call(addNewClientUser, user)
    if (response.status === true) {
      yield put(addNewClientUserSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Client User Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history(`/clients/view-client/${user.client_id}`)
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewClientUserFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onUpdateClientUser({ payload: { id, user, history } }) {
  try {
    const response = yield call(updateClientUser, id, user)
    if (response && response.status === true) {
      yield put(updateClientUserSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Client User Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history(`/clients/view-client/${user.client_id}`)
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateClientUserFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteClientUser({ payload: user }) {
  try {
    const response = yield call(deleteClientUser, user)
    if (response.status) {
      yield put(deleteClientUserSuccess(response))
      const clientUserListResponse = yield call(
        getClientUserList,
        user
      )
       if (clientUserListResponse) {
        yield put(getClientUserListSuccess(clientUserListResponse))
      }
    } else {
      console.error("Error in delete :", response.error)
    }
  } catch (error) {
    yield put(deleteClientUserFail(error))
  }
}

function* clientUserSaga() {
  yield takeEvery(GET_CLIENT_USER_LIST, fetchClientUserList)
  yield takeEvery(GET_CLIENT_USER_DETAIL, fetchClientUserDetail)
  yield takeEvery(ADD_NEW_CLIENT_USER, onAddNewClientUser)
  yield takeEvery(UPDATE_CLIENT_USER, onUpdateClientUser)
  yield takeEvery(DELETE_CLIENT_USER, onDeleteClientUser)
}

export default clientUserSaga
