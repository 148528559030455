import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_VENDORTYPE_MASTER_LIST,
  GET_VENDORTYPE_MASTER_DETAIL,
  ADD_NEW_VENDORTYPE_MASTER,
  UPDATE_VENDORTYPE_MASTER,
  DELETE_VENDORTYPE_MASTER,
} from "./actionTypes"
import {
  getVendorTypeMasterListSuccess,
  getVendorTypeMasterListFail,
  getVendorTypeMasterDetailSuccess,
  getVendorTypeMasterDetailFail,
  addNewVendorTypeMasterSuccess,
  addNewVendorTypeMasterFail,
  updateVendorTypeMasterSuccess,
  updateVendorTypeMasterFail,
  deleteVendorTypeMasterSuccess,
  deleteVendorTypeMasterFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getVendorTypeMasterList,
  getVendorTypeMasterDetail,
  addNewVendorTypeMaster,
  updateVendorTypeMaster,
  deleteVendorTypeMaster,
} from "helpers/fakebackend_helper"

function* fetchVendorTypeMasterList() {
  try {
    const response = yield call(getVendorTypeMasterList)
    yield put(getVendorTypeMasterListSuccess(response))
  } catch (error) {
    yield put(getVendorTypeMasterListFail(error))
  }
}

function* fetchVendorTypeMasterDetail({ vendorTypeId }) {
  try {
    const response = yield call(getVendorTypeMasterDetail, vendorTypeId)
    yield put(getVendorTypeMasterDetailSuccess(response))
  } catch (error) {
    yield put(getVendorTypeMasterDetailFail(error))
  }
}

function* onAddNewVendorTypeMaster({ payload: { vendorType, history } }) {
  try {
    const response = yield call(addNewVendorTypeMaster, vendorType)
    if (response.status === true) {
      yield put(addNewVendorTypeMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Vendor Type Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/vendor-type-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewVendorTypeMasterFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onUpdateVendorTypeMaster({ payload: { id, vendorType, history } }) {
  try {
    const response = yield call(updateVendorTypeMaster, id, vendorType)
    if (response && response.status === true) {
      yield put(updateVendorTypeMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Vendor Type Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/vendor-type-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateVendorTypeMasterFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteVendorTypeMaster({ payload: vendorType }) {
  try {
    const response = yield call(deleteVendorTypeMaster, vendorType)
    const response2 = yield put(deleteVendorTypeMasterSuccess(response))
    // yield put(deleteSACHSNCodeSuccess(response))
    if (response2.payload.status) {
      const vendorTypeMasterListResponse = yield call(getVendorTypeMasterList)
      yield put(getVendorTypeMasterListSuccess(vendorTypeMasterListResponse))
    } else {
      console.error("Error in delete VendorType Master:", response.error)
    }
  } catch (error) {
    yield put(deleteVendorTypeMasterFail(error))
  }
}

function* vendorTypeMasterSaga() {
  yield takeEvery(GET_VENDORTYPE_MASTER_LIST, fetchVendorTypeMasterList)
  yield takeEvery(GET_VENDORTYPE_MASTER_DETAIL, fetchVendorTypeMasterDetail)
  yield takeEvery(ADD_NEW_VENDORTYPE_MASTER, onAddNewVendorTypeMaster)
  yield takeEvery(UPDATE_VENDORTYPE_MASTER, onUpdateVendorTypeMaster)
  yield takeEvery(DELETE_VENDORTYPE_MASTER, onDeleteVendorTypeMaster)
}

export default vendorTypeMasterSaga
