import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAIL,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,

  //DOCUMENTS
  GET_CLIENT_DOCUMENTS_SUCCESS,
  GET_CLIENT_DOCUMENTS_FAIL,
  ADD_NEW_CLIENT_DOCUMENT_SUCCESS,
  ADD_NEW_CLIENT_DOCUMENT_FAIL,
  DELETE_CLIENT_DOCUMENT_SUCCESS,
  DELETE_CLIENT_DOCUMENT_FAIL,

  //REINVITE API
  REINVITE_CLIENT_SUCCESS,
  REINVITE_CLIENT_FAIL,

  //ASSIGNED PROJECT
  GET_ASSIGNED_PROJECT_FAIL,
  GET_ASSIGNED_PROJECT_LIST_SUCCESS,
  GET_ASSIGNED_PROJECT_LIST_FAIL,
  ADD_ASSIGNED_PROJECT_SUCCESS,
  ADD_ASSIGNED_PROJECT_FAIL,
  UPDATE_ASSIGNED_PROJECT_SUCCESS,
  UPDATE_ASSIGNED_PROJECT_FAIL,
  DELETE_ASSIGNED_PROJECT_SUCCESS,
  DELETE_ASSIGNED_PROJECT_FAIL,
  GET_ASSIGNED_PROJECT_SUCCESS,
  GET_ALL_ASSIGNED_PROJECT_UNITS_SUCCESS,
  GET_ALL_ASSIGNED_PROJECT_UNITS_FAIL,
  GET_PROJECT_LIST_FOR_CLIENT_SUCCESS,
  GET_PROJECT_LIST_FOR_CLIENT_FAIL,
  GET_PROJECT_UNIT_LIST_FOR_CLIENT_SUCCESS,
  GET_PROJECT_UNIT_LIST_FOR_CLIENT_FAIL,
  GET_CLIENT_CODE_SUCCESS,
  GET_CLIENT_CODE_FAIL,
  GET_INVOICE_TYPE_MASTER_LIST_SUCCESS,
  GET_INVOICE_TYPE_MASTER_LIST_FAIL,
  GET_INVOICE_TYPE_MASTER_CLIENT_LIST_SUCCESS,
  GET_INVOICE_TYPE_MASTER_CLIENT_LIST_FAIL,
  GET_SACHSN_CODE_CLIENT_SUCCESS,
  GET_SACHSN_CODE_CLIENT_FAIL,
  GET_APPLICABLE_RATE_CLIENT_LIST_SUCCESS,
  GET_APPLICABLE_RATE_CLIENT_LIST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  clients: {},
  docs: [],
  viewAssignedProjectUnit: [],
  projectUnits: [],
  clientsList: [],
  projectList: [],
  projectUnitList: [],
  client_code: [],
  codesClient: [],
  invoiceTypeMasterList: [],
  applicableRateList: [],
  error: {},
  loading: true,
}

const clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        loading: true,
      }

    case GET_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        clientsList: action.payload,
        loading: true,
      }

    case GET_CLIENTS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload],
      }

    case ADD_NEW_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.map(client =>
          client.id.toString() === action.payload.id.toString()
            ? { client, ...action.payload }
            : client
        ),
      }

    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clientsList.clients.filter(
          client => client.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CLIENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        docs: action.payload,
        loading: true,
      }

    case GET_CLIENT_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_CLIENT_DOCUMENT_FAIL:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      }

    case ADD_NEW_CLIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CLIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        docs: state.docs.filter(
          doc => doc.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CLIENT_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // ASSIGNED PROJECT

    case GET_ASSIGNED_PROJECT_SUCCESS:
      return {
        ...state,
        pastEmp: action.payload,
        loading: true,
        // loading: true
      }

    case GET_ASSIGNED_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ASSIGNED_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        viewAssignedProjectUnit: action.payload,
        loading: true,
        // loading: true
      }

    case GET_ASSIGNED_PROJECT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ASSIGNED_PROJECT_SUCCESS:
      return {
        ...state,
        pastEmp: [...pastEmp.users, action.payload],
      }

    case ADD_ASSIGNED_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ASSIGNED_PROJECT_SUCCESS:
      return {
        ...state,
        pastEmp: state.pastEmp.map(emp =>
          emp.id.toString() === action.payload.id.toString()
            ? { emp, ...action.payload }
            : emp
        ),
      }

    case UPDATE_ASSIGNED_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ASSIGNED_PROJECT_SUCCESS:
      return {
        ...state,
        pastEmp: state.pastEmp.filter(
          emp => emp.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_ASSIGNED_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ALL_ASSIGNED_PROJECT_UNITS_SUCCESS:
      return {
        ...state,
        projectUnits: action.payload,
        loading: true,
      }

    case GET_ALL_ASSIGNED_PROJECT_UNITS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //REINVITE

    case REINVITE_CLIENT_SUCCESS:
      return {
        ...state,
        reinvite: action.payload,
        loading: true,
        // loading: true
      }

    case REINVITE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //project and project unit
    case GET_PROJECT_LIST_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        projectList: action.payload,
        loading: true,
      }

    case GET_PROJECT_LIST_FOR_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_UNIT_LIST_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        projectUnitList: action.payload,
        loading: true,
      }

    case GET_PROJECT_UNIT_LIST_FOR_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CLIENT_CODE_SUCCESS:
      return {
        ...state,
        client_code: action.payload,
        loading: true,
      }

    case GET_CLIENT_CODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INVOICE_TYPE_MASTER_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        invoiceTypeMasterList: action.payload,
        loading: true,
      }

    case GET_INVOICE_TYPE_MASTER_CLIENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SACHSN_CODE_CLIENT_SUCCESS:
      return {
        ...state,
        codesClient: action.payload,
        loading: true,
      }

    case GET_SACHSN_CODE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_APPLICABLE_RATE_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        applicableRateList: action.payload,
        loading: true,
      }

    case GET_APPLICABLE_RATE_CLIENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default clients
