import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Form,
  Input,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { getUserPermission, getUserModules } from "store/actions"
import { addNewUserRole } from "helpers/fakebackend_helper"
import Spinners from "components/Common/Spinner"

const manageUserPermission = () => {
  // const location = useLocation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [userRole, setUserRole] = useState(user?.user_role)
  const dispatch = useDispatch()

  // const modulesState = state => state.roles.userRoleModules.data
  const modulesState = state => state.users.userPermission.data
  // const loadingState = state => state.roles.loading
  const allModule = useSelector(modulesState) || []
  // const loadingData = useSelector(loadingState)
  const [isLoading, setLoading] = useState(true)


  const [selectedModules, setSelectedModules] = useState()
  const handleModuleRadioChange = (component_id, value) => {
    setSelectedModules({
      ...selectedModules,
      [component_id]: value,
    })
    makeApiCall(component_id, value)
  }

  const makeApiCall = async (component_id, value) => {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = await addNewUserRole({
      user_id: Number(userId),
      component_id: component_id,
      access: value,
    })
    if (response.status === true) {
      localStorage.setItem(
        "permission",
        JSON.stringify(response.user_permissions)
      )
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your User Permission has been updated.",
        timer: 800,
        showConfirmButton: false,
      })
    }
    dispatch(getUserPermission(userId))
    // dispatch(getUserModules(userId))
  }

  const rolesProperties = createSelector(modulesState, roles => {
    return {
      roles: roles,
    }
  })

  const { roles } = useSelector(rolesProperties)
  const [specificUser, setSpecificUser] = useState(null)
  const [userId, setUserId] = useState()

  const userRoledata = state => state.roles.roleDetail.Data
  const role = useSelector(userRoledata)


  const roleData = async () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const userId = url.pathname.split("/manage-user-permission/")[1]
      setUserId(userId)
      // dispatch(getModules(roleId))
      dispatch(getUserPermission(userId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    roleData()
  }, [dispatch])

  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  document.title = "Role Management"

  const groupedModules = {}
  allModule.forEach(module => {
    if (!groupedModules[module.module_heading]) {
      groupedModules[module.module_heading] = []
    }
    groupedModules[module.module_heading].push(module)
  })

  return (
    <div className="page-content">
      <Container fluid={true}>
        {userId === "1" ? (
          <>
            <div>
              <i
                className="mdi mdi-arrow-left font-size-20 text-grey"
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              <span className="ms-2 font-weight-bold font-size-18">
                Manage User Permission
              </span>
            </div>
            <Row>
              <Card>
                <CardBody>
                  <h3 className="text-center text-danger">Alert!</h3>
                  <p className="text-center">
                    Cannot Change Access of Super Admin
                  </p>
                </CardBody>
              </Card>
            </Row>
          </>
        ) : (
          <>
            <Row className="mb-3">
              <Col>
                <div className="d-flex align-items-center">
                  <i
                    className="mdi mdi-arrow-left font-size-20 text-grey"
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  <span className="ms-2 font-weight-bold font-size-18">
                    Manage User Permission
                  </span>
                </div>
              </Col>
              <Col>
                <Breadcrumbs title="Users / User Details / Manage User Permission" />
              </Col>
            </Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Row>
                <Card>
                  <CardBody>
                    <Form>
                      <Row className="mb-3">
                        <Col>
                          <span style={{ color: "black", fontWeight: "" }}>
                            Modules / Components
                          </span>
                        </Col>
                        <Col style={{ marginLeft: "100px" }}>
                          <span style={{ color: "black", fontWeight: "" }}>
                            Permissions
                          </span>
                        </Col>
                      </Row>
                      {Object.keys(groupedModules).map(
                        (heading, headingIndex) => (
                          <div key={headingIndex}>
                            <Row
                              className=""
                              style={{
                                backgroundColor: "#3783E8",
                                color: "white",
                                padding: "12px",
                              }}
                            >
                              <Col>
                                <span style={{ fontWeight: "1000" }}>
                                  {heading}
                                </span>
                              </Col>
                            </Row>
                            {groupedModules[heading].map(
                              (module, moduleIndex) => (
                                <Row
                                  key={module.component_id}
                                  style={{
                                    backgroundColor:
                                      moduleIndex % 2 === 0 ? "#EEE" : "white",
                                    padding: "12px",
                                  }}
                                >
                                  <Col>
                                    <div className="d-flex align-items-center">
                                      <span
                                        style={{
                                          fontWeight: "400",
                                          color: "black",
                                        }}
                                        className="mb-0"
                                      >
                                        {module.display_text}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="d-flex gap-3">
                                      <div className="form-check">
                                        <Input
                                          type="radio"
                                          className="form-check-input"
                                          id={`radioOption1-${module.component_id}`}
                                          name={`radioGroup-${module.component_id}`}
                                          checked={module.access === 1}
                                          onClick={() => {
                                            handleModuleRadioChange(
                                              module.component_id,
                                              1
                                            )
                                          }}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor={`radioOption1-${module.component_id}`}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "400",
                                              color: "black",
                                            }}
                                          >
                                            Allow Access
                                          </span>
                                        </Label>
                                      </div>
                                      <div className="form-check">
                                        <Input
                                          type="radio"
                                          className="form-check-input"
                                          id={`radioOption2-${module.component_id}`}
                                          name={`radioGroup-${module.component_id}`}
                                          checked={module.access === 0}
                                          onClick={() => {
                                            handleModuleRadioChange(
                                              module.component_id,
                                              0
                                            )
                                          }}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor={`radioOption2-${module.component_id}`}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "400",
                                              color: "black",
                                            }}
                                          >
                                            {" "}
                                            Deny Access
                                          </span>
                                        </Label>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )
                            )}
                          </div>
                        )
                      )}
                    </Form>
                  </CardBody>
                </Card>
              </Row>
            )}
          </>
        )}
      </Container>
    </div>
  )
}

export default manageUserPermission
