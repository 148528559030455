import React, { useEffect, useState } from "react"

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getCityForCustomerIndex,
  getCustomerIndex,
  getProjectFilterForCustomerIndex,
  getStateForCustomerIndex,
} from "store/actions"
import { downloadCustomerIndex } from "helpers/fakebackend_helper"

const CustomerIndex = () => {
  document.title = "Customer Index"
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const dispatch = useDispatch()

  const states = state => state.CustomerIndex.state.data
  const statesList = useSelector(states) || []
  const city = state => state.CustomerIndex.city.data
  const cityList = useSelector(city) || []
  const allProjectState = state => state.CustomerIndex.projectFilter.project
  const projectList = useSelector(allProjectState) || []
  const customerIndex = state => state.CustomerIndex.customerIndex.data
  const customerIndexList = useSelector(customerIndex) || []

  const validation = useFormik({
    initialValues: {
      search_type: "by Email",
      search_value: "",
      type: "CustomerIndex",
      user_id:user.user_id,
    },
    onSubmit: async values => {
      await dispatch(getCustomerIndex(values))
    },
  })

  
  const handleExport = async () => {
    if (validation.values.search_type && validation.values.search_value) {
      try {
        // Construct the URL for download
        const downloadUrl = await downloadCustomerIndex(validation.values)
  
      } catch (error) {
        Swal.fire({
          title: "Export Failed",
          text: "There was an issue generating the export. Please try again later.",
          icon: "error"
        });
      }
    } else {
      Swal.fire({
        title: "Company location Required",
        text: "Please fill required details",
        icon: "warning"
      });
    }
  };

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 10) {
        validation.setFieldValue("search_value", "")
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)

        switch (tab) {
          case 1:
            validation.setFieldValue("search_type", "by Email")
            break
          case 2:
            validation.setFieldValue("search_type", "by Brand Name")
            break
          case 3:
            validation.setFieldValue("search_type", "by State")
            break
          case 4:
            validation.setFieldValue("search_type", "by City")
            break
          case 5:
            validation.setFieldValue("search_type", "by Pincode")
            break
          case 6:
            validation.setFieldValue("search_type", "by PAN No")
            break
          case 7:
            validation.setFieldValue("search_type", "by TAN No")
            break
          case 8:
            validation.setFieldValue("search_type", "by GST No")
            break
          case 9:
            validation.setFieldValue("search_type", "by Contact Personal No")
            break
          case 10:
            validation.setFieldValue("search_type", "by Project Name")
            break
          default:
            break
        }
      }
    }
  }

  useEffect(() => {
    dispatch(getStateForCustomerIndex())
    dispatch(getCityForCustomerIndex())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      dispatch(getProjectFilterForCustomerIndex(user.user_id))
    }
  }, [dispatch, user])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Clients" breadcrumbItem="Customer Index" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Search</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                          >
                            <span className="number">1.</span> By Email
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                          >
                            <span className="number">2.</span>{" "}
                            <span>By brand Name</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 3,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 3,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(3)
                            }}
                          >
                            <span className="number">3.</span> By State
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 4,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 4,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(4)
                            }}
                          >
                            <span className="number">4.</span> By City
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 5,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 5,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(5)
                            }}
                          >
                            <span className="number">5.</span> by Pincode
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 6,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 6,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(6)
                            }}
                          >
                            <span className="number">6.</span> By Pan No.
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 7,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 7,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(7)
                            }}
                          >
                            <span className="number">7.</span> By Tan No.
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 8,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 8,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(8)
                            }}
                          >
                            <span className="number">8.</span> By Gst No.
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 9,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 9,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(9)
                            }}
                          >
                            <span className="number">9.</span> By Contact No.
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 10,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 10,
                              }),
                              "done")
                            }
                            onClick={() => {
                              toggleTabVertical(10)
                            }}
                            // disabled={!(passedStepsVertical || []).includes(10)}
                          >
                            <span className="number">10.</span> By Project Name
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="search_value">Email</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="search_value"
                                    name="search_value"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.search_value || ""}
                                    placeholder="Enter Your Email ID"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="search_value">
                                    Brand Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="search_value"
                                    name="search_value"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.search_value || ""}
                                    placeholder="Enter Your Brand Name"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>State</Label>
                                  <select
                                    className="form-select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    name="search_value"
                                    value={validation.values.search_value || ""}
                                  >
                                    <option value="">Select State</option>
                                    {statesList.map((state, index) => (
                                      <option key={index} value={state.id}>
                                        {state.state_name}
                                      </option>
                                    ))}
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label>City</Label>
                                  <select
                                    className="form-select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    name="search_value"
                                    value={validation.values.search_value || ""}
                                  >
                                    <option value="">Select City</option>
                                    {cityList.map((city, index) => (
                                      <option key={index} value={city.id}>
                                        {city.city_name}
                                      </option>
                                    ))}
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={5}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="search_value">Pincode</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="search_value"
                                    name="search_value"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.search_value || ""}
                                    placeholder="Enter Pincode"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={6}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="search_value">PAN No.</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="search_value"
                                    name="search_value"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.search_value || ""}
                                    placeholder="Enter PAN No."
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={7}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="search_value">TAN No.</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="search_value"
                                    name="search_value"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.search_value || ""}
                                    placeholder="Enter TAN No."
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={8}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="search_value">GST No.</Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="search_value"
                                    name="search_value"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.search_value || ""}
                                    placeholder="Enter GST No."
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={9}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="search_value">
                                    Contact No.
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="search_value"
                                    name="search_value"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.search_value || ""}
                                    placeholder="Enter Contact No."
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={10}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>Project Name</Label>
                                    <select
                                      className="form-select"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      name="search_value"
                                      value={
                                        validation.values.search_value || ""
                                      }
                                    >
                                      <option value="">Select Project</option>
                                      {projectList &&
                                        projectList.map((project, index) => (
                                          <option
                                            key={index}
                                            value={project.project_name}
                                          >
                                            {project.project_name}
                                          </option>
                                        ))}
                                    </select>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li className={"next "}>
                          {customerIndexList.length > 0 &&
                            validation.values.search_value && (
                              <Link
                                className="me-2"
                                style={{ background: "green" }}
                                to="#"
                                onClick={
                                  handleExport
                                }
                              >
                                Export
                              </Link>
                            )}
                          <Link to="#" onClick={validation.handleSubmit}>
                            Submit
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {customerIndexList.length > 0 &&
                  validation.values.search_value ? (
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>#</th>
                            <th>Type</th>
                            <th>Client Code</th>
                            <th>Client Name</th>
                            <th>Mobile No.</th>
                            <th>Email Address</th>
                            <th>Units Assigned</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerIndexList?.map((customer, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {customer?.customer_type === 1
                                  ? "Individual"
                                  : "Brand"}
                              </td>
                              <td>
                                <Link
                                  to={`/clients/view-client/${customer.id}`}
                                >
                                  {customer?.customer_code}
                                </Link>
                              </td>
                              <td>{customer?.customer_name}</td>
                              <td>{customer?.mobile_no}</td>
                              <td>{customer?.email_address}</td>
                              <td>{customer?.unit_codes}</td>
                              <td
                                style={{
                                  color:
                                    customer.is_active === 1 ? "green" : "red",
                                }}
                              >
                                {customer.is_active === 1
                                  ? "Active"
                                  : "Is Not Active"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <Alert color="danger" role="alert" className="text-center">
                      No data Available...
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerIndex
