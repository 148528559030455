import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import {
  Input,
  Label,
  Modal,
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  FormFeedback,
  Button,
} from "reactstrap"

// Form Editor
import { Editor, EditorState } from "react-draft-wysiwyg"
import { convertToRaw } from "draft-js"
import { ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import { TextEditor } from "./TextEditor"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { sendReceiptEmailToCustomer } from "helpers/fakebackend_helper"

const LargeModal = ({ isOpen, toggle, customerId,receipt_no ,data}) => {
  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      to: "",
      cc: "",
      subject: "",
      message: "<p></p>",
      send_pdf_attachment: false,
    },
    validationSchema: Yup.object().shape({}),

    onSubmit: async values => {
      // dispatch(sendReceiptEmailToCustomer(customerId, values))

   try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        const response = await sendReceiptEmailToCustomer(customerId, values)
        if (response && response.status === true) {
          loadingSwal.close()
          validation.setFieldValue('cc','')
          validation.setFieldValue('subject','')
          validation.setFieldValue('message','<p></p>')
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Your Mail has been sent.",
          }).then(result => {
            if (result.isConfirmed) {
              toggle()
            }
          })
        } else {
          loadingSwal.close()

          Swal.fire({
            title: "failure",
            icon: "error",
            text: "Your Mail has not been sent.",
          })
        }
      } catch (error) {
        console.error("Error:", error)
        Swal.fire({
          title: "Error",
          icon: "error",
          text:
            error.response.data.message ||
            "An error occurred while saving your work.",
        })
      }
    },
  })

  useEffect(()=>{
    if(data){
      validation.setFieldValue('to',data.email_address)
    }
  },[data])

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Draft Mail: Receipt No. {receipt_no}
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <div className="mb-3">
            <Col>
              <Label>To*</Label>
              <Input
                type="text"
                name="to"
                className="form-control mb-2"
                id="to"
                placeholder="Enter To"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.to || ""}
                invalid={
                  validation.touched.to && validation.errors.to ? true : false
                }
              />
              {validation.touched.to && validation.errors.to ? (
                <FormFeedback type="invalid">
                  {validation.errors.to}
                </FormFeedback>
              ) : null}
              For multiple receipients use comma (,). Eg.
              abcd@gmail.com,xyz@gmail.com
            </Col>
          </div>
        </Row>
        <Row>
          <div className="mb-3">
            <Col>
              <Label>CC</Label>
              <Input
                type="text"
                name="cc"
                className="form-control mb-2"
                id="cc"
                placeholder="Enter CC"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.cc || ""}
                invalid={
                  validation.touched.cc && validation.errors.cc ? true : false
                }
              />
              {validation.touched.cc && validation.errors.cc ? (
                <FormFeedback type="invalid">
                  {validation.errors.cc}
                </FormFeedback>
              ) : null}
               For multiple receipients use comma (,). Eg.
              abcd@gmail.com,xyz@gmail.com
            </Col>
          </div>
        </Row>
        <Row>
          <div className="mb-3">
            <Col>
              <Label>Subject*</Label>
              <Input
                type="text"
                name="subject"
                className="form-control mb-2"
                id="subject"
                placeholder="Enter Subject"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.subject || ""}
                invalid={
                  validation.touched.subject && validation.errors.subject
                    ? true
                    : false
                }
              ></Input>
            </Col>
          </div>
        </Row>

        <Row>
          <Col>
            <Label>Message:</Label>
            <Form method="post">
              <TextEditor
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                setFieldValue={val => validation.setFieldValue("message", val)}
                value={validation.values.message}
              />
            </Form>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="12">
            <div className="form-check form-check-inline">
              <Input
                type="checkbox"
                className="form-check-Input"
                id="send_pdf_attachment"
                name="send_pdf_attachment"
                onChange={e => {
                  validation.setFieldValue(
                    "send_pdf_attachment",
                    e.target.checked
                  )
                }}
                checked={Boolean(validation?.values?.send_pdf_attachment)}
              />
              <Label className="form-check-Label" htmlFor="formrow-customCheck">
                Attach Receipt
              </Label>
            </div>
            {validation.touched.send_pdf_attachment &&
              validation.errors.send_pdf_attachment && (
                <FormFeedback type="invalid">
                  {validation.errors.send_pdf_attachment}
                </FormFeedback>
              )}
          </Col>
        </Row>
        <Row>
          <div className="mb-2">
            <Col className="text-center">
              <button
                onClick={validation.handleSubmit}
                className="btn btn-primary mt-3 "
              >
                {" "}
                Submit{" "}
              </button>
            </Col>
          </div>
        </Row>
      </div>
    </Modal>
  )
}

export default LargeModal
