import {
  GET_COUNT_FOR_TICKETS_DASHBOARD,
  GET_COUNT_FOR_TICKETS_DASHBOARD_SUCCESS,
  GET_COUNT_FOR_TICKETS_DASHBOARD_FAIL,
  GET_CRITICAL_PRIORITY_TICKET_LIST,
  GET_CRITICAL_PRIORITY_TICKET_LIST_SUCCESS,
  GET_CRITICAL_PRIORITY_TICKET_LIST_FAIL,
  GET_HIGH_PRIORITY_TICKET_LIST,
  GET_HIGH_PRIORITY_TICKET_LIST_SUCCESS,
  GET_HIGH_PRIORITY_TICKET_LIST_FAIL,
  GET_MEDIUM_PRIORITY_TICKET_LIST,
  GET_MEDIUM_PRIORITY_TICKET_LIST_SUCCESS,
  GET_MEDIUM_PRIORITY_TICKET_LIST_FAIL,
  GET_LOW_PRIORITY_TICKET_LIST,
  GET_LOW_PRIORITY_TICKET_LIST_SUCCESS,
  GET_LOW_PRIORITY_TICKET_LIST_FAIL,
  GET_RECENT_TICKETS_LIST,
  GET_RECENT_TICKETS_LIST_SUCCESS,
  GET_RECENT_TICKETS_LIST_FAIL,
  GET_USER_VENDOR_WITH_MOST_TICKET,
  GET_USER_VENDOR_WITH_MOST_TICKET_SUCCESS,
  GET_USER_VENDOR_WITH_MOST_TICKET_FAIL,
  GET_OPEN_CLOSE_TICKET_GRAPH,
  GET_OPEN_CLOSE_TICKET_GRAPH_SUCCESS,
  GET_OPEN_CLOSE_TICKET_GRAPH_FAIL,
  GET_TICKETS_STATUS_PIE_DIGRAM,
  GET_TICKETS_STATUS_PIE_DIGRAM_SUCCESS,
  GET_TICKETS_STATUS_PIE_DIGRAM_FAIL,
  GET_TICKETS_PRIORITY_PIE_DIGRAM,
  GET_TICKETS_PRIORITY_PIE_DIGRAM_SUCCESS,
  GET_TICKETS_PRIORITY_PIE_DIGRAM_FAIL,
} from "./actionTypes"

export const getCountForTicketsDashboard = () => ({
  type: GET_COUNT_FOR_TICKETS_DASHBOARD,
})

export const getCountForTicketsDashboardSuccess = count => ({
  type: GET_COUNT_FOR_TICKETS_DASHBOARD_SUCCESS,
  payload: count,
})

export const getCountForTicketsDashboardFail = error => ({
  type: GET_COUNT_FOR_TICKETS_DASHBOARD_FAIL,
  payload: error,
})
export const getCriticalPriorityTicketList = () => ({
  type: GET_CRITICAL_PRIORITY_TICKET_LIST,
})

export const getCriticalPriorityTicketListSuccess = criticalPriorityList => ({
  type: GET_CRITICAL_PRIORITY_TICKET_LIST_SUCCESS,
  payload: criticalPriorityList,
})

export const getCriticalPriorityTicketListFail = error => ({
  type: GET_CRITICAL_PRIORITY_TICKET_LIST_FAIL,
  payload: error,
})
export const getHighPriorityTicketList = () => ({
  type: GET_HIGH_PRIORITY_TICKET_LIST,
})

export const getHighPriorityTicketListSuccess = highPriorityList => ({
  type: GET_HIGH_PRIORITY_TICKET_LIST_SUCCESS,
  payload: highPriorityList,
})

export const getHighPriorityTicketListFail = error => ({
  type: GET_HIGH_PRIORITY_TICKET_LIST_FAIL,
  payload: error,
})
export const getMediumPriorityTicketList = () => ({
  type: GET_MEDIUM_PRIORITY_TICKET_LIST,
})

export const getMediumPriorityTicketListSuccess = mediumPriorityList => ({
  type: GET_MEDIUM_PRIORITY_TICKET_LIST_SUCCESS,
  payload: mediumPriorityList,
})

export const getMediumPriorityTicketListFail = error => ({
  type: GET_MEDIUM_PRIORITY_TICKET_LIST_FAIL,
  payload: error,
})
export const getLowPriorityTicketList = () => ({
  type: GET_LOW_PRIORITY_TICKET_LIST,
})

export const getLowPriorityTicketListSuccess = lowPriorityList => ({
  type: GET_LOW_PRIORITY_TICKET_LIST_SUCCESS,
  payload: lowPriorityList,
})

export const getLowPriorityTicketListFail = error => ({
  type: GET_LOW_PRIORITY_TICKET_LIST_FAIL,
  payload: error,
})

export const getRecentTicketList = () => ({
  type: GET_RECENT_TICKETS_LIST,
})

export const getRecentTicketListSuccess = recentTickets => ({
  type: GET_RECENT_TICKETS_LIST_SUCCESS,
  payload: recentTickets,
})

export const getRecentTicketListFail = error => ({
  type: GET_RECENT_TICKETS_LIST_FAIL,
  payload: error,
})

export const getUserVendorWithMostTicket = () => ({
  type: GET_USER_VENDOR_WITH_MOST_TICKET,
})

export const getUserVendorWithMostTicketSuccess = mostTickets => ({
  type: GET_USER_VENDOR_WITH_MOST_TICKET_SUCCESS,
  payload: mostTickets,
})

export const getUserVendorWithMostTicketFail = error => ({
  type: GET_USER_VENDOR_WITH_MOST_TICKET_FAIL,
  payload: error,
})

export const getOpenCloseTicketGraph = openCloseTicketGraph => ({
  type: GET_OPEN_CLOSE_TICKET_GRAPH,
  payload: openCloseTicketGraph,
})

export const getOpenCloseTicketGraphSuccess = openCloseTicketGraph => ({
  type: GET_OPEN_CLOSE_TICKET_GRAPH_SUCCESS,
  payload: openCloseTicketGraph,
})

export const getOpenCloseTicketGraphFail = error => ({
  type: GET_OPEN_CLOSE_TICKET_GRAPH_FAIL,
  payload: error,
})

export const getTicketStatusPieDigram = ticketStatusPieDigram => ({
  type: GET_TICKETS_STATUS_PIE_DIGRAM,
  payload: ticketStatusPieDigram,
})

export const getTicketStatusPieDigramSuccess = ticketStatusPieDigram => ({
  type: GET_TICKETS_STATUS_PIE_DIGRAM_SUCCESS,
  payload: ticketStatusPieDigram,
})

export const getTicketStatusPieDigramFail = error => ({
  type: GET_TICKETS_STATUS_PIE_DIGRAM_FAIL,
  payload: error,
})

export const getTicketPriorityPieDigram = ticketPriorityPieDigram => ({
  type: GET_TICKETS_PRIORITY_PIE_DIGRAM,
  payload: ticketPriorityPieDigram,
})

export const getTicketPriorityPieDigramSuccess = ticketPriorityPieDigram => ({
  type: GET_TICKETS_PRIORITY_PIE_DIGRAM_SUCCESS,
  payload: ticketPriorityPieDigram,
})

export const getTicketPriorityPieDigramFail = error => ({
  type: GET_TICKETS_PRIORITY_PIE_DIGRAM_FAIL,
  payload: error,
})
