import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
} from "reactstrap"
import {
  addBillingSetup,
  editBillingSetup,
  getApplicableRateList,
  getBillingClientDetail,
  getBillingCompanyDetail,
  getBillingCompanyList,
  getBillingSetupDetail,
  getCities,
  getCountries,
  getEngagementService,
  getInvoiceTypeMasterList,
  getPlaceOfSupply,
  getSacHsnCodeBillingSetup,
  getStates,
  getSupplyList,
} from "store/actions"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Spinners from "components/Common/Spinner"

import {
  addNewServiceRequest,
  getClientInvoices,
  getMaintenanceIssues,
  getProjectUnitCategory,
  getProjectUnitDetail,
  getServiceRequestType,
  getSupportTickets,
} from "store/actions"
import { createSelector } from "reselect"
import LargeModal from "./detailedSummaryModal"

const EditBillingSetup = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [isLoading, setLoading] = useState(true)

  const [clientId, setClientId] = useState()
  const [unitId, setUnitId] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const BillingState = state => state.billing
  const path = useLocation()

  const AllBillingProperties = createSelector(
    BillingState,

    billing => ({
      clientDetail: billing.clientDetail.client,
      engagementService: billing.serviceEngagement.project_unit,
      billingCompanyList: billing.companyList.companies,
      billingCompanyDetail: billing.companyDetail.company,
      billingCompanyLocation: billing.companyDetail.location,
      typeOfSupplyList: billing.supplyList.data,
      placeOfSupplyList: billing.placeOfSupply.data,
      applicableRateList: billing.applicableRateList.data,
      invoiceTypeMasterList: billing.invoiceTypeMasterList.data,
      billingSetupDetail: billing.billSetupDetail.data,
      sacHsnCode: billing.codesBillingSetup.data,
    })
  )

  const {
    clientDetail,
    engagementService,
    billingCompanyList,
    billingCompanyDetail,
    billingCompanyLocation,
    typeOfSupplyList,
    placeOfSupplyList,
    applicableRateList,
    invoiceTypeMasterList,
    billingSetupDetail,
    sacHsnCode,
  } = useSelector(AllBillingProperties)

  useEffect(() => {
    dispatch(getBillingCompanyList())
    dispatch(getSupplyList())
    dispatch(getPlaceOfSupply())
    dispatch(getApplicableRateList())
    dispatch(getInvoiceTypeMasterList())
    dispatch(getSacHsnCodeBillingSetup())
  }, [dispatch])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      billing_setup_id:
        (billingSetupDetail && billingSetupDetail.billing_setup_id) || "",
      client_id: clientId,
      client_service_engagement_id: unitId,
      billing_company:
        (billingSetupDetail && billingSetupDetail.billing_company) || "",
      billing_mode:
        (billingSetupDetail && billingSetupDetail.billing_mode) || 0,
      billing_frequency:
        (billingSetupDetail && String(billingSetupDetail.billing_frequency)) ||
        "",
      commencement_date:
        (billingSetupDetail && billingSetupDetail.commencement_date) || "",
      type_of_supply:
        (billingSetupDetail && billingSetupDetail.type_of_supply) || "",
      place_of_supply:
        (billingSetupDetail && billingSetupDetail.place_of_supply) || "",
      gst_applicable:
        (billingSetupDetail && billingSetupDetail.gst_applicable) || "no",
      applicable_rate:
        (billingSetupDetail && billingSetupDetail.applicable_rate) || "",
      items: billingSetupDetail?.billing_item_details.map(component => ({
        // invoice_type_id: component?.invoice_type_id,
        id: component?.id || "",
        invoice_type: component?.invoice_type?.id || "",
        amount: component?.amount || 0,
        sac_hsm_id: component?.sac_hsm_id || "",
        gst_percentage: component?.gst_percentage || "",
        description: component?.description || "",
        check: component?.check || 0,
      })) ||
    [],
      items_show: billingSetupDetail?.billing_item_details.map(component => ({
        // invoice_type_id: component?.invoice_type_id,
        
        sac_hsn: component?.sac_hsm_code?.sac_hsm_code || "",
        invoice_component: component?.invoice_type?.invoice_type || "",

       
      })) ||
    [],
    },
    validationSchema: Yup.object().shape({
      type_of_service: Yup.number(),
      engagement_type: Yup.string(),
      billing_frequency: Yup.number(),
      commencement_date: Yup.date().required("Please Select Commencement Date"),
      gst_applicable: Yup.string(),
      items: Yup.array().of(
        Yup.object().shape({
          invoice_type: Yup.number(),
          sac_hsm_id: Yup.number(),
          description: Yup.string(),
          amount: Yup.number(),
        })
      ),
    }),

    onSubmit: async values => {
      dispatch(
        editBillingSetup(
          props.router.navigate,
          validation.values.billing_setup_id,
          values
        )
      )
    },
  })

 

  const [rows, setRows] = useState([{ id: 1 }])

  useEffect(() => {
    if (validation && validation.values && validation.values.items) {
      // Update rows state based on the length of items array
      setRows(
        [...Array(validation.values.items.length)].map((_, index) => ({
          id: index,
        }))
      )
    }
  }, [validation.values.items])

  const [hidden, setHidden] = useState(false)
  const addRow = () => {
    // const newRow = { id: rows.length + 1 }
    // setRows([...rows, newRow])
    setHidden(true)
  }
  const removeRow = id => {
    if (id !== 1) {
      var modifiedRows = [...rows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setRows(modifiedRows)
    }
  }

  //Company Detail
  useEffect(() => {
    if (engagementService && engagementService?.id) {
      dispatch(getBillingCompanyDetail(engagementService?.id))
    }
  }, [engagementService?.company_id])

  useEffect(() => {
    if (clientDetail) {
      setLoading(false)
    }
  }, [dispatch, clientDetail])

  useEffect(() => {
    if (engagementService) {
      const clientId = engagementService.client_id
      setClientId(clientId)
      dispatch(dispatch(getBillingClientDetail(clientId)))
    }
    validation.setFieldValue("client_id", clientId)
  }, [engagementService])

  useEffect(() => {
    const pathName = path.pathname
    const unitId = pathName.split("/edit-billing-setup/")[1]
    setUnitId(unitId)
    dispatch(dispatch(getEngagementService(unitId)))

    validation.setFieldValue("client_service_engagement_id", unitId)
  }, [dispatch, path])
  useEffect(() => {
    const pathName = path.pathname
    const unitId = pathName.split("/edit-billing-setup/")[1]
    setUnitId(unitId)
    // dispatch(dispatch(getEngagementService(unitId)))
    dispatch(getBillingSetupDetail(unitId))

    validation.setFieldValue("client_service_engagement_id", unitId)
  }, [dispatch, path])

  //File Upload
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleChange = event => {
    validation.setFieldValue("engagement_type", event.target.value)
  }

  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  const [billingFrequency, setBillingFrequency] = useState(
    (billingSetupDetail && billingSetupDetail.billing_company) || ""
  )
  const [billingDates, setBillingDates] = useState([])
  const [commencementDate, setCommencementDate] = useState("")

  useEffect(() => {
    setBillingFrequency(validation.values.billing_frequency)
    if (commencementDate !== "") {
      calculateBillingDates(
        validation.values.billing_frequency,
        commencementDate
      )
    }
  }, [validation.values.billing_frequency, commencementDate])

  useEffect(() => {
    setCommencementDate(validation.values.commencement_date)
    if (validation.values.billing_frequency !== "") {
      calculateBillingDates(
        validation.values.billing_frequency,
        validation.values.commencement_date
      )
    }
  }, [validation.values.commencement_date, validation.values.billing_frequency])

  const calculateBillingDates = (billingFrequency, commencementDate) => {
    const startDate = new Date(commencementDate)
    const calculatedBillingDates = []

    let interval
    let iterations
    switch (billingFrequency) {
      case "1":
        interval = 1 // Monthly
        iterations = 12
        break
      case "2":
        interval = 3 // Quarterly
        iterations = 4
        break
      case "3":
        interval = 6 // Half-yearly
        iterations = 2
        break
      case "4":
        interval = 12 // Yearly
        iterations = 1
        break
      default:
        interval = 0
        iterations = 0
        break
    }

    if (isNaN(startDate.getTime())) {
      console.error("Invalid commencement date")
      return
    }

    const formatDate = date => {
      const day = String(date.getDate()).padStart(2, "0")
      const monthNames = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ]
      const monthIndex = date.getMonth()
      const year = date.getFullYear()
      const monthName = monthNames[monthIndex]

      return `${day} ${monthName} ${year}`
    }

    const addMonths = (date, months) => {
      const newDate = new Date(date)
      newDate.setMonth(date.getMonth() + months)
      if (newDate.getDate() !== date.getDate()) {
        newDate.setDate(0) // Adjusts for month-end
      }
      return newDate
    }

    let currentDate = new Date(startDate)

    for (let i = 1; i <= iterations; i++) {
      currentDate = addMonths(currentDate, interval)
      calculatedBillingDates.push(formatDate(currentDate))
    }
    setBillingDates(calculatedBillingDates)
  }

  useEffect(() => {
    calculateBillingDates(
      validation.values.billing_frequency,
      validation.values.commencement_date
    )
  }, [validation.values.billing_frequency, validation.values.commencement_date])

  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        data={validation.values}
        applicableRateList={applicableRateList}
        billingCompanyDetail={billingCompanyDetail}
        placeOfSupplyList={placeOfSupplyList}
        invoiceTypeMasterList={invoiceTypeMasterList}
      />
      <div className="page-content ">
        <Container fluid={true}>
          {/* {clientDetail ? ( */}
          <Form
            autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Recurring Billing Setup
              </div>
            </CardTitle>
            <Row className="justify-content">
              <Col lg={8}>
                <Card
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "15px",
                  }}
                >
                  <CardBody>
                    <CardTitle className="mb-3">Client Details</CardTitle>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="customer_code">Customer Code*</Label>
                          <p>{clientDetail && clientDetail.customer_code}</p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="customer_name">Customer Name*</Label>
                          <p>{clientDetail && clientDetail.customer_name} </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="email_address">Email address*</Label>
                          <p> {clientDetail && clientDetail.email_address} </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="mobile_no">Mobile No.*</Label>
                          <p> {clientDetail && clientDetail.mobile_no} </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="address">Address*</Label>
                          <p>{clientDetail && clientDetail.address} </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="pincode">Pincode*</Label>
                          <p>{clientDetail && clientDetail.pincode}</p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label for="country">Country*</Label>
                          <p>{clientDetail && clientDetail.country_name}</p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="state">State*</Label>
                          <p>{clientDetail && clientDetail.state_name} </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="city">City*</Label>
                          <p>{clientDetail && clientDetail.city_name}</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content">
              <Col lg={8}>
                <Card
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "15px",
                  }}
                >
                  <CardBody>
                    <CardTitle className="mb-3">
                      Service Engagement Information
                    </CardTitle>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="project">Project*</Label>
                          <p>
                            {" "}
                            {engagementService &&
                              engagementService.project_name}{" "}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="project_unit">Project Unit*</Label>
                          <p>
                            {" "}
                            {engagementService &&
                              engagementService.unit_code}{" "}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="minimum_monthly_rent">
                            Minimum Monthly Rent*
                          </Label>
                          <p>
                            {" "}
                            {engagementService &&
                              engagementService.minimum_monthly_rent}{" "}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="revenue_share">Revenue Share*</Label>
                          <p>
                            {" "}
                            {engagementService &&
                              engagementService.revenue_share}{" "}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="revenue_share_percentage">
                            Revenue Share(%)*
                          </Label>
                          <p>
                            {" "}
                            {engagementService &&
                              engagementService.revenue_share_percentage}{" "}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="rent_payment_schedule">
                            Rent Payment Schedule*
                          </Label>
                          <p>
                            {" "}
                            {engagementService &&
                              engagementService.rent_payment_schedule}{" "}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label for="customer_lock_in_period">
                            Customer Lock-in Period*(Days)
                          </Label>
                          <p>
                            {" "}
                            {engagementService &&
                              engagementService.customer_lock_in_period}{" "}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label for="company_lock_in_period">
                            Company Lock-in Period*(Days)
                          </Label>
                          <p>
                            {" "}
                            {engagementService &&
                              engagementService.company_lock_in_period}{" "}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="notice_period">
                            Notice Period*(Days)
                          </Label>
                          <p>
                            {engagementService &&
                              engagementService.notice_period}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="security_deposit">
                            Security Deposit*
                          </Label>
                          <p>
                            {engagementService &&
                              engagementService.security_deposit}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="other_deposit">
                            Other Deposit(Optional)
                          </Label>
                          <p>
                            {(engagementService &&
                              engagementService.other_deposit) ||
                              "NA"}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="agreement_start_date">
                            Agreement Start Date*
                          </Label>
                          <p>
                            {engagementService &&
                              engagementService.agreement_start_date}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="agreement_end_date">
                            Agreement End Date*
                          </Label>
                          <p>
                            {engagementService &&
                              engagementService.agreement_end_date}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="rent_escalation_percentage">
                            Rent Escalation(%)
                          </Label>
                          <p>
                            {engagementService &&
                              engagementService.rent_escalation_percentage}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="registration_cost_for_agreement_on_company">
                            Registration cost for Agreement on Company
                          </Label>
                          <p>
                            {engagementService &&
                              engagementService.registration_cost_for_agreement_on_company}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="rent_escalation_period">
                            Rent Escalation Period(Months)
                          </Label>
                          <p>
                            {engagementService &&
                              engagementService.rent_escalation_period}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content">
              <Col lg={4}>
                <Card
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "15px",
                  }}
                >
                  <CardBody
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderRadius: "15px",
                    }}
                  >
                    <CardTitle className="mb-3">Company Detail</CardTitle>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="rent_escalation_percent">
                            Company Name
                          </Label>
                          <p>
                            {billingCompanyDetail && billingCompanyDetail.name}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="address_line_1">Address</Label>
                          <p>
                            {billingCompanyDetail &&
                              billingCompanyDetail.address_line_1}{" "}
                            {billingCompanyDetail &&
                              billingCompanyDetail.address_line_2}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="pin_code">Pincode</Label>
                          <p>
                            {billingCompanyDetail &&
                              billingCompanyDetail.pin_code}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="country_name">Country</Label>
                          <p>
                            {billingCompanyDetail &&
                              billingCompanyDetail.country_name}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="state_name">State</Label>
                          <p>
                            {billingCompanyDetail &&
                              billingCompanyDetail.state_name}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="city_name">City</Label>
                          <p>
                            {billingCompanyDetail &&
                              billingCompanyDetail.city_name}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <CardBody
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderRadius: "15px",
                    }}
                  >
                    <CardTitle className="mb-3">
                      Billing Company's Location Detail
                    </CardTitle>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="pan_no">Address</Label>
                          <p>
                            {billingCompanyLocation &&
                              billingCompanyLocation.location_title}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="pan_no">Country</Label>
                          <p>
                            {billingCompanyLocation &&
                              billingCompanyLocation.country_name}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="pan_no">State</Label>
                          <p>
                            {billingCompanyLocation &&
                              billingCompanyLocation.state_name}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="pan_no">City</Label>
                          <p>
                            {billingCompanyLocation &&
                              billingCompanyLocation.city_name}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="pan_no">GST No.</Label>
                          <p>
                            {billingCompanyLocation &&
                              billingCompanyLocation.gst_no}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {String(validation.values.billing_mode) === "1" && (
              <>
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-3">
                          Billing Item Details
                        </CardTitle>
                        <Row>
                          <Col lg={1} style={{ width: "50px" }}>
                            <div className="mb-3">
                              <Label></Label>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Invoice Components</Label>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Description</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>Amount</Label>
                            </div>
                          </Col>

                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>SAC / HSN Code</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>GST Rate</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>GST Amount</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label>Total</Label>
                            </div>
                          </Col>
                          <Col lg={1}>
                            <div className="mb-3">
                              <Label> </Label>
                            </div>
                          </Col>
                        </Row>
                        {validation.values?.items?.map(
                          (row, key) =>
                            row.check === 1 && (
                              <Row key={key}>
                                <Col
                                  lg={1}
                                  className="d-flex align-items-center justify-content-end"
                                  style={{ width: "50px" }}
                                >
                                  <div className="mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`record_${key}`}
                                      name={`items[${key}].check`}
                                      onChange={e => {
                                        const { name, checked } = e.target
                                        validation.setFieldValue(
                                          name,
                                          checked ? 1 : 0
                                        ) // Update form field value
                                      }}
                                      onBlur={validation.handleBlur}
                                      checked={
                                        Number(
                                          validation.values.items[key]?.check
                                        ) === 1
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  className="d-flex align-items-center"
                                >
                                  <div className="mb-3">
                                    <p
                                      htmlFor={`invoice_type_${key}`}
                                      className="form-label"
                                    >
                                      {validation?.values?.items_show[key]
                                          ?.invoice_component || ""}
                                    </p>
                                  </div>
                                </Col>
                                <Col lg={2}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`description_${key}`}
                                      name={`items[${key}].description`}
                                      placeholder="Enter Description"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]
                                          ?.description || ""
                                      }
                                    />
                                    {validation.touched.items?.[key]
                                      ?.description &&
                                      validation.errors.items?.[key]
                                        ?.description && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.description
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`amount_${key}`}
                                      name={`items[${key}].amount`}
                                      placeholder="Enter Amount"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]?.amount ||
                                        ""
                                      }
                                    />
                                    {validation.touched.items?.[key]?.amount &&
                                      validation.errors.items?.[key]
                                        ?.amount && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.items[key]?.amount}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>

                                {/* <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`discount_${key}`}
                                      name={`items[${key}].discount`}
                                      placeholder="Enter Discount"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]
                                          ?.discount || ""
                                      }
                                    />
                                    {validation.touched.items?.[key]
                                      ?.discount &&
                                      validation.errors.items?.[key]
                                        ?.discount && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.discount
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col> */}
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`sac_hsn_code_${key}`}
                                      name={`items[${key}].sac_hsn`}
                                      placeholder="NA"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      disabled
                                      value={
                                        validation.values.items_show[key]?.sac_hsn ||
                                        ""
                                      }
                                    />
                                    {validation.touched.items?.[key]
                                      ?.sac_hsn_code &&
                                      validation.errors.items?.[key]
                                        ?.sac_hsn_code && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.sac_hsn_code
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      disabled
                                      id={`gst_percentage${key}`}
                                      name={`items[${key}].gst_percentage`}
                                      placeholder="NA"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]
                                          ?.gst_percentage || ""
                                      }
                                    />
                                    {validation.touched.items?.[key]
                                      ?.gst_percentage &&
                                      validation.errors.items?.[key]
                                        ?.gst_percentage && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.gst_percentage
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>

                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]?.amount *
                                          (validation.values.items[key]
                                            ?.gst_percentage /
                                            100) || "0"
                                      }
                                      id={`gst_amount${key}`}
                                      name={`items[${key}].gst_amount`}
                                      placeholder="GST Amount"
                                      disabled
                                    />
                                    {validation.touched.items?.[key]
                                      ?.gst_amount &&
                                      validation.errors.items?.[key]
                                        ?.gst_amount && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.gst_amount
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      name={`items[${key}].total`}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        parseFloat(
                                          validation.values.items[key]
                                            ?.amount || 0
                                        ) +
                                          ((parseFloat(
                                            validation.values.items[key]?.amount
                                          ) || 0) *
                                            (parseFloat(
                                              validation.values.items[key]
                                                ?.gst_percentage
                                            ) /
                                              100) || 0) || "0"
                                      }
                                      id={`total_${key}`}
                                      placeholder="Total"
                                      disabled
                                    />
                                  </div>
                                </Col>
                                {/* <Col lg={1} className="d-flex align-items-center">
                              <div className="mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`record_${key}`}
                                  name={`billing_components[${key}].check`}
                                  onChange={e => {
                                    const { name, checked } = e.target
                                    validation.setFieldValue(
                                      name,
                                      checked ? 1 : 0
                                    ) // Update form field value
                                  }}
                                  onBlur={validation.handleBlur}
                                  checked={
                                    Number(
                                      validation.values.billing_components[key]
                                        ?.check
                                    ) === 1
                                  }
                                />
                              </div>
                            </Col> */}
                                {/* <Col>
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    // className="btn btn-danger "
                                    style={{
                                      border: "none",
                                      background: "none",
                                      padding: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeRow(row.id)}
                                  >
                                    <i
                                      className="mdi mdi-delete"
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </button>
                                </div>
                              </Col> */}
                              </Row>
                            )
                        )}

                        {validation.values.items?.map(
                          (row, key) =>
                            row.check === 0 &&
                            hidden && (
                              <Row key={key}>
                                <Col
                                  lg={1}
                                  className="d-flex align-items-center justify-content-end"
                                  style={{ width: "50px" }}
                                >
                                  <div className="mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`record_${key}`}
                                      name={`items[${key}].check`}
                                      onChange={e => {
                                        const { name, checked } = e.target
                                        validation.setFieldValue(
                                          name,
                                          checked ? 1 : 0
                                        )
                                      }}
                                      onBlur={validation.handleBlur}
                                      checked={
                                        Number(
                                          validation.values.items[key]?.check
                                        ) === 1
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col
                                  lg={2}
                                  className="d-flex align-items-center"
                                >
                                  <div className="mb-3">
                                    {validation.values.items.length > 0 ? (
                                      <p
                                      htmlFor={`invoice_type_${key}`}
                                      className="form-label"
                                    >
                                      {validation?.values?.items_show[key]
                                          ?.invoice_component || ""}
                                    </p>
                                    ) : (
                                      <select
                                        className={`form-control ${
                                          validation.touched.items?.[key]
                                            ?.sac_hsn_id &&
                                          validation.errors.items?.[key]
                                            ?.sac_hsn_id
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.items[key]
                                            ?.sac_hsn_id || ""
                                        }
                                        name={`items[${key}].sac_hsn_id`}
                                        disabled
                                      >
                                        {(sacHsnCode || []).map(code => (
                                          <option key={code.id} value={code.id}>
                                            {code.sac_hsm_code}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </div>
                                </Col>
                                <Col lg={2}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`description_${key}`}
                                      name={`items[${key}].description`}
                                      placeholder="Enter Description"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]
                                          ?.description || ""
                                      }
                                    />
                                    {validation.touched.items?.[key]
                                      ?.description &&
                                      validation.errors.items?.[key]
                                        ?.description && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.description
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`amount_${key}`}
                                      name={`items[${key}].amount`}
                                      placeholder="Enter Amount"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]?.amount ||
                                        ""
                                      }
                                    />
                                    {validation.touched.items?.[key]?.amount &&
                                      validation.errors.items?.[key]
                                        ?.amount && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.items[key]?.amount}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>

                                {/* <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`discount_${key}`}
                                      name={`items[${key}].discount`}
                                      placeholder="Enter Discount"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]
                                          ?.discount || ""
                                      }
                                    />
                                    {validation.touched.items?.[key]
                                      ?.discount &&
                                      validation.errors.items?.[key]
                                        ?.discount && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.discount
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col> */}
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      id={`sac_hsn_code_${key}`}
                                      name={`items[${key}].sac_hsn`}
                                      placeholder="NA"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      disabled
                                      value={
                                        validation.values.items_show[key]?.sac_hsn ||
                                        ""
                                      }
                                    />
                                    {validation.touched.items?.[key]
                                      ?.sac_hsn_code &&
                                      validation.errors.items?.[key]
                                        ?.sac_hsn_code && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.sac_hsn_code
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      disabled
                                      id={`gst_percentage${key}`}
                                      name={`items[${key}].gst_percentage`}
                                      placeholder="NA"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]
                                          ?.gst_percentage || ""
                                      }
                                    />
                                    {validation.touched.items?.[key]
                                      ?.gst_percentage &&
                                      validation.errors.items?.[key]
                                        ?.gst_percentage && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.gst_percentage
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>

                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.items[key]?.amount *
                                          (validation.values.items[key]
                                            ?.gst_percentage /
                                            100) || "0"
                                      }
                                      id={`gst_amount${key}`}
                                      name={`items[${key}].gst_amount`}
                                      placeholder="GST Amount"
                                      disabled
                                    />
                                    {validation.touched.items?.[key]
                                      ?.gst_amount &&
                                      validation.errors.items?.[key]
                                        ?.gst_amount && (
                                        <FormFeedback type="invalid">
                                          {
                                            validation.errors.items[key]
                                              ?.gst_amount
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="mb-3">
                                    <Input
                                      type="text"
                                      name={`items[${key}].total`}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        parseFloat(
                                          validation.values.items[key]
                                            ?.amount || 0
                                        ) +
                                          ((parseFloat(
                                            validation.values.items[key]?.amount
                                          ) || 0) *
                                            (parseFloat(
                                              validation.values.items[key]
                                                ?.gst_percentage
                                            ) /
                                              100) || 0) || "0"
                                      }
                                      id={`total_${key}`}
                                      placeholder="Total"
                                      disabled
                                    />
                                  </div>
                                </Col>
                                {/* <Col lg={1} className="d-flex align-items-center">
                              <div className="mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`record_${key}`}
                                  name={`billing_components[${key}].check`}
                                  onChange={e => {
                                    const { name, checked } = e.target
                                    validation.setFieldValue(
                                      name,
                                      checked ? 1 : 0
                                    ) // Update form field value
                                  }}
                                  onBlur={validation.handleBlur}
                                  checked={
                                    Number(
                                      validation.values.billing_components[key]
                                        ?.check
                                    ) === 1
                                  }
                                />
                              </div>
                            </Col> */}
                                {/* <Col>
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    // className="btn btn-danger "
                                    style={{
                                      border: "none",
                                      background: "none",
                                      padding: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeRow(row.id)}
                                  >
                                    <i
                                      className="mdi mdi-delete"
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </button>
                                </div>
                              </Col> */}
                              </Row>
                            )
                        )}
                        {hidden ? null : (
                          <Row>
                            <Col>
                              <div className="mb-3 text-start">
                                <button
                                  type="button"
                                  className="btn btn-primary w-md me-2"
                                  style={{ width: "10%" }}
                                  onClick={addRow}
                                >
                                  Add New
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">Billing Setup</CardTitle>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <select
                            className="form-select"
                            onChange={validation.handleChange}
                            name="billing_mode"
                            value={validation.values.billing_mode}
                          >
                            <option>Billing Mode</option>
                            <option value="1">Automated Invoicing</option>
                            <option value="0">Manual Invoicing</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    {String(validation.values.billing_mode) === "0" && (
                      <Row>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="commencement_date">
                              Commencement Date
                            </Label>
                            <Input
                              type="date"
                              id="commencement_date"
                              name="commencement_date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.commencement_date}
                              className={`form-control ${
                                validation.touched.commencement_date &&
                                validation.errors.commencement_date
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {validation.touched.commencement_date &&
                            validation.errors.commencement_date ? (
                              <FormFeedback type="invalid">
                                {validation.errors.commencement_date}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {String(validation.values.billing_mode) === "1" && (
                      <>
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label htmlFor="billing_frequency">
                                Billing Frequency
                              </Label>
                              <select
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="billing_frequency"
                                value={validation.values.billing_frequency}
                              >
                                <option>Billing Frequency</option>
                                <option value="1">Monthly</option>
                                <option value="2">Quaterly</option>
                                <option value="3">Half-Yearly</option>
                                <option value="4">Yearly</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label htmlFor="commencement_date">
                                Commencement Date
                              </Label>
                              <Input
                                type="date"
                                id="commencement_date"
                                name="commencement_date"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.commencement_date}
                                className={`form-control ${
                                  validation.touched.commencement_date &&
                                  validation.errors.commencement_date
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.commencement_date &&
                              validation.errors.commencement_date ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.commencement_date}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {billingDates.length > 0 && (
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label htmlFor="billing_dates">
                                  Billing Dates
                                </Label>
                                <ul
                                  className="list-group text-center"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: 0,
                                  }}
                                >
                                  {billingDates.map((date, index) => (
                                    <li
                                      key={index}
                                      className="list-group-item"
                                      style={{
                                        backgroundColor: "#D0F2FF",
                                        listStyleType: "disc",
                                        marginRight: "5px",
                                        borderRadius: "20px",
                                      }}
                                    >
                                      {date}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label htmlFor="type_of_supply">
                                Type of supply
                              </Label>
                              <select
                                className="form-select"
                                onChange={validation.handleChange}
                                name="type_of_supply"
                                value={validation.values.type_of_supply}
                              >
                                <option>Select Type of supply</option>
                                {(typeOfSupplyList || []).map(supply => (
                                  <option key={supply.id} value={supply.id}>
                                    {supply.gst_type_of_supply}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.type_of_supply &&
                              validation.errors.type_of_supply ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.type_of_supply}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label htmlFor="place_of_supply">
                                Place of supply
                              </Label>
                              <select
                                className="form-select"
                                onChange={validation.handleChange}
                                name="place_of_supply"
                                value={validation.values.place_of_supply}
                              >
                                <option>Select Place of supply</option>
                                {(placeOfSupplyList || []).map(supply => (
                                  <option key={supply.id} value={supply.id}>
                                    {supply.state}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.place_of_supply &&
                              validation.errors.place_of_supply ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.place_of_supply}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button type="submit" className="btn btn-primary w-lg ">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          {/* // ) : (
          //   <Spinners setLoading={setLoading} />
          // )} */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditBillingSetup)

EditBillingSetup.propTypes = {
  history: Proptypes.object,
}
