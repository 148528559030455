import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"

import { Card, Col, Container, Row, CardBody, CardTitle } from "reactstrap"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Spinners from "components/Common/Spinner"
import TicketCount from "./Components/typeOfRequest"
import OpenCloseTicketGraph from "./Components/openCloseTicketGraph"
import LatestTranaction from "./Components/LatestTranaction"
import MostTicketsCount from "./Components/mostTicketsCount"
import TicketStatusWisePieChart from "./Components/ticketStatusWisePieChart"
import TicketPriorityWisePieChart from "./Components/ticketPriorityWisePieChart"

const TicketsDashboard = () => {

  const navigate = useNavigate()

  return (
    <React.Fragment>
      {/* {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : ( */}
      <div className="page-content ">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={12}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  Tickets Dashboard
                </div>
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <TicketCount></TicketCount>
          </Row>
          <Row>
            <OpenCloseTicketGraph></OpenCloseTicketGraph>
          </Row>
          <Row>
            <Col xs="6">
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardBody style={{ flex: 1 }}>
                  <TicketStatusWisePieChart />
                </CardBody>
              </Card>
            </Col>
            <Col xs="6">
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardBody style={{ flex: 1 }}>
                  <TicketPriorityWisePieChart />
                </CardBody>
              </Card>
            </Col>
            {/* <Col xs="6">
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardBody style={{ flex: 1 }}>
                  <MostTicketsCount />
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row className="mt-4">
            <LatestTranaction />
          </Row>
        </Container>
      </div>
      {/* )} */}
    </React.Fragment>
  )
}

export default TicketsDashboard
