import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Card, Col, CardBody } from "reactstrap"
import { getCountForDashboard } from "store/actions"

const typeOfRequest = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const countState = state => state.Dashboard.count
  const count = useSelector(countState) || []

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCountForDashboard())
  }, [dispatch])

  return (
    <React.Fragment>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <Link to="/projects">
            <CardBody>
              <div className="d-flex">
                <div className="me-3">
                  <p className="text-muted mb-2">
                    Projects{" "}
                    <span style={{ visibility: "hidden" }}>Invisible Text</span>
                  </p>
                  <h5 className="mb-0  text-primary ">
                    {count && count.all_project_count}
                  </h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div
                    className="avatar-title bg-light rounded-circle text-primary font-size-20"
                    style={{ width: "50px", height: "50px", fontSize: "20px" }}
                  >
                    <i className="mdi mdi-room-service-outline"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <Link to="/dashboard/vacant-project-unit-list">
            <CardBody>
              <div className="d-flex">
                <div className="me-3">
                  <p className="text-muted mb-2">Vacant Project Units</p>
                  <h5 className="mb-0 text-warning">
                    {count && count.all_vacant_project_unit_count}
                  </h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div
                    className="avatar-title bg-light rounded-circle text-warning font-size-20"
                    style={{ width: "50px", height: "50px", fontSize: "20px" }}
                  >
                    <i className="mdi mdi-room-service-outline"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <Link to="/clients">
            <CardBody>
              <div className="d-flex">
                <div className="me-3">
                  <p className="text-muted mb-2 ">
                    Customers{" "}
                    <span style={{ visibility: "hidden" }}>Invisible Text</span>
                  </p>
                  <h5 className="mb-0 text-danger">
                    {count && count.all_customer_count}
                  </h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div
                    className="avatar-title bg-light rounded-circle text-danger font-size-20"
                    style={{ width: "50px", height: "50px", fontSize: "20px" }}
                  >
                    <i className="mdi mdi-room-service-outline"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <Link to="/vendors">
            <CardBody>
              <div className="d-flex">
                <div className="me-3">
                  <p className="text-muted mb-2">
                    Vendors{" "}
                    <span style={{ visibility: "hidden" }}>Invisible Text</span>
                  </p>
                  <h5 className="mb-0 text-success">
                    {count && count.all_vendor_count}
                  </h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div
                    className="avatar-title bg-light rounded-circle text-success font-size-20"
                    style={{ width: "50px", height: "50px", fontSize: "20px" }}
                  >
                    <i className="mdi mdi-room-service-outline"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      {permission.some(
        item =>
          (item.page === "/tickets-list" ||
            item.page === "/ticket-dashboard") &&
          item.access === 1
      ) && (
        <>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/tickets/tickets-list">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        All Tickets{" "}
                        <span style={{ visibility: "hidden" }}>Invisible</span>
                      </p>
                      <h5 className="mb-0 text-primary">
                        {count && count.total_tickets}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-primary font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/tickets/open-tickets-list">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">Pending/Open Tickets</p>
                      <h5 className="mb-0 text-warning">
                        {count && count.open_ticket_status}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-warning font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/tickets/wip-tickets-list">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        WIP Tickets{" "}
                        <span style={{ visibility: "hidden" }}>Invisible</span>
                      </p>
                      <h5 className="mb-0 text-danger">
                        {count && count.WIP_tickets}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-danger font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/tickets/closed-tickets-list">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Closed Tickets{" "}
                        <span style={{ visibility: "hidden" }}>Text</span>
                      </p>
                      <h5 className="mb-0 text-success">
                        {count && count.closed_ticket_status}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-success font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
        </>
      )}
      {permission.some(
        item =>
          (item.page === "/all-service-requests" ||
            item.page === "/service-request-dashboard") &&
          item.access === 1
      ) && (
        <>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/service-requests/all-service-requests">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        All Service Requests{" "}
                        <span style={{ visibility: "hidden" }}>Text</span>
                      </p>
                      <h5 className="mb-0 text-primary">
                        {count && count.all_count_service_request_status}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-primary font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/service-requests/pending-service-requests">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Pending Service Requests
                      </p>
                      <h5 className="mb-0 text-warning">
                        {count && count.Pending_Status_Count}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-warning font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/service-requests/inprogress-service-requests">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Accepted Service Request
                      </p>
                      <h5 className="mb-0 text-danger">
                        {count && count.Accept_Status_Count}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-danger font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/service-requests/closed-service-request">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Rejected Service Request
                      </p>
                      <h5 className="mb-0 text-success">
                        {count && count.Reject_Status_Count}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-success font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
        </>
      )}
      {permission.some(
        item => item.page === "vendor-proposal" && item.access === 1
      ) && (
        <>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/vendor-proposal">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        All Vendor Proposal{" "}
                        <span style={{ visibility: "hidden" }}>Text</span>
                      </p>
                      <h5 className="mb-0 text-primary">
                        {count && count.All_vendor_proposal_list}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-primary font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/vendor-proposal">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">Pending Vendor Proposal</p>
                      <h5 className="mb-0 text-warning">
                        {count && count.Pending_vendor_proposal_list}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-warning font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/vendor-proposal">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Approved Vendor Proposal
                      </p>
                      <h5 className="mb-0 text-danger">
                        {count && count.approved_vendor_proposal_list}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-danger font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/vendor-proposal">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Rejected Vendor Proposal
                      </p>
                      <h5 className="mb-0 text-success">
                        {count && count.rejectd_vendor_proposal_list}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-success font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
        </>
      )}

      {permission.some(
        item => item.page === "vendor-bill-history" && item.access === 1
      ) && (
        <>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/vendor-bill-history">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        All Vendor Bill List{" "}
                        <span style={{ visibility: "hidden" }}>Text</span>
                      </p>
                      <h5 className="mb-0 text-primary">
                        {count && count.all_vendor_invoice_list}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-primary font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/vendor-bill-history">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Pending Vendor Bill List
                      </p>
                      <h5 className="mb-0 text-warning">
                        {count && count.pending_vendor_invoice_list}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-warning font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/vendor-bill-history">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">
                        Approved Vendor Bill List
                      </p>
                      <h5 className="mb-0 text-danger">
                        {count && count.approved_vendor_invoice_list}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-danger font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <Link to="/vendor-bill-history">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <p className="text-muted mb-2">Rejected Bill List</p>
                      <h5 className="mb-0 text-success">
                        {count && count.rejectd_vendor_invoice_list}
                      </h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div
                        className="avatar-title bg-light rounded-circle text-success font-size-20"
                        style={{
                          width: "50px",
                          height: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <i className="mdi mdi-room-service-outline"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Link>
            </Card>
          </Col>
        </>
      )}
    </React.Fragment>
  )
}

export default typeOfRequest
