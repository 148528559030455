import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import InvoiceDetail from "VipPages/Billing/InvoicePreview/invoicePreview"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  addRecordPayment,
  deleteBillingRecord,
  getInvoicePreviewDetail,
  getPaymentMode,
  getReceiptNumber,
  getRecordPaymentDetail,
} from "store/actions"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"

const RecordPayment = props => {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const dispatch = useDispatch()
  const path = useLocation()
  const [paymentId, setPaymentId] = useState()

  useEffect(() => {
    dispatch(getPaymentMode())
  }, [dispatch])

  useEffect(() => {
    const pathName = path.pathname
    const paymentId = pathName.split("/record-payment/")[1]
    setPaymentId(paymentId)
    dispatch(getRecordPaymentDetail(paymentId))
  }, [dispatch, path])

  const billingState = state => state.billing
  const InvoicesDetailProperties = createSelector(billingState, billing => ({
    paymentMode: billing.paymentMode.data,
    invoiceDetail: billing.invoicePreviewDetail.billing,
    invoiceNo: billing.receiptNumber.receipt_number,
    outstandingAmount: billing?.invoicePreviewDetail?.outstanding_amount,
    recordPayment: billing.recordPayment.bill_payments,
  }))

  const {
    paymentMode,
    invoiceDetail,
    invoiceNo,
    outstandingAmount,
    recordPayment,
  } = useSelector(InvoicesDetailProperties)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      billing_id: (invoiceDetail && invoiceDetail.billing_id) || paymentId,
      outstandingAmount: outstandingAmount || "",
      customer_id: (invoiceDetail && invoiceDetail.customer_id) || "",
      location_id: (invoiceDetail && invoiceDetail.location_id) || "",
      company_id: (invoiceDetail && invoiceDetail.company_id) || "",
      // depositTo: "",
      receipt_date: new Date().toISOString().split("T")[0] || "",
      closingAmount: "",
      description: "",
      amount: "0",
      bank_account_number: "",
      payment_type: "",
      cheque_no: "",
      date: "",
      receipt_no: '',
      tds_amount: "0",
    },
    validationSchema: Yup.object().shape({
      outstandingAmount: Yup.number(),
      receipt_date: Yup.date()
        .max(new Date(), "Payment Received On Date Cannot Be A Future Date")
        .required("Payment Received Date is Required"),
      payment_type: Yup.number().required("Payment Mode is Required"),

      amount: Yup.number()
        .min(0)
        .test(
          "max",
          "Combination of Bill amount and TDS amount should not exceed outstanding amount",
          function (value) {
            const { outstandingAmount, tds_amount } = this.parent

            const totalAmount = Number(value) + (Number(tds_amount) || 0)
            return totalAmount <= (outstandingAmount || 0)
          }
        )
        .required(),
    }),

    onSubmit: async values => {
      dispatch(addRecordPayment(props.router.navigate, values))
      setTimeout(1000)
      dispatch(getRecordPaymentDetail(paymentId))
      dispatch(getInvoicePreviewDetail(paymentId))
      dispatch(getReceiptNumber({ location_id: values.location_id, receipt_date:values.receipt_date }))
      dispatch(getRecordPaymentDetail(paymentId))
      dispatch(getInvoicePreviewDetail(paymentId))
      // dispatch(getReceiptNumber({ company_id: values.company_id }))

      // const updatedValues = { ...values, description: "" } // Resetting the 'description' field
      // resetForm(updatedValues)
      // setFieldValue('description','')
    },
  })

  useEffect(() => {
    if (recordPayment) {
      setTableData(recordPayment)
    }
  }, [recordPayment])
  
  useEffect(() => {
    if (invoiceNo) {
      validation.setFieldValue('receipt_no',invoiceNo)
    }
  }, [invoiceNo])

  useEffect(() => {
    if (validation.values.location_id && validation.values.receipt_date) {
      dispatch(getReceiptNumber({ location_id: validation.values.location_id,receipt_date:validation.values.receipt_date }))
    }
  }, [validation.values.location_id,validation.values.receipt_date])

  const handleSubmit = () => {
    // Update the table data with the current form data
    setTableData([...tableData, validation.values])
  }
  const handleDelete = index => {
    // Create a copy of the tableData array without the specified row
    const updatedTableData = [...tableData]
    updatedTableData.splice(index, 1)
    setTableData(updatedTableData)
  }
  //////
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteBillingRecord(deleteId.id))
    }
    setDeleteModal(false)
    dispatch(getRecordPaymentDetail(paymentId))
    dispatch(getInvoicePreviewDetail(paymentId))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
            <div className="d-flex gap-2 ">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              Record Payment
            </div>
          </CardTitle>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <InvoiceDetail />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Payment Update</CardTitle>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <p></p>
                        <Label htmlFor="outstandingAmount">
                          Outstanding Amount
                        </Label>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-2">
                        <p></p>
                        <Label htmlFor="outstandingAmount">
                          {outstandingAmount > 0
                            ? outstandingAmount
                            : outstandingAmount === 0
                            ? 0
                            : "NA"}
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className=" mb-2">
                        <Label htmlFor="receipt_no" className="mt-2">
                          Receipt No.
                        </Label>
                      </div>
                    </Col>

                    <Col>
                      <div className=" mb-2">
                        <input
                          type="text"
                          disabled
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={`form-control w-50 ${
                            validation.touched.receipt_no &&
                            validation.errors.receipt_no
                              ? "is-invalid"
                              : ""
                          }`}
                          name="receipt_no"
                          value={validation.values.receipt_no || ""}
                        />
                        {validation.touched.receipt_no &&
                        validation.errors.receipt_no ? (
                          <FormFeedback type="invalid">
                            {validation.errors.receipt_no}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4}>
                      <div className=" mb-2">
                        <Label htmlFor="payment_type" className="mt-2">
                          Payment mode
                        </Label>
                      </div>
                    </Col>

                    <Col>
                      <div className="mb-2">
                        <select
                          className={`form-select w-50 ${
                            validation.touched.payment_type &&
                            validation.errors.payment_type
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={validation.handleChange}
                          name="payment_type"
                          value={validation.values.payment_type || ""}
                        >
                          {!validation.values.payment_type && (
                            <option>Select Payment Mode</option>
                          )}
                          {paymentMode &&
                            paymentMode.map((mode, index) => (
                              <option key={index} value={mode.id}>
                                {mode.payment_mode}
                              </option>
                            ))}
                        </select>
                        {validation.touched.payment_type &&
                        validation.errors.payment_type ? (
                          <div className="invalid-feedback">
                            {validation.errors.payment_type}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col lg={4}>
                      <div className=" mb-2">
                        <Label htmlFor="depositTo" className="mt-2">
                          Deposit To
                        </Label>
                      </div>
                    </Col>

                    <Col>
                      <div className=" mb-2">
                        <select
                          onChange={validation.handleChange}
                          className="form-select w-50 "
                          // onChange={validation.handleChange}
                          name="depositTo"
                        >
                          <option>Select Bank</option>
                          <option value="bank1"> bank 1 </option>
                          {(billingCompanyList || []).map(campany => (
                              <option key={campany.id} value={campany.id}>
                                {campany.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col lg={4}>
                      <div className=" mb-2">
                        <Label htmlFor="payment_method" className="mt-2">
                          Payment Received On
                        </Label>
                      </div>
                    </Col>

                    <Col>
                      <div className=" mb-2">
                        <Input
                          type="date"
                          className="form-control w-50 "
                          // onChange={validation.handleChange}
                          name="receipt_date"
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          value={validation.values.receipt_date}
                          invalid={
                            validation.touched.receipt_date &&
                            validation.errors.receipt_date
                              ? true
                              : false
                          }
                        />
                        {validation.touched.receipt_date &&
                        validation.errors.receipt_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.receipt_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <Label htmlFor="amount" className="mt-2">
                          Bill Amount Received
                        </Label>
                      </div>
                    </Col>

                    <Col>
                      <div className="mb-2">
                        <Input
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={`form-control w-50 ${
                            validation.touched.amount &&
                            validation.errors.amount
                              ? "is-invalid"
                              : ""
                          }`}
                          name="amount"
                          value={validation.values.amount || ""}
                          invalid={
                            validation.touched.amount &&
                            validation.errors.amount
                              ? true
                              : false
                          }
                        />
                        {validation.touched.amount &&
                        validation.errors.amount ? (
                          <div className="invalid-feedback">
                            {validation.errors.amount}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4}>
                      <div className=" mb-2">
                        <Label htmlFor="tds_amount" className="mt-2">
                          TDS Amount
                        </Label>
                      </div>
                    </Col>

                    <Col>
                      <div className=" mb-2">
                        <input
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={`form-control w-50 ${
                            validation.touched.tds_amount &&
                            validation.errors.tds_amount
                              ? "is-invalid"
                              : ""
                          }`}
                          name="tds_amount"
                          value={validation.values.tds_amount || ""}
                        />
                        {validation.touched.tds_amount &&
                        validation.errors.tds_amount ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tds_amount}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  {/* ====================================== */}
                  {(validation.values.payment_type === "1" ||
                    validation.values.payment_type === "2") && (
                    <Row>
                      <Col lg={4}>
                        <div className=" mb-2">
                          <Label htmlFor="ins_no" className="mt-2">
                            Ins No.
                          </Label>
                        </div>
                      </Col>

                      <Col>
                        <div className=" mb-2">
                          <input
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            className={`form-control w-50 ${
                              validation.touched.ins_no &&
                              validation.errors.ins_no
                                ? "is-invalid"
                                : ""
                            }`}
                            name="ins_no"
                            value={validation.values.ins_no || ""}
                          />
                          {validation.touched.ins_no &&
                          validation.errors.ins_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.ins_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {validation.values.payment_type === "3" && (
                    <>
                      <Row>
                        <Col lg={4}>
                          <div className=" mb-2">
                            <Label
                              htmlFor="bank_account_number"
                              className="mt-2"
                            >
                              Bank Account Number
                            </Label>
                          </div>
                        </Col>

                        <Col>
                          <div className=" mb-2">
                            <input
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              className={`form-control w-50 ${
                                validation.touched.bank_account_number &&
                                validation.errors.bank_account_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="bank_account_number"
                              value={
                                validation.values.bank_account_number || ""
                              }
                            />
                            {validation.touched.bank_account_number &&
                            validation.errors.bank_account_number ? (
                              <FormFeedback type="invalid">
                                {validation.errors.bank_account_number}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className=" mb-2">
                            <Label htmlFor="cheque_no" className="mt-2">
                              Cheque No.
                            </Label>
                          </div>
                        </Col>

                        <Col>
                          <div className=" mb-2">
                            <input
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              className={`form-control w-50 ${
                                validation.touched.cheque_no &&
                                validation.errors.cheque_no
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="cheque_no"
                              value={validation.values.cheque_no || ""}
                            />
                            {validation.touched.cheque_no &&
                            validation.errors.cheque_no ? (
                              <FormFeedback type="invalid">
                                {validation.errors.cheque_no}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className=" mb-2">
                            <Label htmlFor="date" className="mt-2">
                              Date
                            </Label>
                          </div>
                        </Col>

                        <Col>
                          <div className=" mb-2">
                            <input
                              type="date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              className={`form-control w-50 ${
                                validation.touched.date &&
                                validation.errors.date
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="date"
                              value={validation.values.date || ""}
                            />
                            {validation.touched.date &&
                            validation.errors.date ? (
                              <FormFeedback type="invalid">
                                {validation.errors.date}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  {/* ===================================== */}
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <p></p>
                        <Label htmlFor="closingAmount">Closing Amount</Label>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-2">
                        <p></p>
                        <Label htmlFor="closingAmount">
                          {validation.values.outstandingAmount -
                            validation.values.amount -
                            validation.values.tds_amount}
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <p></p>
                        <Label htmlFor="customer_code">Remarks</Label>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-2">
                        <p></p>
                        <textarea
                          type="text"
                          className="form-control w-50 "
                          // onChange={validation.handleChange}
                          onChange={validation.handleChange}
                          value={validation.values.description || ""}
                          name="description"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2"></div>
                    </Col>
                    {outstandingAmount !== 0 && (
                      <Col lg={2}>
                        <div className="mb-2 mt-2 ">
                          <button
                            className="btn btn-primary w-100 "
                            onClick={validation.handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mb-2">
                        {/* Add your table code here */}
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Rec no.</th>
                              <th>Date</th>
                              <th>Mode</th>
                              <th>Amount</th>
                              <th>Remarks</th>
                              <th>Action</th>
                              {/* Add more table headers as needed */}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((data, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.receipt_no}</td>
                                <td>{data.receipt_date}</td>
                                <td>{data.payment_mode}</td>
                                <td>{data.amount}</td>
                                <td>{data.description}</td>
                                <td>
                                  <button
                                    // className="btn btn-danger btn-sm"
                                    type="button "
                                    style={{
                                      border: "none",
                                      background: "none",
                                      padding: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      onClickDelete(data.payment_id)
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-delete"
                                      style={{ color: "red", fontSize: "18px" }}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(RecordPayment)

RecordPayment.propTypes = {
  history: Proptypes.object,
}
