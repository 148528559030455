import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//import images
// import adobephotoshop from "../../../assets/images/companies/adobe-photoshop.svg";

const Overview = () => {
  const ticketViewDetailState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request_ticket
  const ticketData = useSelector(ticketViewDetailState) || []
  const serviceRequestDetailState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request
  const serviceRequestData = useSelector(serviceRequestDetailState) || []

  const ticketLogState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request_ticket_log
  const trackingData = useSelector(ticketLogState) || []

  const getStatusColor = status => {
    switch (status) {
      case "Open":
        return "text-secondary" // Primary color
      case "Ticket Created":
        return "text-primary" // Success color
      case "In Progress":
        return "text-warning" // Warning color
      case "Closed":
        return "text-success" // Danger color
      case "Rejected":
        return "text-danger" // Danger color
      default:
        return ""
    }
  }
  return (
    <React.Fragment>
      <Col xl={3}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Ticket Summary</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Client Name</th>
                    <td scope="col">
                      {serviceRequestData && serviceRequestData.customer_name}
                    </td>
                  </tr>
                  {serviceRequestData && serviceRequestData.project_name && (
                    <tr>
                      <th scope="row">Project:</th>
                      <td>
                        {serviceRequestData && serviceRequestData.project_name}
                      </td>
                    </tr>
                  )}
                  {serviceRequestData && serviceRequestData.unit_code && (
                    <tr>
                      <th scope="row">Project Unit</th>
                      <td>
                        {serviceRequestData && serviceRequestData.unit_code}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Project Manager</th>
                    <td>{ticketData && ticketData.project_manager_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Ticket Incharge</th>
                    <td>{ticketData && ticketData.ticket_supervisor_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Mode Of Operation</th>
                    <td>
                      {ticketData && ticketData.mode_of_operation === 1
                        ? "Internal"
                        : "External"}
                    </td>
                  </tr>
                  {ticketData && ticketData.mode_of_operation === 1 && (
                    <tr>
                      <th scope="row">Assigned User</th>
                      <td>{ticketData && ticketData.assigned_user_name}</td>
                    </tr>
                  )}
                  {ticketData && ticketData.employee_code && (
                    <tr>
                      <th scope="row">User Code</th>
                      <td>{ticketData && ticketData?.employee_code}</td>
                    </tr>
                  )}
                  {ticketData && ticketData.mode_of_operation === 2 && (
                    <tr>
                      <th scope="row">Assigned Vendor</th>
                      <td>{ticketData && ticketData.vendor_name}</td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Priority</th>
                    <td>
                      <span
                        className={`badge ${
                          ticketData && ticketData.priority_id === 1
                            ? "badge-soft-danger"
                            : ticketData.priority_id === 2
                            ? "badge-soft-warning"
                            : ticketData.priority_id === 3
                            ? "badge-soft-info"
                            : ticketData.priority_id === 4
                            ? "badge-soft-success"
                            : "badge-soft-light"
                        }`}
                      >
                        {ticketData && ticketData.priority_id === 1
                          ? "Critical"
                          : ticketData.priority_id === 2
                          ? "High"
                          : ticketData.priority_id === 3
                          ? "Medium"
                          : ticketData.priority_id === 4
                          ? "Low"
                          : "NA"}
                      </span>{" "}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Ticket Status</th>
                    <td>
                      <span className="badge badge-soft-success">
                        {ticketData && ticketData.ticket_current_status_name}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Ticket Raised On</th>
                    <td>{ticketData && ticketData.formatted_created_at}</td>
                  </tr>
                  <tr>
                    <th scope="row">Last Updated Date</th>
                    <td>{ticketData && ticketData.formatted_updated_at}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Overview
