import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Form,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { getManagePermission, getModules } from "store/actions"
import { updateRoleComponentAccess } from "helpers/fakebackend_helper"
import Spinners from "components/Common/Spinner"
import OverideModal from "./overideRoleModal"

const UserPermission = () => {
  // const location = useLocation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [userRole, setUserRole] = useState(user?.user_role)
  const dispatch = useDispatch()

  // const modulesState = state => state.roles.userRoleModules.data
  const modulesState = state => state.roles.manageRoles.data
  const roleNameState = state => state.roles.manageRoles.role
  const loadingState = state => state.roles.loading
  const allModule = useSelector(modulesState) || []
  const roleName = useSelector(roleNameState) || ""
  const loadingData = useSelector(loadingState)
  const [isLoading, setLoading] = useState(true)

  const [selectedModules, setSelectedModules] = useState()
  const handleModuleRadioChange = (component_id, value) => {
    setSelectedModules({
      ...selectedModules,
      [component_id]: value,
    })
    makeApiCall(component_id, value)

  }
 

  const makeApiCall = async (component_id, value) => {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = await updateRoleComponentAccess({
      user_role_id: Number(roleId),
      component_id: component_id,
      access: value,
    })
    if (response.status === true) {
      loadingSwal.close()

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your User Role Permission has been updated.",
        timer: 800,
        showConfirmButton: false,
      })
    }
    dispatch(getManagePermission(roleId))
    dispatch(getModules(userRole))
  }

  const rolesProperties = createSelector(modulesState, roles => {
    return {
      roles: roles,
    }
  })

  const { roles } = useSelector(rolesProperties)
  const [specificUser, setSpecificUser] = useState(null)
  const [roleId, setRoleId] = useState()

  const userRoledata = state => state.roles.roleDetail.Data
  const role = useSelector(userRoledata)



  const roleData = async () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
      }

      const roleId = url.pathname.split("/manage-permission/")[1]
      setRoleId(roleId)
      // dispatch(getModules(roleId))
      dispatch(getManagePermission(roleId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    roleData()
  }, [dispatch])

  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  document.title = "User Role Management"

  const groupedModules = {}
  allModule.forEach(module => {
    if (!groupedModules[module.module_heading]) {
      groupedModules[module.module_heading] = []
    }
    groupedModules[module.module_heading].push(module)
  })

  const [modalLarge1, setModalLarge1] = useState(false)
  const toggleModalLarge1 = () => setModalLarge1(!modalLarge1)


  return (
    <div className="page-content">
      <OverideModal
        isOpen={modalLarge1}
        toggle={toggleModalLarge1}
        roleId={roleId}
        userRoleName={roleName}
      />
      <Container fluid={true}>
        <Row className="mb-3">
          <Col>
            <div className="d-flex align-items-center">
              <i
                className="mdi mdi-arrow-left font-size-20 text-grey"
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              <span className="ms-2 font-weight-bold font-size-18">
                Manage Role Permission {roleName ? `(${roleName})` : ""}
              </span>
            </div>
          </Col>
          <Col>
            <Breadcrumbs title="Settings / User Role Management / Manage User Role" />
          </Col>
        </Row>
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <Row>
            <Card>
              <CardBody>
                <Form>
                  <Row className="mb-3">
                    <Col lg={6}>
                      <span style={{ color: "black", fontWeight: "" }}>
                        Modules / Components
                      </span>
                    </Col>
                    <Col lg={3} style={{ paddingLeft: "100px" }}>
                      <span style={{ color: "black" }}>Permissions</span>
                    </Col>
                    <Col lg={3} className="text-end">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={(e) => {
                          e.preventDefault()
                          toggleModalLarge1()
                        }}
                      >
                        Overide Role
                      </button>
                    </Col>
                  </Row>
                  {Object.keys(groupedModules).map((heading, headingIndex) => (
                    <div key={headingIndex}>
                      <Row
                        className=""
                        style={{
                          backgroundColor: "#3783E8",
                          color: "white",
                          padding: "12px",
                        }}
                      >
                        <Col>
                          <span style={{ fontWeight: "1000" }}>{heading}</span>
                        </Col>
                      </Row>
                      {groupedModules[heading].map((module, moduleIndex) => (
                        <Row
                          key={module.component_id}
                          style={{
                            backgroundColor:
                              moduleIndex % 2 === 0 ? "#EEE" : "white",
                            padding: "12px",
                          }}
                        >
                          <Col>
                            <div className="d-flex align-items-center">
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                                className="mb-0"
                              >
                                {module.display_text}
                              </span>
                            </div>
                          </Col>
                          <Col>
                            <div className="d-flex gap-3">
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  id={`radioOption1-${module.component_id}`}
                                  name={`radioGroup-${module.component_id}`}
                                  checked={module.access === 1}
                                  onClick={() => {
                                    handleModuleRadioChange(
                                      module.component_id,
                                      1
                                    )
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor={`radioOption1-${module.component_id}`}
                                >
                                  <span
                                    style={{
                                      fontWeight: "400",
                                      color: "black",
                                    }}
                                  >
                                    Allow Access
                                  </span>
                                </Label>
                              </div>
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  id={`radioOption2-${module.component_id}`}
                                  name={`radioGroup-${module.component_id}`}
                                  checked={module.access === 0}
                                  onClick={() => {
                                    handleModuleRadioChange(
                                      module.component_id,
                                      0
                                    )
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor={`radioOption2-${module.component_id}`}
                                >
                                  <span
                                    style={{
                                      fontWeight: "400",
                                      color: "black",
                                    }}
                                  >
                                    {" "}
                                    Deny Access
                                  </span>
                                </Label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  ))}
                </Form>
              </CardBody>
            </Card>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default UserPermission
