import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"
import { forgotPassword } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  let loadingSwal;
  try {
    // Display loading Swal
    loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    // Make the API call
    const response = yield call(forgotPassword, {
      email: user.email,
    }, "/forgot-password");

    // Close loading Swal and handle successful response
    if (response) {
      loadingSwal.close();
      yield put(
        userForgetPasswordSuccess(
          "Reset link has been sent to your registered email."
        )
      );
    }
  } catch (error) {
    // Ensure loadingSwal closes in case of error
    if (loadingSwal) loadingSwal.close();

    // Extract error message safely
    const errorMessage = error?.response?.data?.message || "An error occurred.";

    // Dispatch error action
    yield put(userForgetPasswordError(errorMessage));
  }
}



export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
