import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_UNIT_MASTER_LIST,
  GET_UNIT_MASTER_DETAIL,
  ADD_NEW_UNIT_MASTER,
  UPDATE_UNIT_MASTER,
  DELETE_UNIT_MASTER,
} from "./actionTypes"
import {
  getUnitMasterListSuccess,
  getUnitMasterListFail,
  getUnitMasterDetailSuccess,
  getUnitMasterDetailFail,
  addNewUnitMasterSuccess,
  addNewUnitMasterFail,
  updateUnitMasterSuccess,
  updateUnitMasterFail,
  deleteUnitMasterSuccess,
  deleteUnitMasterFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUnitMasterList,
  getUnitMasterDetail,
  addNewUnitMaster,
  updateUnitMaster,
  deleteUnitMaster,
} from "helpers/fakebackend_helper"

function* fetchUnitMasterList() {
  try {
    const response = yield call(getUnitMasterList)
    yield put(getUnitMasterListSuccess(response))
  } catch (error) {
    yield put(getUnitMasterListFail(error))
  }
}

function* fetchUnitMasterDetail({ unitId }) {
  try {
    const response = yield call(getUnitMasterDetail, unitId)
    yield put(getUnitMasterDetailSuccess(response))
  } catch (error) {
    yield put(getUnitMasterDetailFail(error))
  }
}

function* onAddNewUnitMaster({ payload: { unit, history } }) {
  try {
    const response = yield call(addNewUnitMaster, unit)
    if (response.status === true) {
      yield put(addNewUnitMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Unit Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/unit-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewUnitMasterFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onUpdateUnitMaster({ payload: { id, unit, history } }) {
  try {
    const response = yield call(updateUnitMaster, id, unit)
    if (response && response.status === true) {
      yield put(updateUnitMasterSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Unit Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/unit-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateUnitMasterFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteUnitMaster({ payload: unit }) {
  try {
    const response = yield call(deleteUnitMaster, unit)
    const response2 = yield put(deleteUnitMasterSuccess(response))
    // yield put(deleteSACHSNCodeSuccess(response))
    if (response2.payload.status) {
      const unitMasterListResponse = yield call(getUnitMasterList)
      yield put(getUnitMasterListSuccess(unitMasterListResponse))
    } else {
      console.error("Error in delete Unit Master:", response.error)
    }
  } catch (error) {
    yield put(deleteUnitMasterFail(error))
  }
}

function* unitMasterSaga() {
  yield takeEvery(GET_UNIT_MASTER_LIST, fetchUnitMasterList)
  yield takeEvery(GET_UNIT_MASTER_DETAIL, fetchUnitMasterDetail)
  yield takeEvery(ADD_NEW_UNIT_MASTER, onAddNewUnitMaster)
  yield takeEvery(UPDATE_UNIT_MASTER, onUpdateUnitMaster)
  yield takeEvery(DELETE_UNIT_MASTER, onDeleteUnitMaster)
}

export default unitMasterSaga
