import PropTypes from "prop-types"
import React, { useState } from "react"

import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import VLogo from "../../assets/images/VIP/V1MallShort.jpg"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {
   
  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const navigate = useNavigate()

  function setting() {
    if (
      permission.find(
        item => item.page === "/regional-settings" && item.access === 1
      )
    ) {
      navigate("/settings/regional-settings")
    } else if (
      permission.find(
        item => item.page === "/user-role-management" && item.access === 1
      )
    ) {
      navigate("/settings/user-role-management")
    } else if (
      permission.find(item => item.page === "/companies" && item.access === 1)
    ) {
      navigate("/settings/companies")
    } else if (
      permission.find(
        item => item.page === "/company-setup-page" && item.access === 1
      )
    ) {
      navigate("/settings/company-setup-page")
    } else if (
      permission.find(
        item => item.page === "/vendor-master" && item.access === 1
      )
    ) {
      navigate("/settings/vendor-master")
    } else if (
      permission.find(
        item => item.page === "/facility-master" && item.access === 1
      )
    ) {
      navigate("/settings/facility-master")
    } else if (
      permission.find(
        item => item.page === "/document-master" && item.access === 1
      )
    ) {
      navigate("/settings/document-master")
    } else if (
      permission.find(
        item => item.page === "/expense-master" && item.access === 1
      )
    ) {
      navigate("/settings/expense-master")
    } else if (
      permission.find(
        item => item.page === "/maintenence-service" && item.access === 1
      )
    ) {
      navigate("/settings/maintenence-service")
    } else if (
      permission.find(
        item => item.page === "/customer-ticket-master" && item.access === 1
      )
    ) {
      navigate("/settings/customer-ticket-master")
    } else if (
      permission.find(
        item => item.page === "/invoice-master" && item.access === 1
      )
    ) {
      navigate("/settings/invoice-master")
    } else if (
      permission.find(
        item => item.page === "/project-unit-service" && item.access === 1
      )
    ) {
      navigate("/settings/project-unit-service")
    } else if (
      permission.find(
        item => item.page === "/project-service" && item.access === 1
      )
    ) {
      navigate("/settings/project-service")
    } else if (
      permission.find(
        item => item.page === "/landlord-service" && item.access === 1
      )
    ) {
      navigate("/settings/landlord-service")
    } else if (
      permission.find(
        item => item.page === "/material-master" && item.access === 1
      )
    ) {
      navigate("/settings/material-master")
    } else if (
      permission.find(item => item.page === "/unit-master" && item.access === 1)
    ) {
      navigate("/settings/material-master")
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={VLogo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={VLogo} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  setting()
                }}
                className="btn header-item noti-icon "
              >
                <i className="bx bx-cog  shimmer-icon" />
              </button>
            </div>

            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
