import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  FormFeedback,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { createSelector } from "reselect"
import Proptypes from "prop-types"
import withRouter from "components/Common/withRouter"
import {
  addNewDebitNote,
  addNewInvoice,
  getAllProjectsForCustomerOustanding,
  getApplicableRateList,
  getCreditNoteReasons,
  getDebitNoteDetail,
  getExpenseList,
  getGstRateList,
  getProjectCompanyDetail,
  getProjectList,
  getTicketDetail,
  getTicketList,
  getVendorFilterForVendorPaymentList,
  getVendorListInPayment,
  updateDebitNote,
} from "store/actions"
import { getVendorInvoicesInDebitNote } from "helpers/fakebackend_helper"
// import { getAcceptedProposal } from "helpers/backend"

const EditDebitNote = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [vendorInvoice, setVendorInvoice] = useState([])
  const BillingState = state => state.billAndPayments
  const path = useLocation()
  const projectstate = state => state.oustandingReports?.allProjects?.project
  const vendorstate = state => state.vendors.vendorListInPayment?.data
  const projectList = useSelector(projectstate) || []
  const vendorList = useSelector(vendorstate) || []
  const billingstate = state => state.billing.applicableRateList?.data
  const reasonsListState = state => state.billing?.creditNoteReasons?.companies
  const reasonsList = useSelector(reasonsListState) || []
  const gstRateList = useSelector(billingstate) || []
  
  const debitNoteState = state => state.debitNote?.debitNoteDetail?.data
  const debitNoteDetail = useSelector(debitNoteState) || []

  useEffect(() => {
    if (user) {
      dispatch(getAllProjectsForCustomerOustanding(user.user_id))
    }
  }, [user])
  
  useEffect(() => {
    dispatch(getCreditNoteReasons())
  }, [dispatch])
  
  const [debitNoteId, setDebitNote] = useState()

  useEffect(() => {
    const pathName = path.pathname
    const debitNoteId = pathName.split("/edit-debit-note/")[1]
    setDebitNote(debitNoteId)
    dispatch(getDebitNoteDetail(debitNoteId))
  }, [dispatch, path])


  //FORMIK VALIDATION
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      vendor_id: debitNoteDetail && debitNoteDetail.vendor_id || "",
      vendor_invoice_id: debitNoteDetail && debitNoteDetail.vendor_invoice_id  || "",
      gst_percentage: debitNoteDetail && debitNoteDetail.gst_percentage  || "",
      project_id: debitNoteDetail && debitNoteDetail.project_id  || "",
      remarks: debitNoteDetail && debitNoteDetail.remarks  || "",
      taxable_amount: debitNoteDetail && debitNoteDetail.taxable_amount  || "",
      reason_id: debitNoteDetail && debitNoteDetail.reason_id  || "",
      debit_note_date:debitNoteDetail && debitNoteDetail.debit_note_date  || "",
      debit_note_no: debitNoteDetail && debitNoteDetail.debit_note_no  || "",
    },
    validationSchema: Yup.object().shape({
      gst_percentage: Yup.string().required("Please select Gst Applicable"),
      debit_note_no: Yup.string().required("Please Enter Debit Invoice Number"),
      taxable_amount: Yup.number()
        .positive()
        .typeError("Taxable Amount Must be Positive Integer")
        .required("Taxable Amount is Required"),
      project_id: Yup.number().required(" Please Select Project"),
      debit_note_date: Yup.string().required("Debit Invoice date is required"),

      remarks: Yup.string().required("Remarks is required"),
    }),

    onSubmit: async values => {
      dispatch(updateDebitNote(debitNoteId,values, props.router.navigate))
    },
  })

  useEffect(() => {
    if (validation.values.project_id) {
      dispatch(
        getVendorFilterForVendorPaymentList(validation.values.project_id)
      )
    }
  }, [validation.values.project_id])

  useEffect(() => {
    if (validation.values.project_id) {
      dispatch(getVendorListInPayment(validation.values.project_id))
    }
  }, [validation.values.project_id])

  useEffect(() => {
    dispatch(getApplicableRateList())
  }, [dispatch])

  useEffect(() => {
    const getUnpaidVendorInvoices = async () => {
      try {
        const value = {
          project_id: validation.values.project_id,
          vendor_id: validation.values.vendor_id,
        }
        const response = await getVendorInvoicesInDebitNote(value)
        setVendorInvoice(response.data)
      } catch (error) {
        console.error("Failed to fetch accepted proposals", error)
      }
    }

    if (validation.values.project_id && validation.values.vendor_id) {
      getUnpaidVendorInvoices()
    }
  }, [validation.values.project_id, validation.values.vendor_id])

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Form>
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                <i
                  className="mdi mdi-arrow-left font-size-20"
                  style={{ color: "grey" }}
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Edit Debit Note
              </div>
            </CardTitle>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={3}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.project_id &&
                              validation.errors.project_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="project_id"
                            value={validation.values.project_id || ""}
                          >
                            <option value="">Select Project</option>
                            {(projectList || []).map(project => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.project_id &&
                            validation.errors.project_id && (
                              <div className="invalid-feedback">
                                {validation.errors.project_id}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={3}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.vendor_id &&
                              validation.errors.vendor_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="vendor_id"
                            value={validation.values.vendor_id}
                          >
                            <option value="">Select Vendor</option>
                            {(vendorList || []).map(vendor => (
                              <option key={vendor.id} value={vendor.id}>
                                {vendor.vendor_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.vendor_id &&
                            validation.errors.vendor_id && (
                              <div className="invalid-feedback">
                                {validation.errors.vendor_id}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.vendor_invoice_id &&
                              validation.errors.vendor_invoice_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="vendor_invoice_id"
                            value={validation.values.vendor_invoice_id}
                          >
                            <option value="">Select Vendor Invoice</option>
                            {(vendorInvoice || []).map(vendor => (
                              <option
                                key={vendor.id}
                                value={vendor.vendor_invoice_id}
                              >
                                {vendor.invoice_no}
                              </option>
                            ))}
                          </select>
                          {validation.touched.vendor_invoice_id &&
                            validation.errors.vendor_invoice_id && (
                              <div className="invalid-feedback">
                                {validation.errors.vendor_invoice_id}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col lg={3}>
                        {validation.values.project_id && (
                          <Row className="justify-content-left">
                            <Card>
                              <CardBody
                                style={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "15px",
                                }}
                              >
                                <CardTitle className="mb-3">
                                  Company Detail
                                </CardTitle>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="name">Company Name</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="address_line_1">
                                        Address
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_1}{" "}
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.address_line_2}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="pin_code">Pincode</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.pin_code}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="country_name">
                                        Country
                                      </Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.country_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="state_name">State</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.state_name}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-3">
                                      <Label htmlFor="city_name">City</Label>
                                      <p>
                                        {billingCompanyDetail &&
                                          billingCompanyDetail.city_name}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Row>
                        )}
                      </Col>
                      <Col lg={3}>
                        {validation.values.project_id && (
                          <Card>
                            <CardBody
                              style={{
                                backgroundColor: "#EEEEEE",
                                borderRadius: "15px",
                              }}
                            >
                              <CardTitle className="mb-3">
                                Billing Company's Location Detail
                              </CardTitle>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Address</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.location_title}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Country</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.country_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">State</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.state_name}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">City</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.city_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">GST No.</Label>
                                    <p>
                                      {billingCompanylocation &&
                                        billingCompanylocation.gst_no}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                      <Col lg={3}>
                        {validation.values.service_ticket_id && (
                          <Card>
                            <CardBody
                              style={{
                                backgroundColor: "#EEEEEE",
                                borderRadius: "15px",
                              }}
                            >
                              <CardTitle className="mb-3">
                                Service Request Ticket Detail
                              </CardTitle>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Ticket no</Label>
                                    <p>
                                      {ticketDetail && ticketDetail.ticket_no}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Created at</Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.formatted_created_at}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Work Type</Label>
                                    <p>
                                      {ticketDetail && ticketDetail.work_type}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">Priority</Label>
                                    <p>
                                      {ticketDetail && ticketDetail.priority_id}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Project Manager
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.project_manager_name}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Ticket Incharge
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.ticket_supervisor_name}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Total Material
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.total_material_cost}
                                    </p>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Total Manpower
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.total_manpower_cost}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label htmlFor="pan_no">
                                      Total Other Cost
                                    </Label>
                                    <p>
                                      {ticketDetail &&
                                        ticketDetail.total_other_cost}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">Invoice Details</CardTitle>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label>Taxable Amount</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>GST Rate(%)</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>GST Amount</Label>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label>Total Amount</Label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            className={`form-control ${
                              validation.touched.taxable_amount &&
                              validation.errors.taxable_amount
                                ? "is-invalid"
                                : ""
                            }`}
                            id="taxable_amount"
                            name="taxable_amount"
                            placeholder="Enter Amount"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.taxable_amount || ""}
                          />
                          {validation.touched.taxable_amount &&
                            validation.errors.taxable_amount && (
                              <FormFeedback type="invalid">
                                {validation.errors.taxable_amount}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <select
                            className={`form-select ${
                              validation.touched.gst_percentage &&
                              validation.errors.gst_percentage
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="gst_percentage"
                            value={validation.values.gst_percentage || ""}
                          >
                            <option value="">Select Gst Rate</option>
                            <option value="0">0</option>
                            {(gstRateList || []).map(gst => (
                              <option key={gst.id} value={gst.applicable_rate}>
                                {gst.applicable_rate}
                              </option>
                            ))}
                          </select>
                          {validation.touched.gst_percentage &&
                            validation.errors.gst_percentage && (
                              <div className="invalid-feedback">
                                {validation.errors.gst_percentage}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            id="amount"
                            name="amount"
                            disabled
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              (parseFloat(validation.values.taxable_amount) *
                                parseFloat(validation.values.gst_percentage)) /
                                100 || 0
                            }
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Input
                            type="text"
                            id="total"
                            name="total"
                            disabled
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              parseFloat(validation.values.taxable_amount) +
                                (parseFloat(validation.values.taxable_amount) *
                                  parseFloat(
                                    validation.values.gst_percentage
                                  )) /
                                  100 || 0
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="debit_note_no">
                            Debit Invoice Number*
                          </Label>
                          <Input
                            type="text"
                            name="debit_note_no"
                            className={`form-control ${
                              validation.touched.debit_note_no &&
                              validation.errors.debit_note_no
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            id="debit_note_no"
                            placeholder="Enter Invoice Number"
                            value={validation.values.debit_note_no || ""}
                          />
                          {validation.touched.debit_note_no &&
                            validation.errors.debit_note_no && (
                              <FormFeedback type="invalid">
                                {validation.errors.debit_note_no}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="debit_note_date">
                            Debit Invoice Date*
                          </Label>
                          <Input
                            type="date"
                            id="debit_note_date"
                            name="debit_note_date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.debit_note_date}
                            className={`form-control ${
                              validation.touched.debit_note_date &&
                              validation.errors.debit_note_date
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {validation.touched.debit_note_date &&
                            validation.errors.debit_note_date && (
                              <FormFeedback type="invalid">
                                {validation.errors.debit_note_date}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={4} xxl={3}>
                        <div className="mb-3">
                          <Label htmlFor="reason_id">Reason</Label>
                          <select
                            className={`form-select ${
                              validation.touched.reason_id &&
                              validation.errors.reason_id
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validation.handleChange}
                            name="reason_id"
                            value={validation.values.reason_id}
                          >
                            <option>Select Reason</option>
                            {(reasonsList || []).map(reason => (
                              <option key={reason.reason_id} value={reason.id}>
                                {reason.reason}
                              </option>
                            ))}
                          </select>
                          {validation.touched.reason_id &&
                            validation.errors.reason_id && (
                              <div className="invalid-feedback">
                                {validation.errors.reason_id}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={8}>
                        <div className="mb-3">
                          <Label htmlFor="remarks">Remark*</Label>
                          <Input
                            type="textarea"
                            name="remarks"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            className={`form-control ${
                              validation.touched.remarks &&
                              validation.errors.remarks
                                ? "is-invalid"
                                : ""
                            }`}
                            id="remarks"
                            placeholder="Enter Remark"
                            value={validation.values.remarks || ""}
                          />
                          {validation.touched.remarks &&
                            validation.errors.remarks && (
                              <FormFeedback type="invalid">
                                {validation.errors.remarks}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3 text-end">
                  <button
                    type="button"
                    className="btn btn-primary w-md me-4"
                    style={{ width: "10%" }}
                    onClick={validation.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditDebitNote)

EditDebitNote.propTypes = {
  history: Proptypes.object,
}
