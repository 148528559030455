import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "../Vendor/vendorContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { deleteVendor, deleteVendors, getVendorsList } from "store/actions"

const AllVendors = () => {
  const navigate = useNavigate()

  //meta title
  document.title = "Vendor List "

  const dispatch = useDispatch()
  const [customer, setCustomer] = useState()

  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const loadingState = state => state.vendors.loading
  const loadingData = useSelector(loadingState)
  const vendorsState = state => state.vendors.vendorsList.data
  const data = useSelector(vendorsState) || []
  const VendorProperties = createSelector(
    vendorsState,
    loadingState,
    (vendors, loading) => ({
      vendors: vendors,
      loading: loading,
    })
  )

  const { vendors, loading } = useSelector(VendorProperties)

  useEffect(() => {
    dispatch(getVendorsList())

    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    setCustomer(vendors)
    setIsEdit(false)
  }, [vendors])

  useEffect(() => {
    if (!isEmpty(vendors) && !!isEdit) {
      setCustomer(vendors)
      setIsEdit(false)
    }
  }, [vendors])

  const [isLoading, setLoading] = useState(loading)
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Vendor Code",
        accessor: "vendor_code",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.vendor_code}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Entity",
        accessor: "entity",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.entity}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Vendor",
        accessor: "vendor_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/vendor/view-vendor/${cellProps.row.original.vendor_id}`}
                >
                  {cellProps.row.original.vendor_name}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Service Offering",
        accessor: "service_offering",
        filterable: true,
        Cell: cellProps => {
          const serviceOfferingArray = cellProps.row.original.service_offering
          if (Array.isArray(serviceOfferingArray)) {
            const serviceOfferingString = serviceOfferingArray.join(", ")
            return (
              <>
                <h5 className="font-size-14 mb-1">
                  <Link className="text-dark" to="#">
                    {serviceOfferingString}
                  </Link>
                </h5>
                {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
              </>
            )
          } else {
            return null // or some default value if service_offering is not an array
          }
        },
      },
      {
        Header: "Active",
        accessor: "is_active",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.is_active === 1 ? "Yes" : "No"}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {permission
                .filter(
                  item =>
                    item.page === "vendors/edit-vendor/:id" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to={`/vendors/edit-vendor/${cellProps.row.original.vendor_id}`}
                    // to={`/vendors/view-vendor/${cellProps.row.original.vendor_id}`}
                    className="text-success"
                    onClick={() => {
                      const userData = cellProps.row.original
                      handleUserClick(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                ))}
              {permission
                .filter(
                  item => item.page === "delete-vendor" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      const userData = cellProps.row.original.vendor_id
                      onClickDelete(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                ))}
              {permission
                .filter(
                  item => item.page === "/view-vendor/:id" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index} // to={`/client-details/${cellProps.row.original.id}`}
                    to={`/vendor/view-vendor/${cellProps.row.original.vendor_id}`}
                    className="text-success"
                    onClick={() => {
                      const userData = cellProps.row.original
                      handleUserClick(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-chevron-right font-size-18"
                      id="viewtooltip"
                      style={{ fontSize: "18px", color: "#3366cc" }}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target="viewtooltip"
                    >
                      View
                    </UncontrolledTooltip>
                  </Link>
                ))}
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteVendors(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/vendors")
    dispatch(getVendorsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Vendors" breadcrumbItem="All Vendors" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default AllVendors
