import { call, put, takeEvery } from "redux-saga/effects"

//  Redux States
import {
  GET_SERVICE_REQUESTS,
  GET_SERVICE_REQUEST_DETAIL,
  ADD_NEW_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST,
  GET_PROJECT_UNIT_CATEGORY,
  GET_MAINTENANCE_ISSUES,
  GET_CLIENT_INVOICES,
  GET_SUPPORT_TICKET,
  GET_SERVICE_REQUEST_TYPE,
  GET_ALL_SERVICE_REQUEST_LIST,
  GET_PENDING_SERVICE_REQUEST_LIST,
  GET_PROJECT_MANAGER_IN_SERVICE_REQUEST,
  GET_USERS_IN_SERVICE_REQUEST,
  GET_VENDORS_IN_SERVICE_REQUEST,
  ASSIGN_SERVICE_REQUEST,
  GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST,
  GET_UNIT_MASTER_FOR_SERVICE_REQUEST,
  CREATE_SERVICE_REQUEST,
  GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE,
} from "./actionTypes"
import {
  getServiceRequestsSuccess,
  getServiceRequestsFail,
  getServiceRequestDetailSuccess,
  getServiceRequestDetailFail,
  addServiceRequestFail,
  addServiceRequestSuccess,
  updateServiceRequestSuccess,
  updateServiceRequestFail,
  deleteServiceRequestSuccess,
  deleteServiceRequestFail,
  getProjectUnitCategorySuccess,
  getProjectUnitCategoryFail,
  getSupportTicketsSuccess,
  getSupportTicketsFail,
  getClientInvoicesSuccess,
  getClientInvoicesFail,
  getMaintenanceIssuesSuccess,
  getMaintenanceIssuesFail,
  getServiceRequestTypeSuccess,
  getServiceRequestTypeFail,
  getAllServiceRequestListSuccess,
  getAllServiceRequestListFail,
  getPendingServiceRequestListSuccess,
  getPendingServiceRequestListFail,
  getProjectManagerInServiceRequestSuccess,
  getProjectManagerInServiceRequestFail,
  getUsersInServiceRequestSuccess,
  getUsersInServiceRequestFail,
  getVendorsInServiceRequestSuccess,
  getVendorsInServiceRequestFail,
  assignServiceRequestSuccess,
  assignServiceRequestFail,
  getMaterialMasterForServiceRequestSuccess,
  getMaterialMasterForServiceRequestFail,
  getUnitMasterForServiceRequestSuccess,
  getUnitMasterForServiceRequestFail,
  createServiceRequestSuccess,
  createServiceRequestFail,
  getAllServiceRequestListStatusWiseSuccess,
  getAllServiceRequestListStatusWiseFail,
} from "./actions"

import {
  getServiceRequests,
  getServiceRequestDetails,
  addNewServiceRequest,
  updateServiceRequest,
  deleteServiceRequest,
  getMaintenanceIssues,
  getProjectUnitCategory,
  getClientInvoices,
  getSupportTicket,
  getServiceRequestType,
  getAllServiceRequestList,
  getPendingServiceRequestList,
  getProjectManagerInServiceRequest,
  getUsersInServiceRequest,
  getVendorsInServiceRequest,
  assignServiceRequest,
  getMaterialMasterForServiceRequest,
  getUnitMasterForServiceRequest,
  createServiceRequest,
  getAllServiceRequestListStatusWise,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

function* fetchServiceRequests({ id }) {
  try {
    const response = yield call(getServiceRequests, id)
    yield put(getServiceRequestsSuccess(response))
  } catch (error) {
    yield put(getServiceRequestsFail(error))
  }
}
function* fetchServiceRequestType() {
  try {
    const response = yield call(getServiceRequestType)
    yield put(getServiceRequestTypeSuccess(response))
  } catch (error) {
    yield put(getServiceRequestTypeFail(error))
  }
}

function* fetchServiceRequestDetail({ serviceRequestId }) {
  try {
    const response = yield call(getServiceRequestDetails, serviceRequestId)
    yield put(getServiceRequestDetailSuccess(response))
  } catch (error) {
    yield put(getServiceRequestDetailFail(error))
  }
}

function* onUpdateServiceRequest({ payload: { id, serviceRequest } }) {
  try {
    const response = yield call(updateServiceRequest, id, serviceRequest)
    yield put(updateServiceRequestSuccess(response))
  } catch (error) {
    yield put(updateServiceRequestFail(error))
  }
}

function* onDeleteServiceRequest({ serviceRequest }) {
  try {
    const response = yield call(deleteServiceRequest, serviceRequest)
    yield put(deleteServiceRequestSuccess(response))
  } catch (error) {
    yield put(deleteServiceRequestFail(error))
  }
}

function* onAddNewServiceRequest({ payload: { clientId, serviceRequest } }) {
  try {
    const response = yield call(addNewServiceRequest, clientId, serviceRequest)
    yield put(addServiceRequestSuccess(response))
  } catch (error) {
    yield put(addServiceRequestFail(error))
  }
}
//DEPENDENCY LIST
function* fetchProjectUnitCategory() {
  try {
    const response = yield call(getProjectUnitCategory)
    yield put(getProjectUnitCategorySuccess(response))
  } catch (error) {
    yield put(getProjectUnitCategoryFail(error))
  }
}

function* fetchMaintenanceIssues() {
  try {
    const response = yield call(getMaintenanceIssues)
    yield put(getMaintenanceIssuesSuccess(response))
  } catch (error) {
    yield put(getMaintenanceIssuesFail(error))
  }
}

function* fetchClientInvoices() {
  try {
    const response = yield call(getClientInvoices)
    yield put(getClientInvoicesSuccess(response))
  } catch (error) {
    yield put(getClientInvoicesFail(error))
  }
}

function* fetchSupportTicket() {
  try {
    const response = yield call(getSupportTicket)
    yield put(getSupportTicketsSuccess(response))
  } catch (error) {
    yield put(getSupportTicketsFail(error))
  }
}

// All Service Request List

function* fetchAllServiceRequestList({ data }) {
  try {
    const response = yield call(getAllServiceRequestList, data)
    yield put(getAllServiceRequestListSuccess(response))
  } catch (error) {
    yield put(getAllServiceRequestListFail(error))
  }
}
// All Service Request List Status wise

function* fetchAllServiceRequestListStatusWise({ id }) {
  try {
    const response = yield call(getAllServiceRequestListStatusWise, id)
    yield put(getAllServiceRequestListStatusWiseSuccess(response))
  } catch (error) {
    yield put(getAllServiceRequestListStatusWiseFail(error))
  }
}

// Pending Service Request List

function* fetchPendingServiceRequestList() {
  try {
    const response = yield call(getPendingServiceRequestList)
    yield put(getPendingServiceRequestListSuccess(response))
  } catch (error) {
    yield put(getPendingServiceRequestListFail(error))
  }
}

// GET PROJECT MANAGER

function* fetchProjectManagerInServiceRequest() {
  try {
    const response = yield call(getProjectManagerInServiceRequest)
    yield put(getProjectManagerInServiceRequestSuccess(response))
  } catch (error) {
    yield put(getProjectManagerInServiceRequestFail(error))
  }
}
// GET USERS

function* fetchUsersInServiceRequest({id}) {
  try {
    const response = yield call(getUsersInServiceRequest,id)
    yield put(getUsersInServiceRequestSuccess(response))
  } catch (error) {
    yield put(getUsersInServiceRequestFail(error))
  }
}
// GET MANAGER

function* fetchVendorsInServiceRequest() {
  try {
    const response = yield call(getVendorsInServiceRequest)
    yield put(getVendorsInServiceRequestSuccess(response))
  } catch (error) {
    yield put(getVendorsInServiceRequestFail(error))
  }
}

function* onAssignServiceRequest({
  payload: { serviceId, serviceRequest, history },
}) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(assignServiceRequest, serviceId, serviceRequest)
    if (response.status === true) {
      loadingSwal.close()
      yield put(assignServiceRequestSuccess(response))

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Service Request has been Successfully Converted to Ticket.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/service-requests/all-service-requests")
        }
      })
    } else {
      loadingSwal.close()

      Swal.fire({
        title: "failure",
        icon: "error",
        text: "There Was An Issue While Converting Your Service Request To Ticket.",
      })
    }
  } catch (error) {
    yield put(assignServiceRequestFail(error))
    console.error("Error:", error)
    Swal.fire({
      title: "Error",
      icon: "error",
      text:
        error.response.data.message ||
        "An error occurred while Converting Your Service Request To Ticket.",
    })
  }
}

function* onCreateServiceRequest({
  payload: { serviceId, serviceRequest, history },
}) {
  try {
    const loadingSwal = Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const response = yield call(createServiceRequest, serviceId, serviceRequest)
    if (response.status === true) {
      const response2 = yield call(
        getServiceRequestDetails,
        response.service_request_ticket.service_request_id
      )
      loadingSwal.close()
      yield put(createServiceRequestSuccess(response2))

      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Ticket has been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/tickets/tickets-list")
          // history("/service-requests/all-service-requests")
        }
      })
    } else {
      loadingSwal.close()

      Swal.fire({
        title: "failure",
        icon: "error",
        text: "Your Ticket has been Rejected.",
      })
    }
  } catch (error) {
    yield put(createServiceRequestFail(error))
    console.error("Error:", error)
    Swal.fire({
      title: "Error",
      icon: "error",
      text: error.response.data.message || "An error occurred.",
    })
  }
}

function* fetchMaterialMasterForServiceRequest() {
  try {
    const response = yield call(getMaterialMasterForServiceRequest)
    yield put(getMaterialMasterForServiceRequestSuccess(response))
  } catch (error) {
    yield put(getMaterialMasterForServiceRequestFail(error))
  }
}

function* fetchUnitMasterForServiceRequest() {
  try {
    const response = yield call(getUnitMasterForServiceRequest)
    yield put(getUnitMasterForServiceRequestSuccess(response))
  } catch (error) {
    yield put(getUnitMasterForServiceRequestFail(error))
  }
}
function* serviceRequestsSaga() {
  yield takeEvery(GET_SERVICE_REQUESTS, fetchServiceRequests)
  yield takeEvery(GET_SERVICE_REQUEST_TYPE, fetchServiceRequestType)
  yield takeEvery(GET_SERVICE_REQUEST_DETAIL, fetchServiceRequestDetail)
  yield takeEvery(ADD_NEW_SERVICE_REQUEST, onAddNewServiceRequest)
  yield takeEvery(ASSIGN_SERVICE_REQUEST, onAssignServiceRequest)
  yield takeEvery(UPDATE_SERVICE_REQUEST, onUpdateServiceRequest)
  yield takeEvery(DELETE_SERVICE_REQUEST, onDeleteServiceRequest)
  yield takeEvery(GET_PROJECT_UNIT_CATEGORY, fetchProjectUnitCategory)
  yield takeEvery(GET_MAINTENANCE_ISSUES, fetchMaintenanceIssues)
  yield takeEvery(GET_CLIENT_INVOICES, fetchClientInvoices)
  yield takeEvery(GET_SUPPORT_TICKET, fetchSupportTicket)
  yield takeEvery(GET_ALL_SERVICE_REQUEST_LIST, fetchAllServiceRequestList)
  yield takeEvery(GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE, fetchAllServiceRequestListStatusWise)
  yield takeEvery(
    GET_PENDING_SERVICE_REQUEST_LIST,
    fetchPendingServiceRequestList
  )
  yield takeEvery(
    GET_PROJECT_MANAGER_IN_SERVICE_REQUEST,
    fetchProjectManagerInServiceRequest
  )
  yield takeEvery(GET_USERS_IN_SERVICE_REQUEST, fetchUsersInServiceRequest)
  yield takeEvery(GET_VENDORS_IN_SERVICE_REQUEST, fetchVendorsInServiceRequest)
  yield takeEvery(CREATE_SERVICE_REQUEST, onCreateServiceRequest)
  yield takeEvery(
    GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST,
    fetchMaterialMasterForServiceRequest
  )
  yield takeEvery(
    GET_UNIT_MASTER_FOR_SERVICE_REQUEST,
    fetchUnitMasterForServiceRequest
  )
}

export default serviceRequestsSaga
