import {
  GET_LANDLORDS_FAIL,
  GET_LANDLORDS_SUCCESS,
  GET_LANDLORD_DETAIL_FAIL,
  GET_LANDLORD_DETAIL_SUCCESS,
  ADD_LANDLORD_SUCCESS,
  ADD_LANDLORD_FAIL,
  UPDATE_LANDLORD_SUCCESS,
  UPDATE_LANDLORD_FAIL,
  DELETE_LANDLORD_SUCCESS,
  DELETE_LANDLORD_FAIL,
  ACTIVE_LANDLORD_SUCCESS,
  ACTIVE_LANDLORD_FAIL,
  GET_DOCUMENT_LIST_LANDLORD_SUCCESS,
  GET_DOCUMENT_LIST_LANDLORD_FAIL,
  ADD_DOCUMENT_LANDLORD_SUCCESS,
  ADD_DOCUMENT_LANDLORD_FAIL,
  DELETE_DOCUMENT_LANDLORD_SUCCESS,
  DELETE_DOCUMENT_LANDLORD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  landlords: [],
  landlordDetail: {},
  activelandlord: {},
  error: {},
  loading: true,
}

const landlords = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LANDLORDS_SUCCESS:
      return {
        ...state,
        landlords: action.payload,
        loading: true,
        // loading: true
      }

    case GET_LANDLORDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ACTIVE_LANDLORD_SUCCESS:
      return {
        ...state,
        activelandlord: action.payload,
        loading: true,
        // loading: true
      }

    case ACTIVE_LANDLORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_LANDLORD_SUCCESS:
      return {
        ...state,
        landlords: [...state.landlords, action.payload],
      }

    case ADD_LANDLORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LANDLORD_DETAIL_SUCCESS:
      return {
        ...state,
        landlordDetail: action.payload,
      }

    case UPDATE_LANDLORD_SUCCESS:
      return {
        ...state,
        landlords: state.landlords.map(landlord =>
          landlord.id.toString() === action.payload.id.toString()
            ? { landlord, ...action.payload }
            : landlord
        ),
      }

    case UPDATE_LANDLORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LANDLORD_SUCCESS:
      return {
        ...state,
        landlords: state.landlords.data.filter(
          landlord => landlord.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_LANDLORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LANDLORD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //DOCUMENTS

    case GET_DOCUMENT_LIST_LANDLORD_SUCCESS:
      return {
        ...state,
        docs: action.payload,
        loading: true,
      }

    case GET_DOCUMENT_LIST_LANDLORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DOCUMENT_LANDLORD_SUCCESS:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      }

    case ADD_DOCUMENT_LANDLORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_DOCUMENT_LANDLORD_SUCCESS:
      return {
        ...state,
        docs: state.docs.filter(
          doc => doc.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_DOCUMENT_LANDLORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default landlords
