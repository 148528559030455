import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import { Button, Card, CardBody, Col } from "reactstrap"
import { getRecentTicketList } from "store/actions"
import TableContainer from "../../../../components/Common/TableContainer"
import { useDispatch, useSelector } from "react-redux"

const LatestTranaction = () => {
  const dispatch = useDispatch()
  const serviceRequestState = state =>
    state.ticketsDashboard.recentTickets.latest_services_request_tickets_list

  const latestTransaction = useSelector(serviceRequestState) || []

  useEffect(() => {
    dispatch(getRecentTicketList())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.row.index + 1}</h5>
            </>
          )
        },
      },
      {
        Header: "Ticket No.",
        accessor: "ticket_no",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.ticket_no}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.customer_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Ticket Incharge",
        accessor: "Ticket_Supervisor_Name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.Ticket_Supervisor_Name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Manager",
        accessor: "project_manager_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.project_manager_name}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Ticket Created On",
        accessor: "request_made_on",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.ticket_created_on}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.status_name}
              </h5>
            </>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div className="mb-4 h4 card-title">Recent Tickets</div>
            <TableContainer
              columns={columns}
              data={latestTransaction}
              isGlobalFilter={false}
              isAddOptions={false}
              // isPagination={true}
              iscustomPageSizeOptions={false}
              customPageSize={5}
              pagination="pagination pagination-rounded justify-content-end mb-2"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTranaction)
