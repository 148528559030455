import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
  Row,
  Col,
  CardTitle,
  Label,
  Container,
  Card,
  CardBody,
  CardSubtitle,
} from "reactstrap"

// import { recentfile } from "../../../common/data";
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Breadcrumb from "components/Common/Breadcrumb"
import { getAssignedProjectUnit, getVendorPaymentData } from "store/actions"
import LargeModal from "./rejectModal"
import { approveVendorInvoice } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"
import formatNumberWithCommas from "components/Common/CommaSeparator"

const ApproveVendorPayment = () => {
  const [paymentId, setPaymentId] = useState()
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ApproveVendorBillState = state => state.vendors.vendorPaymentData.data
  const data = useSelector(ApproveVendorBillState)

  const [modalLarge5, setModalLarge5] = useState(false)
  const toggleModalLarge5 = () => setModalLarge5(!modalLarge5)

  const fetchData = async () => {
    const url = new URL(window.location.href)
    const paymentId = url.pathname.split("/approve-vendor-bill/")[1]
    setPaymentId(paymentId)

    try {
      dispatch(getVendorPaymentData(paymentId))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  const [value, setValue] = useState()
  const handleReject = async value => {
    setValue(value)
    toggleModalLarge5()
  }
  const handleApprove = async value1 => {
    let loadingSwal

    try {
      loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      const value = {
        approval_status: 1,
      }

      const response = await approveVendorInvoice(value, paymentId)

      if (response && response.status === true) {
        loadingSwal.close()

        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Approved Successfully",
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(getVendorPaymentData(paymentId))
          }
        })
      } else {
        loadingSwal.close()

        Swal.fire({
          title: "Failure",
          icon: "error",
          text: "Your Request has not been Submitted.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      if (loadingSwal) {
        loadingSwal.close()
      }

      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response?.data?.message ||
          "An error occurred while Submitting.",
      })
    }
  }

  document.title = "Bill"

  const printInvoice = () => {
    window.print()
  }
  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge5}
        toggle={toggleModalLarge5}
        data={paymentId}
      />
      <div className="page-content">
        <Row className="no-print">
          <Col>
            <div className="d-flex gap-2 mb-3">
              <i
                className="mdi mdi-arrow-left font-size-20"
                style={{ color: "grey", marginRight: "15px" }}
                onClick={() => {
                  navigate(-1)
                }}
              ></i>
              <span
                style={{ color: "black", fontWeight: "500", fontSize: "18px" }}
              >
                {" "}
                Vendor Bill
              </span>
            </div>
          </Col>
          <Col>
            <Breadcrumb title="Vendor Payment " />
          </Col>
        </Row>
        <Container fluid={true}>
          <Card>
            <CardBody>
              {data && (
                <Row>
                  <Col>
                    <CardTitle className="mb-3">
                      {" "}
                      <div className="d-flex gap-2 mb-3">
                        <i
                          className="bx bx-receipt font-size-20"
                          style={{ color: "#3366cc", marginTop: "5px" }}
                        ></i>
                        <span
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          {" "}
                          {data.vendor_name}
                          {data.vendor_code && `(${data.vendor_code})`}
                        </span>
                      </div>
                    </CardTitle>
                    <CardSubtitle className="mb-3">
                      {" "}
                      <Row>
                        <Col>
                          <span>Approval Status : </span>
                          <span
                            style={{
                              color:
                                data && data.approval_status === 1
                                  ? "#22bb33"
                                  : data.approval_status === 2
                                  ? "#f0ad4e"
                                  : data.approval_status === 0
                                  ? "#bb2124"
                                  : "#000000",
                              fontWeight: "500",
                              fontSize: "13px",
                            }}
                          >
                            {data && data.approval_status === 1
                              ? "Approved"
                              : data.approval_status === 2
                              ? "Rejected"
                              : "Pending"}
                          </span>
                          <br />
                          {data.approval_status === 1 && (
                            <>
                              <span>Payment Status : </span>
                              <span
                                style={{
                                  color:
                                    data && data.is_paid === 1
                                      ? "#22bb33"
                                      : data.is_paid === 0
                                      ? "#f0ad4e"
                                      : "#000000",
                                  fontWeight: "500",
                                  fontSize: "13px",
                                }}
                              >
                                {data && data.is_paid === 1
                                  ? "Paid"
                                  : data.is_paid === 0
                                  ? "Unpaid"
                                  : "None"}
                              </span>
                            </>
                          )}
                        </Col>
                        <Col>
                          <span>{data && data.approval_status === 1 ? 'Approved By : ' : data.approval_status === 2 ? 'Rejected By : ' : null }</span>
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "13px",
                            }}
                          >
                            {data && data.approved_by_name}
                          </span>
                          <br />
                          <span>{data && data.approval_status === 1 ? 'Approved On : ' : data.approval_status === 2 ? 'Rejected On : ' : null }</span>
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "13px",
                            }}
                          >
                            {data && data.approved_on_formatted}
                          </span>
                        </Col>
                      </Row>
                    </CardSubtitle>
                    <hr />
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Invoice No</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.invoice_no}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Invoice Date </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.invoice_date}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Is Service Request{" "}
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.is_service_request === 1 ? "Yes" : "No"}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      {data.ticket_no && (
                        <Col lg={4}>
                          <Label className="form-label">Ticket No </Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data && data.ticket_no}
                          </p>
                        </Col>
                      )}
                      {data.ticket_date && (
                        <Col lg={4}>
                          <Label className="form-label">Ticket Date </Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data && data.ticket_date}
                          </p>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Project </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.project_name}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Company </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.company_name}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Goods & Services Category </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.expense_title}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Taxable Amount</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {formatNumberWithCommas(data && data.taxable_amount)}
                        </p>
                      </Col>

                      <Col lg={4}>
                        <Label className="form-label">GST Rate(%) </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.gst_percentage}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">GST Amount </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {formatNumberWithCommas(data && data.tax_amount)}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Label className="form-label">Invoice Amount</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {formatNumberWithCommas(data && data.invoice_amount)}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Remark</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {data && data.remarks}
                        </p>
                      </Col>

                      {data.approval_status === 2 && (
                        <Col lg={4}>
                          <Label className="form-label">Rejected Reason </Label>
                          <p className="text-dark" style={{ fontWeight: 600 }}>
                            {data && data.approval_remark}
                          </p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col lg={12} className="text-end no-print">
                        {data && data.invoice_copy_path && (
                          <button
                            className="btn btn-warning l-md me-2"
                            onClick={() => {
                              const billPath = data.invoice_copy_path
                              if (billPath) {
                                window.open(billPath, "_blank")
                              }
                            }}
                          >
                            View Bill
                          </button>
                        )}

                        {data.approval_status === 0 &&
                          permission
                            .filter(
                              item =>
                                item.page === "approve-reject-vendor-bill" &&
                                item.access === 1
                            )
                            .map((item, index) => (
                              <button
                                key={index}
                                type="submit"
                                className="btn btn-success l-md me-2"
                                onClick={() => {
                                  handleApprove("1")
                                }}
                              >
                                Approve
                              </button>
                            ))}

                        {data.approval_status === 0 &&
                          permission
                            .filter(
                              item =>
                                item.page === "approve-reject-vendor-bill" &&
                                item.access === 1
                            )
                            .map((item, index) => (
                              <button
                                key={index}
                                type="submit"
                                className="btn btn-danger l-md"
                                onClick={() => {
                                  handleReject("2")
                                }}
                              >
                                Reject
                              </button>
                            ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </CardBody>
            <div className="d-print-none mt-5 mb-3">
              <div className="float-end">
                <Link
                  to="#"
                  onClick={printInvoice}
                  className="btn btn-success me-2"
                >
                  <i className="fa fa-print" />
                </Link>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ApproveVendorPayment
