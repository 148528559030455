import {
  GET_VENDORTYPE_MASTER_LIST,
  GET_VENDORTYPE_MASTER_LIST_SUCCESS,
  GET_VENDORTYPE_MASTER_LIST_FAIL,
  GET_VENDORTYPE_MASTER_DETAIL,
  GET_VENDORTYPE_MASTER_DETAIL_SUCCESS,
  GET_VENDORTYPE_MASTER_DETAIL_FAIL,
  ADD_NEW_VENDORTYPE_MASTER,
  ADD_NEW_VENDORTYPE_MASTER_SUCCESS,
  ADD_NEW_VENDORTYPE_MASTER_FAIL,
  UPDATE_VENDORTYPE_MASTER,
  UPDATE_VENDORTYPE_MASTER_SUCCESS,
  UPDATE_VENDORTYPE_MASTER_FAIL,
  DELETE_VENDORTYPE_MASTER,
  DELETE_VENDORTYPE_MASTER_SUCCESS,
  DELETE_VENDORTYPE_MASTER_FAIL,
} from "./actionTypes"

export const getVendorTypeMasterList = () => ({
  type: GET_VENDORTYPE_MASTER_LIST,
})

export const getVendorTypeMasterListSuccess = vendorTypes => ({
  type: GET_VENDORTYPE_MASTER_LIST_SUCCESS,
  payload: vendorTypes,
})
export const getVendorTypeMasterListFail = error => ({
  type: GET_VENDORTYPE_MASTER_LIST_FAIL,
  payload: error,
})

export const getVendorTypeMasterDetail = vendorTypeId => ({
  type: GET_VENDORTYPE_MASTER_DETAIL,
  vendorTypeId,
})

export const getVendorTypeMasterDetailSuccess = vendorTypeDetail => ({
  type: GET_VENDORTYPE_MASTER_DETAIL_SUCCESS,
  payload: vendorTypeDetail,
})

export const getVendorTypeMasterDetailFail = error => ({
  type: GET_VENDORTYPE_MASTER_DETAIL_FAIL,
  payload: error,
})

export const addNewVendorTypeMaster = (vendorType, history) => ({
  type: ADD_NEW_VENDORTYPE_MASTER,
  payload: { vendorType, history },
  status: true,
})

export const addNewVendorTypeMasterSuccess = vendorType => ({
  type: ADD_NEW_VENDORTYPE_MASTER_SUCCESS,
  payload: vendorType,
})

export const addNewVendorTypeMasterFail = error => ({
  type: ADD_NEW_VENDORTYPE_MASTER_FAIL,
  payload: error,
})

export const updateVendorTypeMaster = (id, vendorType, history) => ({
  type: UPDATE_VENDORTYPE_MASTER,
  payload: { id, vendorType, history },
})

export const updateVendorTypeMasterSuccess = vendorType => ({
  type: UPDATE_VENDORTYPE_MASTER_SUCCESS,
  payload: vendorType,
})

export const updateVendorTypeMasterFail = error => ({
  type: UPDATE_VENDORTYPE_MASTER_FAIL,
  payload: error,
})

export const deleteVendorTypeMaster = vendorType => ({
  type: DELETE_VENDORTYPE_MASTER,
  payload: vendorType,
})

export const deleteVendorTypeMasterSuccess = vendorType => ({
  type: DELETE_VENDORTYPE_MASTER_SUCCESS,
  payload: vendorType,
})

export const deleteVendorTypeMasterFail = error => ({
  type: DELETE_VENDORTYPE_MASTER_FAIL,
  payload: error,
})
