import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "../Clients/clientsContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { deleteClient, getClientsList } from "store/actions"

const AllClients = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  //meta title
  document.title = "Client List "

  const dispatch = useDispatch()
  const [customer, setCustomer] = useState()
  // validation

  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const loadingState = state => state.clients.loading
  const loadingData = useSelector(loadingState)
  const clientsState = state => state.clients.clientsList.clients
  const data = useSelector(clientsState) || []
  const ClientProperties = createSelector(
    clientsState,
    loadingState,
    (clients, loading) => ({
      clients: clients,
      loading: loading,
    })
  )

  const { clients, loading } = useSelector(ClientProperties)

  useEffect(() => {
    if (user.user_id) {
      const response = dispatch(getClientsList(user.user_id))
      setIsEdit(false)
    }
  }, [dispatch])

  useEffect(() => {
    setCustomer(clients)
    setIsEdit(false)
  }, [clients])

  useEffect(() => {
    if (!isEmpty(clients) && !!isEdit) {
      setCustomer(clients)
      setIsEdit(false)
    }
  }, [clients])

  const [isLoading, setLoading] = useState(true)
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.customer_type === 1
                    ? "Individual"
                    : "Brand"}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Client Code",
        accessor: "customer_code",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.customer_code}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Client Name",
        accessor: "customer_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.customer_name}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Mobile No.",
        accessor: "mobile_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.mobile_no}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Email Address",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.email_address}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Active",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.is_active === 1
                    ? "Yes"
                    : "No"}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {permission
                .filter(
                  item => item.page === "/edit-client/:id" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to={`/clients/edit-client/${cellProps.row.original.id}`}
                    className="text-success"
                    onClick={() => {
                      const userData = cellProps.row.original
                      handleUserClick(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                ))}
              {/* {permission
                .filter(
                  item => item.page === "delete-client" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      const userData = cellProps.row.original.id
                      onClickDelete(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                ))} */}
              {permission
                .filter(
                  item => item.page === "/view-client/:id" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to={`/clients/view-client/${cellProps.row.original.id}`}
                    className="text-success"
                    onClick={() => {
                      const userData = cellProps.row.original
                      handleUserClick(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-chevron-right font-size-18"
                      id="viewtooltip"
                      style={{ fontSize: "18px", color: "#3366cc" }}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target="viewtooltip"
                    >
                      View
                    </UncontrolledTooltip>
                  </Link>
                ))}
            </div>
          )
        },
      },
    ],
    []
  )

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteClient(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
   
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Clients" breadcrumbItem="All Clients" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default AllClients
