import React, { useEffect, useState } from "react"
import FlatPickr from "react-flatpickr"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { API_URL } from "helpers/url_helper"
import { downloadGstR1Excell } from "helpers/fakebackend_helper"
import { getRenewals } from "store/actions"
import { Link } from "react-router-dom"

const RenewalList = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  document.title = "Renewal List"
  const dispatch = useDispatch()

  const RenewalState = state => state.renewals.renewals.data

  const RenewalList = useSelector(RenewalState) || []


  useEffect(() => {
    if (user.user_id) {
      dispatch(getRenewals(user.user_id))
    }
  }, [user])

  const handleExport = async () => {
    // Construct the URL for download
    const downloadUrl = await downloadGstR1Excell(validation.values)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Renewal" breadcrumbItem="Manage Renewals" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    {/* <Col>
                      <CardTitle className="h4">Renewal </CardTitle>
                    </Col> */}
                    {/* <Col className="text-end">
                      <button
                        onClick={handleExport}
                        type="button"
                        className="btn btn-primary btn-sm me-4"
                      >
                        Export
                      </button>
                    </Col> */}
                  </Row>
                  {RenewalList && RenewalList.length > 0 ? (
                    <div className="table-responsive">
                      <Table className="table table-striped mb-0">
                        <thead className="text-center">
                          <tr>
                            <th>#</th>
                            <th>Project Name</th>
                            <th>Renewal Overdue</th>
                            <th>0 to 30 days</th>
                            <th>31 to 60 days</th>
                            <th>61 to 90 days</th>
                            <th>90+ days</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {(RenewalList || []).map((row, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{row?.project_name}</td>
                              <td>
                                <Link
                                  className="text-danger"
                                  to={{
                                    pathname: `/renewals/renewal-detail/overdue/${row?.project_id}`,
                                  }}
                                >
                                  {row?.renewal_overdue}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  style={{color:'orange'}}
                                  to={{
                                    pathname: `/renewals/renewal-detail/zerodays/${row?.project_id}`,
                                  }}
                                >
                                  {row?.zero_to_thirty_days}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  className="text-primay"
                                  to={{
                                    pathname: `/renewals/renewal-detail/thirtydays/${row?.project_id}`,
                                  }}
                                >
                                  {row?.thirtyone_to_sixty_days}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  style={{color:'green'}}
                                  to={{
                                    pathname: `/renewals/renewal-detail/sixtydays/${row?.project_id}`,
                                  }}
                                >
                                  {row?.sixtyone_to_ninety_days}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  // className="text-success"
                                  style={{color:'black'}}
                                  to={{
                                    pathname: `/renewals/renewal-detail/ninetydays/${row?.project_id}`,
                                  }}
                                >
                                  {row?.more_than_ninety_days}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <Alert color="warning" role="alert">
                      No Data...
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RenewalList
