import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./consumptionReportListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { getConsumptionFilter } from "store/actions"

const ConsumptionReportList = () => {
  const navigate = useNavigate()
  document.title = "Consumption Report"
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const consumptionListState = state => state.consumptionReport.Consumption.data
  const consumptionList = useSelector(consumptionListState) || []
  const consumptionTotalState = state =>
    state.consumptionReport?.Consumption?.total_sum
  const consumptionTotal = useSelector(consumptionTotalState) || []
  const loadingState = state => state.consumptionReport.loading
  const loading = useSelector(loadingState) || []

  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    const value = {
      client_id: clientId,
    }
    // dispatch(getConsumptionFilter(value))
  }, [dispatch])

  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [billingId, setBillingId] = useState()

  const handleBillPreview = id => {
    setBillingId(id)
    toggleModalLarge()
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Consumption Type",
        accessor: "consumption_type",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.consumption_type}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.customer_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Unit Code",
        accessor: "unit_code",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.unit_code}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">{cellProps.row.original.date}</Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Opening Unit",
        accessor: "opening_unit",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.opening_unit}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Closing Unit",
        accessor: "closing_unit",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.closing_unit}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Total Consumption",
        accessor: "unit_consumption",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.unit_consumption}
                </Link>
              </h5>
            </>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Consumption"
            breadcrumbItem="Consumption Report"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={consumptionList || []}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          {consumptionTotal > 0 && (
            <Row>
              <Col className="text-end">
                <Card>
                  <CardBody>
                    <Label>
                      <strong>Total Consumption </strong>: {parseFloat(consumptionTotal).toFixed(2)}
                    </Label>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConsumptionReportList
