import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  FormFeedback,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import { getUsers, getUserDetail } from "store/user/actions"
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import * as Yup from "yup"
import { useFormik } from "formik"
import { addNewUser, updateUser } from "helpers/fakebackend_helper"
import Spinners from "components/Common/Spinner"
import {
  getCities,
  getCountries,
  getRoles,
  getStates,
  getEmployeeCode,
  getProjectNamesForUser,
} from "store/actions"

const AddNewUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [passwordShow, setPasswordShow] = useState(false)

  const [isEditMode, setIsEditMode] = useState(false)
  const [loader, setLoader] = useState(false)

  const userInformation = JSON.parse(localStorage.getItem("authUser"))

  const userState = state => state.users.userDetail.user
  const loadingState = state => state.users.loading
  const data = useSelector(userState)
  const loadingData = useSelector(loadingState)

  const countriesDetail = state => state.common.countries.data
  const countries = useSelector(countriesDetail) || []

  const statesDetail = state => state.common.states.data
  const states = useSelector(statesDetail) || []

  const citiesDetail = state => state.common.cities.data
  const cities = useSelector(citiesDetail) || []

  const RolesData = state => state.roles.roles.roles
  const roles = useSelector(RolesData) || []

  const usersProperties = createSelector(
    userState,
    loadingState,
    (users, loading) => {
      return {
        users: users,
        loading: loading,
      }
    }
  )

  const { users, loading } = useSelector(usersProperties)
  const [userId, setFacilityId] = useState()
  const [load, setLoad] = useState(loading)

  const [selectedFile, setSelectedFile] = useState(null)
  const [imageURL, setImageURL] = useState(null)
  const [imageTouched, setImageTouched] = useState(false)

  const employeeCodeState = state => state.users.emp_codes.employee_code
  const employeeCode = useSelector(employeeCodeState)

  const projectNamesState = state => state.users.projectNames.project
  const projectNames = useSelector(projectNamesState)

  const userData = () => {
    try {
      const url = new URL(window.location.href)
      if (url.href.includes("edit")) {
        setIsEditMode(true)
        const userId = url.pathname.split("/users/edit-user/")[1]
        setFacilityId(userId)
        dispatch(getUserDetail(userId))
      }

      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    userData()
    dispatch(getRoles())
    dispatch(getProjectNamesForUser())
  }, [dispatch])

  useEffect(() => {
    if (isEditMode) {
      setSelectedFile(users?.image)
      setImageURL(users?.image)
    }
  }, [isEditMode, users?.image])

  useEffect(() => {
    dispatch(getEmployeeCode())
  }, [dispatch])

  const handleFileChange = event => {
    const file = event.target.files[0]
    setSelectedFile(file)
    if (typeof file === "object") {
      setImageURL(URL.createObjectURL(file))
    } else {
      setImageURL(file)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // initialValues: window.location.href.includes('edit')
    initialValues: isEditMode
      ? {
          employee_code: (users && users.employee_code) || "",
          email: (users && users.email) || "",
          first_name: (users && users.first_name) || "",
          middle_name: (users && users.middle_name) || "",
          last_name: (users && users.last_name) || "",
          mobile_no: (users && users.mobile_no) || "",
          address: (users && users.address) || "",
          country: (users && users.country) || "",
          state: (users && users.state) || "",
          city: (users && users.city) || "",
          pincode: (users && users.pincode) || "",
          login_username: (users && users.login_username) || "",
          password: (users && users.password) || "",
          pan_no: (users && users.pan_no) || "",
          image: "",
          aadhar_no: (users && users.aadhar_no) || "",
          user_role: (users && users.user_role) || "",
          project_id: (users && users.project_id) || "",
          is_active: (users && users.is_active) || "",
        }
      : {
          employee_code: (employeeCode && employeeCode) || "",
          email: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          mobile_no: "",
          address: "",
          country: "IN",
          state: "",
          city: "",
          pincode: "",
          login_username: "",
          password: "",
          pan_no: "",
          image: "",
          aadhar_no: "",
          user_role: "",
          project_id: "",
          is_active: "",
        },

    validationSchema: Yup.object({
      employee_code: Yup.string(),
      email: Yup.string().required("Please Select Email Address"),
      first_name: Yup.string().required("Please Enter First Name"),
      middle_name: Yup.string(),
      last_name: Yup.string().required("Please Enter Last Name"),
      mobile_no: Yup.string()
        .matches(
          /^[0-9]{10,12}$/,
          "Mobile Number should be between 10 and 12 digits and only contain numbers"
        )
        .required("Please Enter Mobile Number"),
      address: Yup.string().required("Please Enter Address"),
      country: Yup.string(),
      state: Yup.number().required("Please Select State"),
      city: Yup.number().required("Please Select City"),
      pincode: Yup.string()
        .matches(
          /^[0-9]{6}$/,
          "Pincode must be 6 digits and only contain numbers"
        )
        .required("Please enter Pincode"),
      login_username: Yup.string().required("Please Enter Login Username"),
      password: !isEditMode
        ? Yup.string().required("Please Enter Password")
        : Yup.string(),
      pan_no: Yup.string()
        .matches(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/, "Invalid PAN card number")
        .required("Please Enter PAN Card Number"),
      image: Yup.string(),
      aadhar_no: Yup.string()
        .matches(/^\d{12}$/, "Invalid Aadhar card number")
        .required("Please Enter Aadhar Number"),
      user_role: Yup.string().required("Please Select User Role"),
      project_id: Yup.string().required("Please Select Project"),
    }),

    onSubmit: async values => {
      try {
        const loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })

        let response
        if (isEditMode === true) {
          response = await updateUser(userId, values)
        } else {
          response = await addNewUser(values)
        }

        loadingSwal.close() // Close the loading indicator

        if (response && response.status === true) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: isEditMode
              ? "Your User has been updated."
              : "Your User has been created.",
          })
          navigate("/users")
        } else {
          Swal.fire({
            title: "Failure",
            icon: "error",
            text: "Your work has not been saved.",
          })
        }
      } catch (error) {
        console.error("Error:", error)

        let errorMessageText = "Your user has not been saved."
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessageText = error.response.data.message
        }

        Swal.fire({
          title: "Failure",
          icon: "error",
          text: errorMessageText,
        })
      } finally {
        // Deactivate the loader when form submission is complete or failed
        if (loadingSwal.isOpen()) {
          loadingSwal.close()
        }
      }
    },
  })

  useEffect(() => {
    if (selectedFile && typeof selectedFile === "object") {
      if (validation.touched.image && !imageTouched) {
        validation.setFieldValue("image", selectedFile)
        setImageTouched(true)
      }
    }
  }, [
    selectedFile,
    validation.touched.image,
    imageTouched,
    validation.setFieldValue,
  ])

  const [countryName, setCountryName] = useState(validation.values.country)
  const [stateId, setStateId] = useState(validation.values.state)

  useEffect(() => {
    const fetchData = async () => {
      // Fetch countries
      await dispatch(getCountries())

      // If country is selected (edit mode), fetch states and cities
      if (validation.values.country) {
        await dispatch(getStates(validation.values.country))
        if (validation.values.state !== "") {
          await dispatch(getCities(validation.values.state))
        }
      }

      // Set initial values
      setCountryName(validation.values.country)
      setStateId(validation.values.state)
    }

    fetchData()
  }, [dispatch, validation.values.country, validation.values.state])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [location.pathname])

  const options = (projectNames || []).map(item => ({
    value: item.id,
    label: item.project_name,
  }))

  const [selectedOptions, setSelectedOptions] = useState([])

  const handleOptionToggle = value => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter(option => option !== value))
    } else {
      setSelectedOptions([...selectedOptions, value])
    }
  }
  const [selectedOption, setSelectedOption] = useState("")

  const handleChange = event => {
    setSelectedOption(event.target.value)

    validation.setFieldValue("is_active", event.target.value)
  }
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          {load ? (
            <Spinners setLoading={setLoad} />
          ) : (
            <div>
              {/* <Breadcrumbs title="Add Users" breadcrumbItem="Add Users" /> */}
              <CardTitle className="h4" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 mb-3">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  {!isEditMode ? "Add User" : "Edit User"}
                </div>
              </CardTitle>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Form
                        autoComplete="off"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <label
                              className="rounded-2"
                              style={{
                                width: "150px",
                                display: "flex",
                                // padding: "30px",
                                alignItems: "center",
                                gap: "10px",
                                borderRadius: "10px",
                                border: "1px solid rgba(0, 0, 0, 0.10)",
                                flexDirection: "column",
                              }}
                            >
                              {!isEditMode ? (
                                <>
                                  {imageURL ? (
                                    <img
                                      className="object-fit-fill rounded-2"
                                      src={imageURL}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        maxHeight: "150px",
                                        minHeight: "150px",
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className="mdi mdi-image-outline"
                                      style={{
                                        fontSize: "60px",
                                        padding: "30px",
                                      }}
                                    ></i>
                                  )}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                </>
                              ) : (
                                <>
                                  {imageURL ? (
                                    <img
                                      className="object-fit-fill rounded-2"
                                      src={imageURL}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        maxHeight: "150px",
                                        minHeight: "150px",
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className="mdi mdi-image-outline"
                                      style={{
                                        fontSize: "60px",
                                        padding: "30px",
                                      }}
                                    ></i>
                                  )}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                </>
                              )}
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="employee_code">
                                Employee Code*
                              </Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="employee_code"
                                placeholder="Enter Employee Code"
                                name="employee_code"
                                // onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.employee_code}
                                className={`form-control ${
                                  validation.touched.employee_code &&
                                  validation.errors.employee_code
                                    ? "is-invalid"
                                    : ""
                                }`}
                                disabled
                              />
                              {validation.touched.employee_code &&
                              validation.errors.employee_code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.employee_code}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                First Name*
                              </Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Enter First Name of Employee"
                                name="first_name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.first_name || ""}
                                className={`form-control ${
                                  validation.touched.first_name &&
                                  validation.errors.first_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.first_name &&
                              validation.errors.first_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.first_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="middle_name">Middle Name</Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="middle_name"
                                placeholder="Enter Middle Name of Employee"
                                name="middle_name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.middle_name || ""}
                                className={`form-control ${
                                  validation.touched.middle_name &&
                                  validation.errors.middle_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.middle_name &&
                              validation.errors.middle_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.middle_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="last_name">Last Name*</Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="last_name"
                                placeholder="Enter Last Name of Employee"
                                name="last_name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.last_name || ""}
                                className={`form-control ${
                                  validation.touched.last_name &&
                                  validation.errors.last_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.last_name &&
                              validation.errors.last_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.last_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="email">Email Id*</Label>
                              <Input
                                type="email"
                                // className="form-control"
                                id="email"
                                placeholder="employee@mail.com"
                                name="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                className={`form-control ${
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="mobile_no">Mobile no.*</Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="mobile_no"
                                placeholder="+91 Employee Mobile no."
                                name="mobile_no"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile_no || ""}
                                className={`form-control ${
                                  validation.touched.mobile_no &&
                                  validation.errors.mobile_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.mobile_no &&
                              validation.errors.mobile_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mobile_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="address">Address*</Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="address"
                                placeholder="Building no./ Flat No, Building Name"
                                name="address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                className={`form-control ${
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.address &&
                              validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="country">Country*</Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                // defaultValue="IN"
                                value={validation.values.country}
                                name="country"
                                className={`form-select ${
                                  validation.touched.country &&
                                  validation.errors.country
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.country && (
                                  <option>Enter Your Country</option>
                                )}

                                {(countries || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.country_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          {/* <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-addressLine2-Input">
                            Address Line 2
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-addressLine2-Input"
                            placeholder="Street, Locality, Area"
                            name="first_name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ""}
                          />
                        </div>
                      </Col> */}
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-state-Input">
                                State*
                              </Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="state"
                                value={validation.values.state}
                                className={`form-select ${
                                  validation.touched.state &&
                                  validation.errors.state
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.state && (
                                  <option>Enter Your States</option>
                                )}

                                {(states || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.state_name}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.state &&
                              validation.errors.state ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.state}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-city-Input">City*</Label>
                              <select
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="city"
                                value={validation.values.city}
                                className={`form-select ${
                                  validation.touched.city &&
                                  validation.errors.city
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.city && (
                                  <option>Enter Your City</option>
                                )}

                                {(cities || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.city_name}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="pincode">Pincode*</Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="pincode"
                                placeholder="Enter Pincode"
                                name="pincode"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pincode || ""}
                                className={`form-control ${
                                  validation.touched.pincode &&
                                  validation.errors.pincode
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.pincode &&
                              validation.errors.pincode ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pincode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="formrow-userRole-Input">
                                User Role*
                              </Label>
                              <select
                                // defaultValue="0"
                                //  className="form-select"
                                name="user_role"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_role || ""}
                                className={`form-select ${
                                  validation.touched.user_role &&
                                  validation.errors.user_role
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                {!validation.values.user_role && (
                                  <option>Select User Role</option>
                                )}
                                {/* <option value="0">Select User Role</option> */}
                                {(roles || []).map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.role}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.user_role &&
                              validation.errors.user_role ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.user_role}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="login_username">
                                Login Username*
                              </Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="login_username"
                                placeholder="Enter Username of Employee"
                                name="login_username"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.login_username || ""}
                                className={`form-control ${
                                  validation.touched.login_username &&
                                  validation.errors.login_username
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.login_username &&
                              validation.errors.login_username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.login_username}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {!isEditMode && (
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="password">Password*</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <div className="col">
                                    <Input
                                      type={passwordShow ? "text" : "password"}
                                      // className="form-control"
                                      id="password"
                                      placeholder="Create Password"
                                      name="password"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.password || ""}
                                      className={`form-control ${
                                        validation.touched.password &&
                                        validation.errors.password
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                    />
                                    {validation.touched.password &&
                                    validation.errors.password ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.password}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div>
                                    <button
                                      onClick={() =>
                                        setPasswordShow(!passwordShow)
                                      }
                                      className="btn btn-light"
                                      type="button"
                                      id="password-addon"
                                    >
                                      <i className="mdi mdi-eye-outline"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="pan_no">PAN Number*</Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="pan_no"
                                placeholder="Enter PAN No. of Employee"
                                name="pan_no"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pan_no || ""}
                                className={`form-control ${
                                  validation.touched.pan_no &&
                                  validation.errors.pan_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.pan_no &&
                              validation.errors.pan_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pan_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="aadhar_no">Aadhar Number*</Label>
                              <Input
                                type="text"
                                // className="form-control"
                                id="aadhar_no"
                                placeholder="Enter Aadhar No. of Employee"
                                name="aadhar_no"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.aadhar_no || ""}
                                className={`form-select ${
                                  validation.touched.aadhar_no &&
                                  validation.errors.aadhar_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.aadhar_no &&
                              validation.errors.aadhar_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.aadhar_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label className="control-label">
                                Select Project Access*
                              </Label>
                              <Select
                                // classNamePrefix="select2-selection"
                                placeholder="Please Select Projects"
                                options={options}
                                isMulti={true}
                                id="project_id"
                                name="project_id"
                                onChange={selectedOptions => {
                                  const selectedValues = selectedOptions.map(
                                    option => option.value
                                  )
                                  validation.setFieldValue(
                                    "project_id",
                                    selectedValues.join(",")
                                  )
                                }}
                                value={options.filter(option =>
                                  ((validation.values.project_id || "") + "")
                                    .split(",")
                                    .includes(option.value.toString())
                                )}
                                // value={validation.values.project_id}
                                onBlur={validation.handleBlur}
                                className={`${
                                  validation.touched.project_id &&
                                  validation.errors.project_id
                                    ? " form-control is-invalid "
                                    : ""
                                }`}
                              />
                              {validation.touched.project_id &&
                                validation.errors.project_id && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.project_id}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col>
                            <Label htmlFor="is_active">Is Active*</Label>
                            <Col lg="6">
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="isActiveYes"
                                  name="is_active"
                                  value={1}
                                  checked={Number(validation.values.is_active) === 1}
                                  // onChange={validation.handleChange}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="isActiveYes"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>Yes</h6>
                                </Label>
                              </div>
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  id="isActiveNo"
                                  name="is_active"
                                  checked={Number(validation.values.is_active) === 0}
                                  value={0}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="isActiveNo"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <h6>No</h6>
                                </Label>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3 text-end">
                              <button
                                type="submit"
                                className="btn btn-primary l-md"
                              >
                                {isEditMode ? "Edit User" : "Create User"}
                              </button>
                            </div>
                          </Col>
                        </Row>

                        {/* <Row>

                      <div className="text-end">
                        <div className="mb-1"></div>
                        <div>
                          <button type="submit" className="btn btn-primary w-md">
                            Create
                          </button>
                        </div>
                      </div>

                    </Row> */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default AddNewUser
