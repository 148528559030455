import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import ForgotPassword from "./auth/forgetpwd/reducer"

//company
import companies from "./company/reducer"

import users from "./user/reducer"
import facilities from "./masters/facility/reducer"
import tickets from "./masters/customer-ticket/reducer"
import documents from "./masters/document/reducer"
import expenses from "./masters/expense/reducer"
import invoices from "./masters/invoice-type/reducer"
import landlords from "./masters/landlord/reducer"
import maintainences from "./masters/maintainence/reducer"
import compliances from "./masters/project-compliance/reducer"
import units from "./masters/project-unit/reducer"
import vendor from "./masters/vendor/reducer"
import sachsn from "./masters/sac&hsncode/reducer"
import unitMaster from "./masters/unitMaster/reducer"
import materialMaster from "./masters/materialMaster/reducer"
// import contacts from "./contacts/reducer";

import projects from "./project/reducer"

import roles from "./role/reducer"

import regions from "./region/reducer"
import common from "./common/reducer"

//CLIENTS
import clients from "./clients/reducer"

//CLIENTS SERVICE ENGAGEMENT

import serviceEngagement from "./clients/ServiceEngagement/reducer"

//VENDORS
import vendors from "./vendors/reducer"
import serviceRequests from "./serviceRequests/reducer"
import serviceRequestDashboard from "./serviceRequestDashboard/reducer"

//Billing Setup

import billing from "./billing/reducer"
import Reports from "./gstReport/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//MY PROFILE

import myProfile from "./myProfile/reducer"
import ServiceTicket from "./tickets/reducer"
import ticketsDashboard from "./ticketsDashboard/reducer"
import renewals from "./renewals/reducer"
import consumptionReport from "./consumptionReport/reducer"
import VendorPaymentList from "./vendors/vendorFilter/reducer"
import oustandingReports from "./customerOutstandingReport/reducer"
import vendorSummaryReport from "./VendorSummaryReport/reducer"
import CustomerIndex from "./customerIndex/reducer"
import proposals from "./proposal/reducer"
import proposalMaster from "./masters/proposalTypeMaster/reducer"
import reasonMaster from "./masters/reasonMaster/reducer"
import debitNote from "./debitNote/reducer"
import clientUser from "./clientUser/reducer"
import Notifications from "./notifications/reducer"
import vendorTypeMaster from "./masters/vendorTypeMaster/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgotPassword,
  projects,
  companies,
  users,
  serviceRequestDashboard,
  regions,
  //masters
  materialMaster,
  unitMaster,
  sachsn,
  facilities,
  tickets,
  documents,
  expenses,
  invoices,
  landlords,
  maintainences,
  compliances,
  units,
  vendor,
  roles,
  common,
  //CLIENTS
  clients,
  //CLIENTS SERVICE ENGAGEMENT
  serviceEngagement,
  //VENDORS
  vendors,
  //service request
  serviceRequests,
  //Billing Setup
  billing,
  //Reports
  Reports,
  //Dashboard
  Dashboard,
  //my Profile
  myProfile,
  //tickets
  ServiceTicket,
  ticketsDashboard,
  renewals,
  consumptionReport,
  VendorPaymentList,
  oustandingReports,
  vendorSummaryReport,
  CustomerIndex,
  proposals,
  proposalMaster,
  reasonMaster,
  vendorTypeMaster,
  debitNote,
  clientUser,
  Notifications,
})

export default rootReducer
