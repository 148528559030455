import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./venderContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { deleteVendor, getVendors } from "store/masters/vendor/actions"
import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"

const VenderService = props => {
  const navigate = useNavigate()

  // const handleAccountant = () => {
  //     navigate('/settings/user-role-management/accountant');
  // };

  // const handleRole = () => {
  //     navigate('/settings/user-role-management/new-user');
  // };

  //meta title
  document.title = "Vendor Master "

  const dispatch = useDispatch()
  const [contact, setContact] = useState()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || "",
      employeeCode: (contact && contact.employeeCode) || "",
      role: (contact && contact.role) || "",
      email: (contact && contact.email) || "",
      phoneNumber: (contact && contact.phoneNumber) || "",
      username: (contact && contact.username) || "",
      propertyType: (contact && contact.propertyType) || "",
      propertyName: (contact && contact.propertyName) || "",
      projectType: (contact && contact.projectType) || "",
      projectArea: (contact && contact.projectArea) || "",
      userDocument: (contact && contact.userDocument) || "",
      facilityMaster: (contact && contact.facilityMaster) || "",
      facilityFees: (contact && contact.facilityFees) || "",
      isActive: (contact && contact.isActive) || "",
      documentTitle: (contact && contact.documentTitle) || "",
      applicableFor: (contact && contact.applicableFor) || "",
      isMandatory: (contact && contact.isMandatory) || "",
      expenseTitle: (contact && contact.expenseTitle) || "",
      maintenanceIssue: (contact && contact.maintenanceIssue) || "",
      ticketTopic: (contact && contact.ticketTopic) || "",
      invoiceType: (contact && contact.invoiceType) || "",
      projectCategoryTitle: (contact && contact.projectCategoryTitle) || "",
      isPromotionalUnit: (contact && contact.isPromotionalUnit) || "",
      complianceTitle: (contact && contact.complianceTitle) || "",
      dependentCompliance: (contact && contact.dependentCompliance) || "",
      entity: (contact && contact.entity) || "",
      landlordName: (contact && contact.landlordName) || "",
      img: (contact && contact.img) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      tags: Yup.array().required("Please Enter Tag"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
        .required("Please Enter Your Email"),
      projects: Yup.string().required("Please Enter Your Project"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: contact.id,
          name: values.name,
          designation: values.designation,
          tags: values.tags,
          email: values.email,
          projects: values.projects,
        }
        // update user
        dispatch(onUpdateUser(updateUser))
        setIsEdit(false)
        validation.resetForm()
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          designation: values["designation"],
          email: values["email"],
          tags: values["tags"],
          projects: values["projects"],
        }
        // save new user
        dispatch(onAddNewUser(newUser))
        validation.resetForm()
      }
      toggle()
    },
  })

  const vendorState = state => state.vendor.vendors.vendors
  const loadingState = state => state.vendor.loading
  const data = useSelector(vendorState) || []
  const numAscending = [...data].sort((a, b) => a.id - b.id)

  // const data = useSelector(vendorState) || []
  const loadingData = useSelector(loadingState)

 

  const vendorsProperties = createSelector(
    vendorState,
    loadingState,
    (vendors, loading) => {
      return {
        vendors: vendors,
        loading: loading,
      }
    }
  )

  const { vendors, loading } = useSelector(vendorsProperties)

  useEffect(() => {
    const response = dispatch(getVendors())
    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    setContact(vendors)
    setIsEdit(false)
  }, [vendors])

  useEffect(() => {
    if (!isEmpty(vendors) && !!isEdit) {
      setContact(vendors)
      setIsEdit(false)
    }
  }, [vendors])

  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setLoading] = useState(loading)

  const sortedData = useMemo(() => [...data].reverse(), [data])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Service Code",
        accessor: "service_code",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {cellProps.row.original.service_code}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Service Name",
        accessor: "service_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">OEM Machinery</Link> */}
                <Link className="text-dark" to="#">
                  {cellProps.row.original.service_name}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Vendor Type",
        accessor: "vendor_type_title",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.vendor_type_title}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Is Active",
        accessor: "isActive",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.is_active === true ? "Yes" : "No"}
                </Link>
              </h5>
              {/* <p className="text-muted mb-0">{cellProps.row.original.designation}</p> */}
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {permission
                .filter(
                  item => item.page === "/edit-vendor/:id" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to={`/settings/edit-vendor/${cellProps.row.original.id}`}
                    className="text-success"
                    onClick={() => {
                      const userData = cellProps.row.original
                      handleUserClick(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                ))}
              {permission
                .filter(
                  item =>
                    item.page === "delete-vendor-master" && item.access === 1
                )
                .map((item, index) => (
                  <Link
                    key={index}
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      const userData = cellProps.row.original.id
                      onClickDelete(userData)
                    }}
                  >
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      style={{ color: "#3366cc" }}
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                ))}
            </div>
          )
        },
      },
    ],
    []
  )

  // useEffect(() => {
  //   if (users && !users.length) {
  //     dispatch(onGetUsers());
  //     setIsEdit(false);
  //   }
  // }, [dispatch, users]);

  // useEffect(() => {
  //   setContact(users);
  //   setIsEdit(false);
  // }, [users]);

  // useEffect(() => {
  //   if (!isEmpty(users) && !!isEdit) {
  //     setContact(users);
  //     setIsEdit(false);
  //   }
  // }, [users]);

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const user = arg

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    })
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = userData => {
    setContact({ id: userData })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (contact && contact.id) {
      dispatch(deleteVendor(contact.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/settings/vendor-master")
    dispatch(getVendors())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        delete="vendor"
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title=" Vendor Master"
            breadcrumbItem="Vendor Service Master"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      // isAddUserList={permission && permission[7].access}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit User" : "Add User"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Insert Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Designation</Label>
                        <Input
                          name="designation"
                          label="Designation"
                          placeholder="Insert Designation"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.designation || ""}
                          invalid={
                            validation.touched.designation &&
                            validation.errors.designation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.designation &&
                        validation.errors.designation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.designation}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="Insert Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Option</Label>
                        <Input
                          type="select"
                          name="tags"
                          className="form-select"
                          multiple={true}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.tags || []}
                          invalid={
                            validation.touched.tags && validation.errors.tags
                              ? true
                              : false
                          }
                        >
                          <option>Photoshop</option>
                          <option>illustrator</option>
                          <option>Html</option>
                          <option>Php</option>
                          <option>Java</option>
                          <option>Python</option>
                          <option>UI/UX Designer</option>
                          <option>Ruby</option>
                          <option>Css</option>
                        </Input>
                        {validation.touched.tags && validation.errors.tags ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tags}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Projects</Label>
                        <Input
                          name="projects"
                          label="Projects"
                          type="text"
                          placeholder="Insert Projects"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.projects || ""}
                          invalid={
                            validation.touched.projects &&
                            validation.errors.projects
                              ? true
                              : false
                          }
                        />
                        {validation.touched.projects &&
                        validation.errors.projects ? (
                          <FormFeedback type="invalid">
                            {validation.errors.projects}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withRouter(VenderService)
