import { newCustomerListAPIExcel } from "helpers/fakebackend_helper";
import { API_URL } from "helpers/url_helper";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Table } from "reactstrap";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { newCustomerList } from "store/actions";

const NewCustomerModal = ({ isOpen, toggle, filterResponse }) => {
  const dispatch = useDispatch();

  const newCustomerListState = state =>
    state.Dashboard?.newCustomerList?.customers;
  const newCustomer = useSelector(newCustomerListState);

  useEffect(() => {
    if(isOpen===true){
    dispatch(newCustomerList({ filter: filterResponse }))
    };
  }, [filterResponse, dispatch,isOpen]);

  
  const handleExport = async() => {
    // Construct the URL for download
    const value = {
      filter:filterResponse,
      type: 'NewCustomer'
    } 
    const downloadUrl = await newCustomerListAPIExcel(value)
   
  }
  
  
  
  
  
  
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          New Customers
        </h5>
        <button
          onClick={handleExport}
          type="button"
          className="btn btn-primary btn-sm me-4"
        >
          Export
        </button>
        <button
          onClick={toggle}
          type="button"
          className="close mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <SimpleBar style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div style={{ minWidth: "100%" }}>
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Client Code</th>
                  <th>Client Name</th>
                  <th>Email Address</th>
                  <th>Has Project Unit</th>
                  <th>Unit Code</th>
                </tr>
              </thead>
              <tbody>
                {(newCustomer || []).map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.customer_code}</td>
                    <td>{row.customer_name}</td>
                    <td>{row.email_address}</td>
                    <td>{row.has_project_unit}</td>
                    <td>{row.unit_code === null ? "-" : row.unit_code}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </div>
    </Modal>
  );
};

export default NewCustomerModal;
