import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  Input,
  Label,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
  Container,
  Button,
  CardBody,
} from "reactstrap"

// Redux imports and other utilities
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { getAllNotificationList } from "store/actions"
import Spinners from "components/Common/Spinner"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  deleteNotification,
  isReadNotification,
} from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

const Notifications = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedMails, setSelectedMails] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 10
  const [selectedMail, setSelectedMail] = useState(null) // New state to track selected mail

  const handleSelectMail = async mail => {
    navigate(`/notifications`)
    setSelectedMail(mail) // Set selected mail when clicked
    const value = {
      id: mail.id,
      target_user_id: user.user_id,
    }
    const response = await isReadNotification(value)
    const value1 = {
      portal_type: "1",
      target_user_id: user.user_id,
    }
    dispatch(getAllNotificationList(value1))
  }

  // Redux selectors for notifications
  const notificationSelector = createSelector(
    state => state.Notifications,
    notifications => ({
      loading: notifications?.loading,
      notifications: notifications.allNotifications.data,
    })
  )
  const { notifications, loading } = useSelector(notificationSelector)
  const [mailsList, setMailsList] = useState([])
  const [isLoading, setLoading] = useState(loading)

  // Fetch notifications when the user is available
  useEffect(() => {
    if (user) {
      const value = {
        portal_type: "1",
        target_user_id: user.user_id,
      }
      dispatch(getAllNotificationList(value))
    }
  }, [user, dispatch])

  // Set the mail list when notifications are updated
  useEffect(() => {
    setMailsList(notifications)
  }, [notifications])

  // Paginate data
  const indexOfLastMail = currentPage * perPage
  const indexOfFirstMail = indexOfLastMail - perPage
  const currentMails = mailsList?.slice(indexOfFirstMail, indexOfLastMail)

  // Handle selection of mails
  const handleSelect = mailId => {
    if (selectedMails.includes(mailId)) {
      setSelectedMails(selectedMails.filter(id => id !== mailId))
    } else {
      setSelectedMails([...selectedMails, mailId])
    }
  }

  const [selectAll, setSelectAll] = useState(false) // Track Select All state

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedMails([]) // Deselect all
    } else {
      setSelectedMails(mailsList.map(mail => mail.id)) // Select all
    }
    setSelectAll(!selectAll) // Toggle Select All state
  }

  // Function to handle deleting selected mails
  const handleDeleteSelected = async () => {
    if (selectedMails.length === 0) return

    // Show confirmation dialog before deletion
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete the selected notifications? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete them!",
      cancelButtonText: "Cancel",
      reverseButtons: true, // Makes the "Cancel" button appear on the left side
    })

    // If the user confirms, proceed with deletion
    if (result.isConfirmed) {
      try {
        const data = {
          ids: selectedMails.join(","), // Join the selected IDs into a comma-separated string
        }

        // Await the API response
        const response = await deleteNotification(data)

        if (response && response.status) {
          // Filter out the deleted mails
          const filteredMails = mailsList.filter(
            mail => !selectedMails.includes(mail.id)
          )
          setMailsList(filteredMails) // Update the mails list with the remaining mails
          setSelectedMails([]) // Clear the selection after successful deletion
          setSelectedMail(null)

          // Show success message
          Swal.fire({
            title: "Deleted!",
            text: "The selected notifications have been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          })
        } else {
          // Handle error case
          console.error("Error deleting notifications:", response.message)
          Swal.fire({
            title: "Error",
            text: "There was an issue deleting the notifications. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          })
        }
      } catch (error) {
        // Handle any errors that occurred during the API call
        console.error("Error during delete request:", error)
        Swal.fire({
          title: "Error",
          text: "There was an error during the deletion process.",
          icon: "error",
          confirmButtonText: "OK",
        })
      }
    }
  }

  const handleRefresh = () => {
    const value = {
      portal_type: "1",
      target_user_id: user.user_id,
    }
    dispatch(getAllNotificationList(value))
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const id = query.get("id") // Get the id from the query string

    if (id) {
      const mail = notifications?.find(noti => Number(noti.id) === Number(id)) // Find the notification
      if (mail) {
        handleSelectMail(mail) // Call your function with the found mail
      }
    }
  }, [window.location.search])

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Notifications" breadcrumbItem="All Notifications" />
        <Row>
          <Col xs="12" lg="7">
            {" "}
            {/* Full width on small screens, half width on large screens */}
            <div>
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <Card style={{ boxShadow: "none" }}>
                  <Row className="mt-1 p-1">
                    <Col xs="6" className="text-start">
                      <Button className="btn-sm" onClick={handleSelectAll}>
                        {selectAll ? "Deselect All" : "Select All"}
                      </Button>
                    </Col>
                    <Col xs="6" className="text-end">
                      {" "}
                      {/* Stays 50% width on all screen sizes */}
                      <Button
                        className="btn-sm btn-success"
                        onClick={handleRefresh}
                      >
                        <i className=" mdi mdi-reload me-1"></i>
                        Refresh
                      </Button>{" "}
                      <Button
                        className="btn-sm btn-danger"
                        onClick={handleDeleteSelected}
                        disabled={selectedMails.length === 0}
                      >
                        Delete Selected
                      </Button>
                    </Col>
                  </Row>

                  {mailsList?.length > 0 ? (
                    <>
                      <ul style={{ paddingLeft: 0 }}>
                        <Col style={{ padding: "10px" }}>
                          {currentMails.map((mail, index) => (
                            <Row
                              key={index}
                              className="mail-row "
                              style={{
                                height: "70px",
                                alignItems: "center",
                                backgroundColor:
                                  mail.is_read === 0 ? "#F2F6FC" : "#FFFFFF",
                                cursor: "pointer",
                                transition:
                                  "background-color 0.3s, opacity 0.3s", // Added opacity transition
                                opacity: mail.is_read === 0 ? 1 : 0.7, // Opacity based on read/unread state
                                border: "1px solid rgba(220, 226, 233, 0.52)", // Corrected border color with rgba'
                              }}
                              onClick={() => handleSelectMail(mail)}
                            >
                              {/* Checkbox Column */}
                              <Col xs={1} style={{ textAlign: "center" }}>
                                <Input
                                  type="checkbox"
                                  value={mail.id}
                                  id={mail.id}
                                  name="emailcheckbox"
                                  onChange={() => handleSelect(mail.id)}
                                  checked={selectedMails.includes(mail.id)}
                                  className="mt-1"
                                />
                                <Label htmlFor={mail.id} />
                              </Col>

                              {/* Title Column */}
                              <Col
                                xs={3}
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Link
                                  to="#"
                                  style={{
                                    color: "#000",
                                    fontWeight:
                                      mail.is_read === 0 ? "bold" : "normal", // Corrected fontWeight assignment
                                    textDecoration: "none",
                                    maxHeight: "70px", // Added maxHeight to prevent overlap
                                  }}
                                >
                                  {mail.title}
                                </Link>
                              </Col>

                              {/* Message Column */}
                              <Col
                                xs={3}
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxHeight: "70px", // Added maxHeight to prevent overlap

                                  fontWeight:
                                    mail.is_read === 0 ? "bold" : "normal", // Corrected fontWeight assignment
                                }}
                              >
                                {/* <div dangerouslySetInnerHTML={{ __html: mail.message }} /> */}
                                {mail.message}
                              </Col>

                              {/* Sent By Column */}
                              <Col
                                xs={2}
                                style={{
                                  textAlign: "left",
                                  flexShrink: 0,
                                  fontWeight:
                                    mail.is_read === 0 ? "bold" : "normal", // Corrected fontWeight assignment
                                }}
                              >
                                {mail.sent_by}
                              </Col>

                              {/* Sent On Column */}
                              <Col
                                xs={3}
                                style={{
                                  textAlign: "right",
                                  flexShrink: 0,
                                  fontWeight:
                                    mail.is_read === 0 ? "bold" : "normal",
                                  maxHeight: "50px", // Added maxHeight to prevent overlap
                                  overflow: "hidden", // Hide overflow content
                                  display: "-webkit-box", // For multiline truncation
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 2, // Limit to 2 lines
                                  whiteSpace: "normal", // Allow text wrapping
                                }}
                              >
                                {mail.sent_on}
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </ul>

                      <Row className="ms-1 me-1">
                        <Col xs="7">
                          Showing {indexOfFirstMail + 1} -{" "}
                          {Math.min(indexOfLastMail, mailsList.length)} of{" "}
                          {mailsList.length}
                        </Col>
                        <Col xs="5">
                          <div className="btn-group float-end mb-1">
                            <Button
                              type="button"
                              color="success"
                              size="sm"
                              onClick={() => setCurrentPage(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              <i className="fa fa-chevron-left" />
                            </Button>
                            <Button
                              type="button"
                              color="success"
                              size="sm"
                              onClick={() => setCurrentPage(currentPage + 1)}
                              disabled={indexOfLastMail >= mailsList.length}
                            >
                              <i className="fa fa-chevron-right" />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div className="text-center p-4">
                      <i className="bx bx-bell  me-2 display-5"></i>
                      <h4>No Records Found</h4>
                    </div>
                  )}
                </Card>
              )}
            </div>
          </Col>

          {selectedMail && (
            <Col xs="12" lg="5">
              {" "}
              {/* Full width on small screens, half width on large screens */}
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="avatar-md me-4">
                      <span className="avatar-title rounded bg-light text-dark font-size-16">
                        {selectedMail && selectedMail.image ? (
                          <img src={selectedMail?.image} alt="" height="75" />
                        ) : (
                          <i className="mdi mdi-account   " style={{fontSize:'60px'}}></i>
                        )}
                      </span>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="text-truncate font-size-15">
                        {selectedMail.title}
                      </h5>
                      <p className="text-muted mb-4">{selectedMail.message}</p>
                    </div>
                  </div>
                </CardBody>
                <div className="px-4 py-3 border-top">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item me-3" id="dueDate">
                      <i className="bx bx-calendar me-1" />{" "}
                      {selectedMail.sent_on}
                      <UncontrolledTooltip placement="top" target="dueDate">
                        Sent on
                      </UncontrolledTooltip>
                    </li>
                    <li className="list-inline-item me-3" id="comments">
                      <i className="fas fa-portrait me-1" />{" "}
                      {selectedMail.sent_by}
                      <UncontrolledTooltip placement="top" target="comments">
                        Sent by
                      </UncontrolledTooltip>
                    </li>
                  </ul>
                </div>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default withRouter(Notifications)
