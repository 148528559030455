import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./VendorBillHistoryListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { getBillHistoryForVendorPayment } from "store/actions"
import formatNumberWithCommas from "components/Common/CommaSeparator"

const VendorBillHistoryList = () => {
  const navigate = useNavigate()
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  document.title = "Bill History List "
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const billHistoryListState = state => state.VendorPaymentList.billHistory.data
  const billHistory = useSelector(billHistoryListState) || []

  useEffect(() => {
    dispatch(getBillHistoryForVendorPayment())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Invoice No.",
        accessor: "invoice_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.invoice_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Invoice Date",
        accessor: "invoice_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.invoice_date}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendor_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.vendor_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.company_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Goods & Services Category",
        accessor: "proposal_type",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.proposal_type}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Total Invoice Amount",
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {formatNumberWithCommas(
                    cellProps.row.original.invoice_amount
                  )}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Outstanding Amount",
        accessor: "outstanding_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {formatNumberWithCommas(
                    cellProps.row.original.outstanding_amount
                  ) || "NA"}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Invoice Verify Status",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link>
                <div
                  style={{
                    color:
                      cellProps.row.original.approval_status === 1
                        ? "green"
                        : cellProps.row.original.approval_status === 2
                        ? "red"
                        : "blue",
                  }}
                >
                  {cellProps.row.original.approval_status === 1
                    ? "Approved"
                    : cellProps.row.original.approval_status === 2
                    ? "Rejected"
                    : "Pending"}
                </div>{" "}
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Payment Status",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link>
                <div
                  style={{
                    color:
                      cellProps.row.original.is_paid === 1 ? "green" : "red",
                  }}
                >
                  {(cellProps.row.original.approval_status === 1) && (cellProps.row.original.is_paid === 1 ? "Paid" : cellProps.row.original.approval_status === 2 ? "Decline" : "Unpaid")}
                </div>
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Approved/Rejected By",
        accessor: "approved_by_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.approved_by_name || ""}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Approved/Rejected On",
        accessor: "formatted_approved_on",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.formatted_approved_on || ""}
                </Link>
              </h5>
            </>
          )
        },
      },
      ...permission
        .filter(
          item => item.page === "view-vendor-bill-document" && item.access === 1
        )
        .map((item, index) => ({
          Header: "View",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <a
                  key={index}
                  href={cellProps.row.original.invoice_copy_path.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-success"
                >
                  <i
                    className="mdi mdi-eye font-size-18"
                    id={`viewleaseagreementtooltip${index}`}
                    style={{ color: "#3366cc" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`viewleaseagreementtooltip${index}`}
                  >
                    View Invoice
                  </UncontrolledTooltip>
                </a>
              </div>
            )
          },
        })),
      ...permission
        .filter(
          item =>
            item.page === "download-vendor-bill-document" && item.access === 1
        )
        .map((item, index) => ({
          Header: "Download",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <a
                  key={index}
                  href={cellProps.row.original.invoice_copy_path.download_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-success"
                  download
                >
                  <i
                    className="mdi mdi-download font-size-18"
                    id={`downloadleaseagreementtooltip${index}`}
                    style={{ color: "#3366cc" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`downloadleaseagreementtooltip${index}`}
                  >
                    Download Invoice
                  </UncontrolledTooltip>
                </a>
              </div>
            )
          },
        })),
      ...permission
        .filter(
          item => item.page === "approve-vendor-bill/:id" && item.access === 1
        )
        .map((item, index) => ({
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  key={index}
                  to={`/approve-vendor-bill/${cellProps.row.original.vendor_invoice_id}`}
                  className="text-primary "
                >
                  <i
                    className="mdi mdi-chevron-right font-size-18"
                    id={`approveRejectTooltip${index}`}
                    style={{ color: "#3366cc" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`approveRejectTooltip${index}`}
                  >
                    Action
                  </UncontrolledTooltip>
                </Link>
              </div>
            )
          },
        })),
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Bills & Payment" breadcrumbItem="Bill History" />

          <Row>
            {/* {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : ( */}
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={billHistory || []}
                    isGlobalFilter={true}
                    isShowingPageLength={true}
                    isAddUserList={true}
                    iscustomPageSizeOptions={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    tableClass="table align-middle table-nowrap table-hover"
                    theadClass="table-light"
                    paginationDiv="col-sm-12 col-md-7"
                    pagination="pagination pagination-rounded justify-content-end mt-4"
                  />
                </CardBody>
              </Card>
            </Col>
            {/* )} */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorBillHistoryList
