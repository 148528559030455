import React, { useEffect, useState } from "react"
import { Col, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2" // Import Swal

//flatpickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"
import {
  getAllServiceRequestList,
  getCustomerFilterForConsumption,
  getProjectFilterForCustomerIndex,
} from "store/actions"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const serviceRequestState = state =>
    state.serviceRequests.serviceRequests.service_requests

  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const allProjectState = state => state.CustomerIndex.projectFilter.project
  const projectList = useSelector(allProjectState) || []
  const customerState = state => state.consumptionReport.customerFilter.data

  const customerList = useSelector(customerState) || []

  const [statusValue, setStatusValue] = useState("")
  const [project, setProject] = useState("")
  const [customer, setCustomer] = useState("")
  const [serviceType, setServiceType] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")

  const handleSelectStatus = ele => {
    setStatusValue(ele.value || "")
  }

  const handleProject = ele => {
    let data = ele.value
    setProject(data || "")
  }
  
  const handleCustomer = ele => {
    let data = ele.value
    setCustomer(data || "")
  }

  const handleServiceType = ele => {
    let data = ele.value
    setServiceType(data || "")
  }

  const handleSearch = () => {
    // Validate date range
    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Date Range",
        text: "From Date should not be greater than To Date",
        confirmButtonText: "OK"
      })
      return
    }

    if (user) {
      const value = {
        project_id: project,
        client_id: customer,
        status: statusValue,
        user_id: user.user_id,
        types_of_service: serviceType, // Include service type
        from_date: fromDate, // Include from date
        to_date: toDate, // Include to date
      }
      dispatch(getAllServiceRequestList(value))
    }
  }

  useEffect(() => {
    if (user) {
      dispatch(getProjectFilterForCustomerIndex(user.user_id))
    }
  }, [dispatch, user])

  useEffect(() => {
    if (user) {
      dispatch(getCustomerFilterForConsumption(user.user_id))
    }
  }, [user])

  return (
    <React.Fragment>
      <Col xxl={2} lg={6} className="mb-3">
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Status"
          onChange={e => handleSelectStatus(e.target)}
        >
          <option value="">Status</option>
          <option value="">All</option>
          <option value="1">Open</option>
          <option value="2">Ticket Created</option>
          <option value="3">In Progress</option>
          <option value="4">Ticket Closed</option>
          <option value="5">Declined</option>
          <option value="6">Assigned</option>
        </select>
      </Col>

      <Col xxl={2} lg={4} className="mb-3">
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleProject(e.target)}
        >
          <option value="">Select Project</option>
          {projectList &&
            projectList.map((project, index) => (
              <option key={index} value={project.id}>
                {project.project_name}
              </option>
            ))}
        </select>
      </Col>

      <Col xxl={2} lg={4} className="mb-3">
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Customer"
          onChange={e => handleCustomer(e.target)}
        >
          <option value="">Select Customer</option>
          {customerList &&
            customerList.map((customer, index) => (
              <option key={index} value={customer.id}>
                {customer.customer_name}
              </option>
            ))}
        </select>
      </Col>

      {/* Service Type Dropdown */}
      <Col xxl={2} lg={4} className="mb-3">
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Service Type"
          onChange={e => handleServiceType(e.target)}
        >
          <option value="">Select Service Type</option>
          <option value="1">Looking for Additional Project Units</option>
          <option value="2">Facing Maintenance Issues</option>
          <option value="4">Finance,Accounting,Compliance and Legal Issue</option>
        </select>
      </Col>

      {/* Date Picker for From Date */}
      <Col xxl={2} lg={4} className="mb-3">
        <input
          type="date"
          className="form-control mb-3 mb-xxl-0"
          value={fromDate}
          onChange={e => setFromDate(e.target.value)}
          placeholder="From Date"
        />
      </Col>

      {/* Date Picker for To Date */}
      <Col xxl={2} lg={4} className="mb-3">
        <input
          type="date"
          className="form-control mb-3 mb-xxl-0"
          value={toDate}
          onChange={e => setToDate(e.target.value)}
          placeholder="To Date"
        />
      </Col>
      
      <Col xxl={1} lg={4} className="mb-3">
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default JobListGlobalFilter
