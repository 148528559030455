import { call, put, takeEvery } from "redux-saga/effects"
import Swal from "sweetalert2"

// Crypto Redux States
import {
  GET_SACHSNCODE,
  GET_SACHSNCODE_DETAIL,
  ADD_NEW_SACHSNCODE,
  UPDATE_SACHSNCODE,
  DELETE_SACHSNCODE,
} from "./actionTypes"
import {
  getSACHSNCodeSuccess,
  getSACHSNCodeFail,
  getSACHSNCodeDetailSuccess,
  getSACHSNCodeDetailFail,
  addNewSACHSNCodeSuccess,
  addNewSACHSNCodeFail,
  updateSACHSNCodeSuccess,
  updateSACHSNCodeFail,
  deleteSACHSNCodeSuccess,
  deleteSACHSNCodeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSACHSNCode,
  getSACHSNCodeDetail,
  addNewSACHSNCode,
  updateSACHSNCode,
  deleteSACHSNCode,
} from "helpers/fakebackend_helper"

function* fetchSACHSNCode() {
  try {
    const response = yield call(getSACHSNCode)
    yield put(getSACHSNCodeSuccess(response))
  } catch (error) {
    yield put(getSACHSNCodeFail(error))
  }
}

function* fetchSACHSNCodeDetail({ codeId }) {
  try {
    const response = yield call(getSACHSNCodeDetail, codeId)
    yield put(getSACHSNCodeDetailSuccess(response))
  } catch (error) {
    yield put(getSACHSNCodeDetailFail(error))
  }
}

function* onUpdateSACHSNCode({ payload: { id, code, history } }) {
  try {
    const response = yield call(updateSACHSNCode, id, code)
    if (response && response.status === true) {
      yield put(updateSACHSNCodeSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your SAC / HSN Code Master Has Been Updated.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/sac-hsn-code-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(updateSACHSNCodeFail(error))
    Swal.fire({
      title: "Failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* onDeleteSACHSNCode({ payload: code }) {
  try {
    const response = yield call(deleteSACHSNCode, code)
    const response2 = yield put(deleteSACHSNCodeSuccess(response))
    // yield put(deleteSACHSNCodeSuccess(response))
    if (response2.payload.status) {
      const sacHsnCodeResponse = yield call(getSACHSNCode)
      yield put(getSACHSNCodeSuccess(sacHsnCodeResponse))
    } else {
      console.error("Error in delete SAC HSN Code:", response.error)
    }
  } catch (error) {
    yield put(deleteSACHSNCodeFail(error))
  }
}

// function* onDeleteClient({ payload: client }) {
//   try {
//     const response = yield call(deleteClient, client)
//     const response3 = yield put(deleteClientSuccess(response))
//     // yield take('DELETE_CLIENT_SUCCESS');
//     if (response3.payload.status) {
//       const clientListResponse = yield call(getClientsList)
//       yield put(getClientsListSuccess(clientListResponse))
//     }
//   } catch (error) {
//     yield put(deleteClientFail(error))
//   }
// }

function* onAddNewSACHSNCode({ payload: { codes, history } }) {
  try {
    const response = yield call(addNewSACHSNCode, codes)
    if (response.status === true) {
      yield put(addNewSACHSNCodeSuccess(response))
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your SAC / HSN Code Master Has Been Created.",
      }).then(result => {
        if (result.isConfirmed) {
          history("/settings/sac-hsn-code-master")
        }
      })
    } else {
      throw new Error(
        response && response.message ? response.message : "Unknown error"
      )
    }
  } catch (error) {
    yield put(addNewSACHSNCodeFail(error))
    Swal.fire({
      title: "failure",
      icon: "error",
      text: error.message || "Unknown error",
    })
  }
}

function* sachsnSaga() {
  yield takeEvery(GET_SACHSNCODE, fetchSACHSNCode)
  yield takeEvery(GET_SACHSNCODE_DETAIL, fetchSACHSNCodeDetail)
  yield takeEvery(ADD_NEW_SACHSNCODE, onAddNewSACHSNCode)
  yield takeEvery(UPDATE_SACHSNCODE, onUpdateSACHSNCode)
  yield takeEvery(DELETE_SACHSNCODE, onDeleteSACHSNCode)
}

export default sachsnSaga
