import { downloadBillingDataExcel } from "helpers/fakebackend_helper"
import { API_URL } from "helpers/url_helper"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import "simplebar-react/dist/simplebar.min.css"
import { getAssignedProjectUnit } from "store/actions"

const IsOccupiedModal = ({ isOpen, toggle, unitId }) => {
  const dispatch = useDispatch()
  const viewAssignedProjectUnitState = state =>
    state.clients?.pastEmp?.project_unit
  const projectUnit = useSelector(viewAssignedProjectUnitState)

  useEffect(() => {
    if (isOpen === true) {
      dispatch(getAssignedProjectUnit(unitId))
    }
  }, [unitId, dispatch, isOpen])

  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-body">
        <Row className="justify-content-center">
          <Col lg={12}>
            <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
              <div className="d-flex gap-2 ">
                Client Project Unit Details{" "}
                <button
                  onClick={toggle}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </CardTitle>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Label className="form-label">Project</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.project_name}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Project Unit </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.unit_code}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Client</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.client_name}
                    </p>
                  </Col>

                  {projectUnit && projectUnit?.if_sale === 0 && (
                    <>
                      <Col lg={4}>
                        <Label className="form-label">Revenue Share</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {projectUnit?.revenue_share === 1
                            ? "Monthly"
                            : projectUnit?.revenue_share === 2
                            ? "Quarterly"
                            : projectUnit?.revenue_share === 3
                            ? "Half Yearly"
                            : projectUnit?.revenue_share === 4
                            ? "Yearly"
                            : ""}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">Revenue Share(%)</Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {projectUnit?.revenue_share_percentage}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <Label className="form-label">
                          Security Deposit For Rent
                        </Label>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {projectUnit?.security_deposit_for_rent}
                        </p>
                      </Col>
                    </>
                  )}

                  <Col lg={4}>
                    <Label className="form-label">Sale/Rent</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit?.if_sale && (
                        <span>
                          {projectUnit.if_sale === 1
                            ? "Sale"
                            : projectUnit.if_sale === 0
                            ? "Rent"
                            : ""}
                        </span>
                      )}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">
                      {projectUnit?.if_sale === 1
                        ? "Sale Amount"
                        : projectUnit?.if_sale === 0
                        ? "Rent Amount"
                        : ""}
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit?.if_sale === 1
                        ? projectUnit?.sale_amount
                        : projectUnit?.if_sale === 0
                        ? projectUnit?.rent_amount
                        : ""}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Lease Rent Amount</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit?.lease_rent_amount}
                    </p>
                  </Col>

                  <Col lg={4}>
                    <Label className="form-label">Rent Payment Schedule</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.rent_payment_schedule && (
                        <span>
                          {projectUnit.rent_payment_schedule === 1
                            ? "Monthly"
                            : projectUnit.rent_payment_schedule === 2
                            ? "Quaterly"
                            : projectUnit.rent_payment_schedule === 3
                            ? "Half Yearly"
                            : projectUnit.rent_payment_schedule === 4
                            ? "Yearly"
                            : ""}
                        </span>
                      )}{" "}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">
                      Customer Lock-in Period (Months)
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.customer_lock_in_period}
                    </p>
                  </Col>

                  <Col lg={4}>
                    <Label className="form-label">Notice Period (Months)</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.notice_period}
                    </p>
                  </Col>

                  <Col lg={4}>
                    <Label className="form-label">
                      Security Deposit For Electricity
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit &&
                        projectUnit?.security_deposit_for_electricity}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">
                      Security Deposit For Maintenance
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {(projectUnit &&
                        projectUnit?.security_deposit_for_maintenance) ||
                        "NA"}
                    </p>
                  </Col>

                  <Col lg={4}>
                    <Label className="form-label">Agreement Start Date </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.agreement_start_date_formatted}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label"> Agreement End Date </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.agreement_end_date_formatted}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">Rent Escalation(%) </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.rent_escalation_percentage}
                    </p>
                  </Col>

                  <Col lg={4}>
                    <Label className="form-label">
                      Registration cost for Agreement on Company{" "}
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit &&
                        projectUnit?.registration_cost_for_agreement_on_company}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">
                      {" "}
                      Rent Escalation Period (Months){" "}
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.rent_escalation_period}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label"> CAM Charges Amount </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.cam_charges_amount}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label"> CAM Escalation(%)</Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.cam_escalation_percentage}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <Label className="form-label">
                      {" "}
                      CAM Escalation Period (Months)
                    </Label>
                    <p className="text-dark" style={{ fontWeight: 600 }}>
                      {projectUnit && projectUnit?.cam_escalation_period}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col>
                    <Label className="form-label">Invoice Type</Label>
                  </Col>
                  <Col>
                    <Label className="form-label">Amount</Label>
                  </Col>
                  <Col>
                    <Label className="form-label">SAC / HSN Code</Label>
                  </Col>
                  <Col>
                    <Label className="form-label">GST Rate</Label>
                  </Col>
                  <Col>
                    <Label className="form-label">GST Amount</Label>
                  </Col>
                  <Col>
                    <Label className="form-label">Total</Label>
                  </Col>
                </Row>
                {projectUnit?.billing_components
                  ?.filter(item => item.check === 1)
                  .map((item, index) => (
                    <Row key={index} className="mb-3">
                      <Col>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {item.description}
                        </p>
                      </Col>
                      <Col>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {item.amt_per_unit}
                        </p>
                      </Col>
                      <Col>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {item.sac_hsn?.sac_hsm_code}
                        </p>
                      </Col>
                      <Col>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {item.gst_percentage}
                        </p>
                      </Col>
                      <Col>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {item.gst_amount}
                        </p>
                      </Col>
                      <Col>
                        <p className="text-dark" style={{ fontWeight: 600 }}>
                          {item.total_amount}
                        </p>
                      </Col>
                    </Row>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default IsOccupiedModal
