import {
  GET_VENDORTYPE_MASTER_LIST_SUCCESS,
  GET_VENDORTYPE_MASTER_LIST_FAIL,
  GET_VENDORTYPE_MASTER_DETAIL_SUCCESS,
  GET_VENDORTYPE_MASTER_DETAIL_FAIL,
  ADD_NEW_VENDORTYPE_MASTER_SUCCESS,
  ADD_NEW_VENDORTYPE_MASTER_FAIL,
  UPDATE_VENDORTYPE_MASTER_SUCCESS,
  UPDATE_VENDORTYPE_MASTER_FAIL,
  DELETE_VENDORTYPE_MASTER_SUCCESS,
  DELETE_VENDORTYPE_MASTER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  vendorType: [],
  addVendorType: [],
  vendorTypeDetail: {},
  error: {},
  loading: true,
}

const vendorTypeMaster = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VENDORTYPE_MASTER_LIST_SUCCESS:
      return {
        ...state,
        vendorType: action.payload,
        loading: false,
      }

    case GET_VENDORTYPE_MASTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_VENDORTYPE_MASTER_DETAIL_SUCCESS:
      return {
        ...state,
        vendorTypeDetail: action.payload,
        loading: false,
      }

    case GET_VENDORTYPE_MASTER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_VENDORTYPE_MASTER_SUCCESS:
      return {
        ...state,
        addVendorType: [...state.addVendorType, action.payload],
      }

    case ADD_NEW_VENDORTYPE_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_VENDORTYPE_MASTER_SUCCESS:
      return {
        ...state,
        addVendorType: state.addVendorType.map(vendorType =>
          vendorType.id === action.payload.id ? { ...vendorType, ...action.payload } : vendorType
        ),
      }

    case UPDATE_VENDORTYPE_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_VENDORTYPE_MASTER_SUCCESS:
      return {
        ...state,
        vendorType: state.vendorType.data.filter(
          vendorType => vendorType.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_VENDORTYPE_MASTER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default vendorTypeMaster
