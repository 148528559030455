import DeleteModal from "components/Common/DeleteModal"
import {
  deleteManpower,
  deleteMaterial,
  deleteOtherCost,
  updateStatusInTicket,
} from "helpers/fakebackend_helper"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import {
  getServiceRequestTicketDetail,
  sendForReviewInTicket,
} from "store/actions"
import Swal from "sweetalert2"
import SimpleBar from "simplebar-react"

const DetailsSection = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const [ticketId, setTicketId] = useState()
  const path = useLocation()
  const [modalLarge1, setModalLarge1] = useState(false)
  const toggleModalLarge1 = () => setModalLarge1(!modalLarge1)
  const [modalLarge2, setModalLarge2] = useState(false)
  const toggleModalLarge2 = () => setModalLarge2(!modalLarge2)
  const [modalLarge3, setModalLarge3] = useState(false)
  const toggleModalLarge3 = () => setModalLarge3(!modalLarge3)
  const [modalLarge4, setModalLarge4] = useState(false)
  const toggleModalLarge4 = () => setModalLarge4(!modalLarge4)
  const [modalLarge5, setModalLarge5] = useState(false)
  const toggleModalLarge5 = () => setModalLarge5(!modalLarge5)

  useEffect(() => {
    const pathName = path.pathname
    const ticketId = pathName.split("/view-ticket/")[1]
    setTicketId(ticketId)
    dispatch(getServiceRequestTicketDetail(ticketId))
  }, [dispatch, path])

  const ticketViewDetailState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request_ticket
  const ticketData = useSelector(ticketViewDetailState) || []
  const serviceRequestDetailState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request
  const data = useSelector(serviceRequestDetailState) || []

  const ticketLogState = state =>
    state.ServiceTicket.serviceTicketDetail.service_request_ticket_log
  const trackingData = useSelector(ticketLogState) || []

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = (users, delete_type) => {
    setDeleteId({ id: users, type: delete_type })
    setDeleteModal(true)
  }

  const handleDeleteUser = async () => {
    let response
    if (deleteId && deleteId.id && deleteId.type === "material") {
      response = await deleteMaterial(deleteId.id)
    }
    if (deleteId && deleteId.id && deleteId.type === "manpower") {
      response = await deleteManpower(deleteId.id)
    }
    if (deleteId && deleteId.id && deleteId.type === "othercost") {
      response = await deleteOtherCost(deleteId.id)
    }
    if (response.status === true) {
      Swal.fire({
        title: "Success",
        icon: "success",
        // text: "Your Billing Setup has been Saved.",
      }).then(result => {
        if (result.isConfirmed) {
          dispatch(getServiceRequestTicketDetail(ticketId))
        }
      })
    }
    setDeleteModal(false)
  }

  const handleStartWork = async () => {
    const value = {
      status: 4,
      // rework_description:''
    }
    let loadingSwal
    try {
      loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const response = await updateStatusInTicket(ticketId, value)

      if (response && response.status === true) {
        loadingSwal.close()

        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Work has been Approved to Start",
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(getServiceRequestTicketDetail(ticketId))
          }
        })
      } else {
        loadingSwal.close()

        Swal.fire({
          title: "Failure",
          icon: "error",
          text: "Your Work Start Request has not been Submitted.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      if (loadingSwal) {
        loadingSwal.close()
      }

      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response?.data?.message ||
          "An error occurred while Submitting.",
      })
    }
  }
  function getStatusText(value) {
    if (value === 1) {
      return "Approved"
    } else if (value === 2) {
      return "Rejected"
    } else {
      return "Pending"
    }
  }
  const [resolvedData, setResolvedData] = useState({
    file_title: "",
    file_path: "",
  })
  const handleResolved = () => {
    const value = resolvedData
    dispatch(sendForReviewInTicket(ticketId, value))
  }
  const handleTicketClose = async () => {
    const value = {
      status: 7,
      // rework_description:''
    }
    let loadingSwal
    try {
      loadingSwal = Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const response = await updateStatusInTicket(ticketId, value)

      if (response && response.status === true) {
        loadingSwal.close()

        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Ticket has been Successfully Closed",
        }).then(result => {
          if (result.isConfirmed) {
            dispatch(getServiceRequestTicketDetail(ticketId))
          }
        })
      } else {
        loadingSwal.close()

        Swal.fire({
          title: "Failure",
          icon: "error",
          text: "Your Ticket Close Request has not been Submitted.",
        })
      }
    } catch (error) {
      console.error("Error:", error)
      if (loadingSwal) {
        loadingSwal.close()
      }

      Swal.fire({
        title: "Error",
        icon: "error",
        text:
          error.response?.data?.message ||
          "An error occurred while Submitting Ticket Close Request.",
      })
    }
  }

  const getStatusColor = status => {
    switch (status) {
      case "Open":
        return "text-secondary" // Primary color
      case "Ticket Created":
        return "text-primary" // Success color
      case "In Progress":
        return "text-warning" // Warning color
      case "Closed":
        return "text-success" // Danger color
      case "Rejected":
        return "text-danger" // Danger color
      default:
        return ""
    }
  }
  return (
    <React.Fragment>
      <Col xl={9}>
        <Card>
          <CardBody className="border-bottom">
            <div className="d-flex">
              {/* <img src={wechat} alt="" height="50" /> */}
              <div className="flex-grow-1 ms-3">
                <h5 className="fw-semibold">Ticket Details</h5>
                <ul className="list-unstyled hstack gap-2 mb-0">
                  <li>
                    <i className="mdi mdi-ticket-outline"></i>{" "}
                    <span className="text-muted">
                      {" "}
                      {ticketData && ticketData.ticket_no}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <Row>
              <Col lg={4}>
                <h6>Type of Service</h6>
              </Col>
              <Col lg={8}>{data && data.service_type}</Col>
            </Row>
            <hr></hr>
            {data && data.maintenance_issues !== null && (
              <>
                <Row>
                  <Col lg={4}>
                    <h6>Maintainence Issue</h6>
                  </Col>
                  <Col lg={8}>
                    {data && data.maintenance_issue_type === null
                      ? "NA"
                      : data.maintenance_issue_type}
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {data && data.project_name !== null && (
              <>
                <Row>
                  <Col lg={4}>
                    <h6>Project In Which You Are Facing Issue</h6>
                  </Col>
                  <Col lg={8}>
                    {data && data.project_name === null
                      ? "NA"
                      : data.project_name}
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {data && data.unit_code !== null && (
              <>
                <Row>
                  <Col lg={4}>
                    <h6>Project Unit In Which You Are Facing Issue</h6>
                  </Col>
                  <Col lg={8}>
                    {data && data.unit_code === null ? "NA" : data.unit_code}
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {data && data.prefered_duration_for_user_engagement !== null && (
              <>
                <Row>
                  <Col lg={4}>
                    <h6>How soon you want to start new engagement?</h6>
                  </Col>
                  <Col lg={8}>
                    {data && data.prefered_duration_for_user_engagement === null
                      ? "NA"
                      : data.prefered_duration_for_user_engagement}
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {(data && data.support_ticket_topic !== null) ||
              ("" && (
                <>
                  {" "}
                  <Row>
                    <Col lg={4}>
                      <h6>Category</h6>
                    </Col>
                    <Col lg={8}>
                      {data && data.support_ticket_topic === null
                        ? "NA"
                        : data.support_ticket_topic}
                    </Col>
                  </Row>
                  <hr />
                </>
              ))}
            {data && data.category_title !== null && (
              <>
                {" "}
                <Row>
                  <Col lg={4}>
                    <h6>Project Unit Category</h6>
                  </Col>
                  <Col lg={8}>
                    {data && data.category_title === null
                      ? "NA"
                      : data.category_title}
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {data && data.budget_prefered !== null && (
              <>
                {" "}
                <Row>
                  <Col lg={4}>
                    <h6>Prefered Budget</h6>
                  </Col>
                  <Col lg={8}>
                    {data && data.budget_prefered === null
                      ? "NA"
                      : data.budget_prefered}
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {(data && data.preferred_project_name !== null) ||
              ("" && (
                <>
                  {" "}
                  <Row>
                    <Col lg={4}>
                      <h6>Preferred Project</h6>
                    </Col>
                    <Col lg={8}>
                      {data && data.preferred_project_name === null
                        ? "NA"
                        : data.preferred_project_name}
                    </Col>
                  </Row>
                  <hr />
                </>
              ))}
            <Row>
              <Col lg={4}>
                <h6>Task Description</h6>
              </Col>
              <Col lg={8}>{data && data.description}</Col>
            </Row>
            <hr></hr>
            {data && data?.files?.length > 0 && (
              <Row>
                <Col lg={4}>
                  <h6>Supporting Pics / Videos / Document</h6>
                </Col>
                <Col lg={8}>
                  {data && data?.files?.length > 0 ? (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {data.files.map((file, index) => (
                        <div
                          key={index}
                          style={{
                            width: "200px",
                            height: "150px",
                            margin: "5px",
                            overflow: "hidden",
                            borderRadius: "15px",
                          }}
                        >
                          <a
                            href={file.file_path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={file.file_path}
                              alt={`File ${index}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  ) : (
                    "NA"
                  )}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {/* <h5 className="fw-semibold">Ticket Summary</h5> */}

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Ticket Incharge</th>
                    <td scope="col">
                      {ticketData && ticketData.ticket_supervisor_name}
                    </td>
                    <th scope="col">Mode Of Operation</th>
                    <td scope="col">
                      {ticketData && ticketData.mode_of_operation === 1
                        ? "Internal"
                        : ticketData && ticketData.mode_of_operation === 2
                        ? "External"
                        : null}
                    </td>
                  </tr>
                  {ticketData && ticketData.mode_of_operation === 1 && (
                    <tr>
                      <th scope="row">Assigned to:</th>
                      <td>{ticketData && ticketData.assigned_user_name}</td>
                      <th scope="row">Material Required</th>
                      <td>
                        {ticketData &&
                        Number(ticketData.is_additional_material_required) === 1
                          ? "Yes"
                          : ticketData.is_additional_material_required === 2
                          ? "NO"
                          : null}
                      </td>
                    </tr>
                  )}
                  {ticketData && ticketData.mode_of_operation === 2 && (
                    <>
                      <tr>
                        <th scope="row">Vendor</th>
                        <td>{ticketData && ticketData.vendor_name}</td>
                        <th scope="row">Work Type</th>
                        <td>
                          {ticketData && Number(ticketData.work_type) === 1
                            ? "AMC"
                            : Number(ticketData.work_type) === 2
                            ? "NEW WORK"
                            : null}
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Material Required</th>
                        <td>
                          {ticketData &&
                          ticketData.is_additional_material_required === 1
                            ? "Yes"
                            : ticketData.work_type === 2
                            ? "NO"
                            : null}
                        </td>

                        <th scope="row">Manpower Required</th>
                        <td>
                          {ticketData && ticketData.is_manpower_required === 1
                            ? "Yes"
                            : "NO"}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <th scope="row">Priority</th>
                    <td>
                      <span
                        className={`badge ${
                          ticketData && ticketData.priority_id === 1
                            ? "badge-soft-danger"
                            : ticketData.priority_id === 2
                            ? "badge-soft-warning"
                            : ticketData.priority_id === 3
                            ? "badge-soft-info"
                            : ticketData.priority_id === 4
                            ? "badge-soft-success"
                            : "badge-soft-light"
                        }`}
                      >
                        {ticketData && ticketData.priority_id === 1
                          ? "Critical"
                          : ticketData.priority_id === 2
                          ? "High"
                          : ticketData.priority_id === 3
                          ? "Medium"
                          : ticketData.priority_id === 4
                          ? "Low"
                          : "NA"}
                      </span>{" "}
                    </td>
                    <th scope="row">Instruction</th>
                    <td style={{ maxWidth: "50px" }}>
                      {ticketData && ticketData.instructions}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        {ticketData &&
        (ticketData.ticket_current_status_id === 3 ||
          ticketData.ticket_current_status_id === 8) &&
        Number(ticketData.assigned_internal_user_id) ===
          Number(user?.user_id) ? (
          <>
            <Card>
              <CardBody>
                <CardTitle>Material </CardTitle>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Material</th>
                        <th>Quantity</th>
                        <th>Unit</th>
                        <th>Unit Price</th>
                        <th>Amount</th>
                        <th>Quotation File</th>
                        <th>Status</th>

                        <th>
                          {" "}
                          <button
                            className="btn btn-primary"
                            onClick={toggleModalLarge1}
                          >
                            Add Material
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {((ticketData && ticketData?.materials) || []).map(
                        (row, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{row?.material?.materials}</td>
                            <td>{row.quantity}</td>
                            <td>{row?.unit?.unit}</td>
                            <td>{row.unit_price}</td>
                            <td>{row.total_cost}</td>
                            <td>
                              <a
                                href={row.file_path}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {row.file_path ? "Quote File" : "NA"}
                              </a>
                            </td>

                            <td>{getStatusText(row.is_rejected)}</td>

                            {row.is_rejected === 0 && (
                              <td>
                                <Link
                                  onClick={() => {
                                    handleEstimation("material", row.id)
                                  }}
                                >
                                  Approve/Reject
                                </Link>
                              </td>
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Manpower</CardTitle>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Labour Type</th>
                        <th>Quantity</th>
                        <th>Amount/Unit</th>
                        <th>Total</th>
                        <th>Quotation File</th>

                        <th>Status</th>

                        <th>
                          {" "}
                          <button
                            className="btn btn-primary"
                            onClick={toggleModalLarge2}
                          >
                            Add Manpower
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {((ticketData && ticketData?.manpower) || []).map(
                        (row, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{row.label_required}</td>
                            <td>{row.quantity}</td>
                            <td>{row.cost_per_unit}</td>
                            <td>{row.total_cost}</td>
                            <td>
                              <a
                                href={row.file_path}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {row.file_path ? "Quote File" : "NA"}
                              </a>
                            </td>

                            <td>{getStatusText(row.is_rejected)}</td>
                            {row.is_rejected === 0 && (
                              <td>
                                <Link
                                  onClick={() => {
                                    handleEstimation("manpower", row.id)
                                  }}
                                >
                                  Approve/Reject
                                </Link>
                              </td>
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Other Cost</CardTitle>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Quotation File</th>

                        <th>Status</th>

                        <th>
                          {" "}
                          <button
                            className="btn btn-primary"
                            onClick={toggleModalLarge3}
                          >
                            Add Other
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {((ticketData && ticketData?.other_cost) || []).map(
                        (row, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{row.requirement}</td>
                            <td>{row.other_cost}</td>
                            <td>
                              <a
                                href={row.file_path}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {row.file_path ? "Quote File" : "NA"}
                              </a>
                            </td>

                            <td>{getStatusText(row.is_rejected)}</td>
                            {row.is_rejected === 0 && (
                              <td>
                                <Link
                                  onClick={() => {
                                    handleEstimation("other_cost", row.id)
                                  }}
                                >
                                  Approve/Reject
                                </Link>
                              </td>
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </>
        ) : (
          <>
            {ticketData && ticketData?.materials?.length > 0 && (
              <Card>
                <CardBody>
                  <CardTitle>Material </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Material</th>
                          <th>Quantity</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th>Amount</th>
                          <th>Quotation File</th>

                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((ticketData && ticketData?.materials) || []).map(
                          (row, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{row?.material?.materials}</td>
                              <td>{row.quantity}</td>
                              <td>{row?.unit?.unit}</td>
                              <td>{row.unit_price}</td>
                              <td>{row.total_cost}</td>
                              <td>
                                <a
                                  href={row.file_path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {row.file_path ? "Quote File" : "NA"}
                                </a>
                              </td>

                              <td>{getStatusText(row.is_rejected)}</td>
                              {row.is_rejected === 0 && (
                                <td>
                                  <Link
                                    onClick={() => {
                                      handleEstimation("material", row.id)
                                    }}
                                  >
                                    Approve/Reject
                                  </Link>
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            )}
            {ticketData && ticketData?.manpower?.length > 0 && (
              <Card>
                <CardBody>
                  <CardTitle>Manpower</CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Labour Type</th>
                          <th>Quantity</th>
                          <th>Amount/Unit</th>
                          <th>Total</th>
                          <th>Quotation File</th>

                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((ticketData && ticketData?.manpower) || []).map(
                          (row, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{row.label_required}</td>
                              <td>{row.quantity}</td>
                              <td>{row.cost_per_unit}</td>
                              <td>{row.total_cost}</td>
                              <td>
                                <a
                                  href={row.file_path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {row.file_path ? "Quote File" : "NA"}
                                </a>
                              </td>

                              <td>{getStatusText(row.is_rejected)}</td>
                              {row.is_rejected === 0 && (
                                <td>
                                  <Link
                                    onClick={() => {
                                      handleEstimation("manpower", row.id)
                                    }}
                                  >
                                    Approve/Reject
                                  </Link>
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            )}
            {ticketData && ticketData?.other_cost?.length > 0 && (
              <Card>
                <CardBody>
                  <CardTitle>Other Cost</CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Item</th>
                          <th>Price</th>

                          <th>Quotation File</th>

                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((ticketData && ticketData?.other_cost) || []).map(
                          (row, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{row.requirement}</td>
                              <td>{row.other_cost}</td>
                              <td>
                                <a
                                  href={row.file_path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {row.file_path ? "Quote File" : "NA"}
                                </a>
                              </td>
                              <td>{getStatusText(row.is_rejected)}</td>
                              {row.is_rejected === 0 && (
                                <td>
                                  <Link
                                    onClick={() => {
                                      handleEstimation("other_cost", row.id)
                                    }}
                                  >
                                    Approve/Reject
                                  </Link>
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            )}
            {ticketData && ticketData?.ticket_files?.length > 0 && (
              <Card>
                <CardBody>
                  {ticketData.ticket_current_status_id !== 7 ? (
                    <CardTitle>Submitted Documents For Review</CardTitle>
                  ) : (
                    <CardTitle>Work Completion Proof</CardTitle>
                  )}
                  <Row>
                    <Col lg={12}>
                      {/* <Label> File Title : {ticketData?.ticket_files[0].file_title}</Label> */}

                      {ticketData &&
                      ticketData?.ticket_files?.length > 0 &&
                      (Number(ticketData.project_manager_id) ===
                        Number(user?.user_id) ||
                        Number(ticketData.ticket_supervisor_id) ===
                          Number(user?.user_id) ||
                        Number(ticketData.assigned_internal_user_id) ===
                          Number(user?.user_id) ||
                        Number(user?.user_id) === 1) ? (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {ticketData?.ticket_files.map((file, index) => (
                            <div
                              key={index}
                              style={{
                                width: "200px",
                                height: "150px",
                                margin: "5px",
                                overflow: "hidden",
                                borderRadius: "15px",
                              }}
                            >
                              <a
                                href={file.file_path}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={file.file_path}
                                  alt={`File ${index}`}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </a>
                            </div>
                          ))}
                        </div>
                      ) : (
                        "NA"
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </>
        )}
      </Col>
    </React.Fragment>
  )
}

export default DetailsSection
