import React, { useEffect, useState } from "react"
import FlatPickr from "react-flatpickr"
import LargeModal from "../../EmailModal/emailModal"
import LargeModal5 from "../../Decline/declineModal"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { API_URL } from "helpers/url_helper"
import { downloadGstR1Excell } from "helpers/fakebackend_helper"
import { getRenewalDetail, getRenewals } from "store/actions"
import { useLocation, useNavigate } from "react-router-dom"

const RenewalDetailList = props => {
  document.title = "Renewal Detail"
  const navigate = useNavigate()
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [modalLarge, setModalLarge] = useState(false)
  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [modalLarge5, setModalLarge5] = useState(false)
  const toggleModalLarge5 = () => setModalLarge5(!modalLarge5)
  const dispatch = useDispatch()
  const [countType, setCountType] = useState()
  const [projectId, setProjectId] = useState()

  const RenewalState = state => state.renewals.renewalDetail.data

  const RenewalList = useSelector(RenewalState) || []

  useEffect(() => {
    const url = new URL(window.location.href)
    const projectId = url.pathname.split("/thirtydays/")[1]
    setProjectId(projectId)
    setCountType({ days: "31-60" })
    if (projectId) {
      const value = {
        project_id: projectId,
        days: "31-60",
      }
      dispatch(getRenewalDetail(value))
    }
  }, [dispatch])

  const [selectedOption, setSelectedOption] = useState("")
  const [data, setMailData] = useState()


  const sendMailModal = value => {
    setMailData(value)

    toggleModalLarge()
  }

  const [unitId, setUnitId] = useState()

  const onClickDecline = value => {
    setUnitId(value)
    toggleModalLarge5()
  }

  const handleChange = (e, row) => {
    const selectedAction = e.target.value
    if (selectedAction === "Renew") {
      navigate(`/renewals/renew-project-unit/${row.id}`)
    } else if (selectedAction === "Decline") {
      onClickDecline(row.id)
    } else if (selectedAction === "Send Reminder Mail") {
      sendMailModal(row)
    } else if (selectedAction === "View") {
      navigate(`/renewals/view-project-unit/${row.id}`)
    }
    e.target.value = "Action"
  }

  return (
    <React.Fragment>
      <LargeModal5
        isOpen={modalLarge5}
        toggle={toggleModalLarge5}
        id={unitId}
        countType={countType}
        projectId={projectId}
      />

      <LargeModal isOpen={modalLarge} toggle={toggleModalLarge} data={data} />
      <div className="page-content">
        <div className="container-fluid">
        <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  Manage Renewals
                </div>
              </CardTitle>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                <Row>
                {RenewalList && RenewalList.length > 0 && (
                  <Col>
                      <CardTitle className="h4">
                        Project Name :{" "}
                        {RenewalList && RenewalList[0]?.project_name}{" "}
                      </CardTitle>
                    </Col>)}
                  </Row>
                  <Row>
                    <Col>
                      <CardTitle className="h4">
                        Category : Renewal due in 30 - 60 days 
                      </CardTitle>
                    </Col>
                  </Row>
                  <hr />
                  {RenewalList && RenewalList.length > 0 ? (
                    <div className="table-responsive">
                      <Table className="table table-striped mb-0">
                        <thead className="text-center">
                          <tr>
                            <th>#</th>
                            <th>Client</th>
                            <th>Unit Code</th>
                            <th>Agreement Start Date</th>
                            <th>Agreement End Date</th>
                            <th >Sale/Rent Amount</th>
                            <th>Rent Escalation(%)</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {(RenewalList || []).map((row, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{row?.client_name}</td>
                              <td>{row?.unit_code}</td>
                              <td>{row?.agreement_start_date}</td>
                              <td>{row?.agreement_end_date}</td>
                              <td>{row.if_sale === 1 ?  row.sale_amount : row.if_sale === 0 ? row.sale_amount : "NA"}</td>
                              <td>{row?.rent_escalation_percentage}</td>
                              <td>
                              <select
                                  className="form-select"
                                  value={selectedOption}
                                  onChange={e => {
                                    handleChange(e, row)
                                  }}
                                >
                                  <option value="">Select an option</option>
                                  {permission
                                    .filter(
                                      item =>
                                        item.page ===
                                          "/renew-project-unit/:id" &&
                                        item.access === 1
                                    )
                                    .map((item, index) => (
                                      <option key={index} value="Renew">
                                        Renew
                                      </option>
                                    ))}
                                  {permission
                                    .filter(
                                      item =>
                                        item.page ===
                                          "/view-project-unit/:id" &&
                                        item.access === 1
                                    )
                                    .map((item, index) => (
                                      <option key={index} value="View">
                                        View
                                      </option>
                                    ))}
                                  {permission
                                    .filter(
                                      item =>
                                        item.page ===
                                          "send-renewal-reminder-mail" &&
                                        item.access === 1
                                    )
                                    .map((item, index) => (
                                      <option
                                        key={index}
                                        value="Send Reminder Mail"
                                      >
                                        Send Reminder Mail
                                      </option>
                                    ))}
                                  {permission
                                    .filter(
                                      item =>
                                        item.page ===
                                          "decline-renewal" &&
                                        item.access === 1
                                    )
                                    .map((item, index) => (
                                      <option key={index} value="Decline">
                                        Decline
                                      </option>
                                    ))}
                                </select>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <Alert color="warning" role="alert">
                      No Data...
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RenewalDetailList
