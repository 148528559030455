import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMPANIES,
  GET_COMPANY_DETAIL,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  GET_DOCUMENT_LIST_COMPANY,
  ADD_DOCUMENT_COMPANY,
  DELETE_DOCUMENT_COMPANY,
  GET_COMPANY_LOCATION_LIST,
  ADD_COMPANY_LOCATION,
  UPDATE_COMPANY_LOCATION,
  DELETE_COMPANY_LOCATION,
  GET_COMPANY_LOCATION_DETAIL,
} from "./actionTypes"
import {
  getCompaniesSuccess,
  getCompaniesFail,
  getCompanyDetailSuccess,
  getCompanyDetailFail,
  addCompanyFail,
  addCompanySuccess,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  getCompanyDocumentsSuccess,
  getCompanyDocumentsFail,
  addCompanyDocumentSuccess,
  addCompanyDocumentFail,
  deleteCompanyDocumentSuccess,
  deleteCompanyDocumentFail,
  getCompanyLocationListSuccess,
  getCompanyLocationListFail,
  addCompanyLocationSuccess,
  addCompanyLocationFail,
  updateCompanyLocationSuccess,
  updateCompanyLocationFail,
  deleteCompanyLocationSuccess,
  deleteCompanyLocationFail,
  getCompanyLocationDetailSuccess,
  getCompanyLocationDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCompanies,
  getCompanyDetails,
  addNewCompany,
  updateCompany,
  deleteCompany,
  getCompanyDocuments,
  addCompanyDocument,
  deleteCompanyDocument,
  getCompanyLocationList,
  getCompanyLocationDetail,
  addCompanyLocation,
  updateCompanyLocation,
  deleteCompanyLocation,
} from "helpers/fakebackend_helper"

function* fetchCompanies() {
  try {
    const response = yield call(getCompanies)

    yield put(getCompaniesSuccess(response))
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
}

function* fetchCompanyDetail({ companyId }) {
  try {
    const response = yield call(getCompanyDetails, companyId)
    yield put(getCompanyDetailSuccess(response))
  } catch (error) {
    yield put(getCompanyDetailFail(error))
  }
}

function* onUpdateCompany({ payload: { id, company } }) {
  try {
    const response = yield call(updateCompany, id, company)
    yield put(updateCompanySuccess(response))
  } catch (error) {
    yield put(updateCompanyFail(error))
  }
}

function* onDeleteCompany({ payload: company }) {
  try {
    const response = yield call(deleteCompany, company)
    yield put(deleteCompanySuccess(response))
  } catch (error) {
    yield put(deleteCompanyFail(error))
  }
}

function* onAddNewCompany({ payload: company }) {
  try {
    const response = yield call(addNewCompany, company)
    yield put(addCompanySuccess(response))
  } catch (error) {
    yield put(addCompanyFail(error))
  }
}

function* fetchCompanyDocuments({ companyId }) {
  try {
    const response = yield call(getCompanyDocuments, companyId)
    yield put(getCompanyDocumentsSuccess(response))
  } catch (error) {
    yield put(getCompanyDocumentsFail(error))
  }
}

function* onAddCompanyDocument({ payload: company }) {
  try {
    const response = yield call(addCompanyDocument, company)
    yield put(addCompanyDocumentSuccess(response))
  } catch (error) {
    yield put(addCompanyDocumentFail(error))
  }
}

function* onDeleteCompanyDocument({ docs }) {
  try {
    const response = yield call(deleteCompanyDocument, docs)
    yield put(deleteCompanyDocumentSuccess(response))
  } catch (error) {
    yield put(deleteCompanyDocumentFail(error))
  }
}

function* fetchCompanyLocationList({ companyId }) {
  try {
    const response = yield call(getCompanyLocationList, companyId)
    yield put(getCompanyLocationListSuccess(response))
  } catch (error) {
    yield put(getCompanyLocationListFail(error))
  }
}
function* fetchCompanyLocationDetail({ companyId }) {
  try {
    const response = yield call(getCompanyLocationDetail, companyId)
    yield put(getCompanyLocationDetailSuccess(response))
  } catch (error) {
    yield put(getCompanyLocationDetailFail(error))
  }
}

function* onAddNewCompanyLocation({ payload: company }) {
  try {
    const response = yield call(addCompanyLocation, company)
    yield put(addCompanyLocationSuccess(response))
  } catch (error) {
    yield put(addCompanyLocationFail(error))
  }
}

function* onUpdateCompanyLocation({ payload: { id, company } }) {
  try {
    const response = yield call(updateCompanyLocation, id, company)
    yield put(updateCompanyLocationSuccess(response))
  } catch (error) {
    yield put(updateCompanyLocationFail(error))
  }
}

function* onDeleteCompanyLocation({ location }) {
  try {
    const response = yield call(deleteCompanyLocation, location)
    yield put(deleteCompanyLocationSuccess(response))
  } catch (error) {
    yield put(deleteCompanyLocationFail(error))
  }
}

function* companysSaga() {
  yield takeEvery(GET_COMPANIES, fetchCompanies)
  yield takeEvery(GET_COMPANY_DETAIL, fetchCompanyDetail)
  yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  yield takeEvery(GET_DOCUMENT_LIST_COMPANY, fetchCompanyDocuments)
  yield takeEvery(ADD_DOCUMENT_COMPANY, onAddCompanyDocument)
  yield takeEvery(DELETE_DOCUMENT_COMPANY, onDeleteCompanyDocument)
  yield takeEvery(GET_COMPANY_LOCATION_LIST, fetchCompanyLocationList)
  yield takeEvery(GET_COMPANY_LOCATION_DETAIL, fetchCompanyLocationDetail)
  yield takeEvery(ADD_COMPANY_LOCATION, onAddNewCompanyLocation)
  yield takeEvery(UPDATE_COMPANY_LOCATION, onUpdateCompanyLocation)
  yield takeEvery(DELETE_COMPANY_LOCATION, onDeleteCompanyLocation)
}

export default companysSaga
