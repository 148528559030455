import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import SimpleBar from "simplebar-react"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  customerAgingReportExcel,
  customerAgingReportModalExcel,
  getAllCustomerAgingReportList,
  getAllProjects,
  getCustomerAgingReport,
} from "helpers/fakebackend_helper"
import formatNumberWithCommas from "components/Common/CommaSeparator"

const CustomerAgingReport = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [customerAgingReportList, setCustomerAgingReportList] = useState([])
  const [projectId, setProjectId] = useState("")
  const [projects, setProjectList] = useState([])
  const [agingListForModel, setAgingListForModel] = useState([])
  const [agingListTotalForModel, setAgingListTotalForModel] = useState({})
  console.log(agingListTotalForModel)
  const [modalOpen, setModalOpen] = useState(false) // Modal visibility state
  const [selectedColumn, setSelectedColumn] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 15

  useEffect(() => {
    if (!modalOpen) {
      setSelectedColumn("")
    }
  }, [modalOpen])
  const toggleModal = (column, customerId, type) => {
    const columnValue = {
      column,
      customerId,
      type,
    }
    setSelectedColumn(columnValue) // Store which column was clicked
    setModalOpen(!modalOpen) // Toggle modal visibility
  }

  // Fetch the customer aging report list when projectId changes
  useEffect(() => {
    const fetchCustomerAgingReportList = async () => {
      try {
        const value = {
          project_id: projectId,
          user_id: user.user_id,
        }
        const response = await getAllCustomerAgingReportList(value)
        setCustomerAgingReportList(response.data)
        setAgingListTotalForModel(response.totals)
      } catch (error) {
        console.error("Error fetching customer aging report list", error)
      }
    }

    if (projectId) {
      fetchCustomerAgingReportList()
    }
  }, [projectId])

  // Fetch all projects when user is available
  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        const response = await getAllProjects(user.user_id)
        setProjectList(response.project)
      } catch (error) {
        console.error("Error fetching project list", error)
      }
    }

    if (user) {
      fetchProjectList()
    }
  }, [user])

  useEffect(() => {
    const fetchModalData = async () => {
      try {
        const value = {
          project_id: projectId,
          days: selectedColumn.column,
          customer_id: selectedColumn.customerId,
        }

        const response = await getCustomerAgingReport(value)
        setAgingListForModel(response.data)
      } catch (error) {
        console.error("Error fetching project list", error)
      }
    }

    if (selectedColumn) {
      fetchModalData()
    }
  }, [selectedColumn])

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  const totalPages = Math.ceil(customerAgingReportList.length / itemsPerPage)
  const displayedData = customerAgingReportList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const handleExport = async () => {
    // Construct the URL for download
    const value = {
      project_id: projectId,
      user_id: user.user_id,
      type: "customerAgingReportList",
    }
    const downloadUrl = await customerAgingReportExcel(value)
  }
  const handleExportModal = async () => {
    // Construct the URL for download
    const value = {
      project_id: projectId,
      days: selectedColumn.column,
      customer_id: selectedColumn.customerId,
      type: "customerAgingDetail",
    }
    const downloadUrl = await customerAgingReportModalExcel(value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Reports" breadcrumbItem="Customer Aging Report" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    Customer Aging Report
                  </CardTitle>
                  <Row>
                    <Col xxl={2} lg={2}>
                      <select
                        className="form-control select2 mb-3 mb-xxl-0"
                        value={projectId}
                        onChange={e => setProjectId(e.target.value)}
                      >
                        <option value="">Select Project</option>
                        {projects.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.project_name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col xxl={2} lg={2}>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm text-end"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Customer Code</th>
                          <th>Customer Name</th>
                          <th>Total Outstanding Amount</th>
                          <th>1-15 Days Due</th>
                          <th>16-30 Days Over Due</th>
                          <th>31-60 Days Over Due</th>
                          <th>61-90 Days Over Due</th>
                          <th>91+ Days Over Due</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayedData.map((report, index) => (
                          <tr key={index}>
                            <td>{report.customer_code}</td>
                            <td>{report.customer_name}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="clickable-column text-end"
                              onClick={() =>
                                toggleModal(
                                  "overdue",
                                  report.customer_id,
                                  "Outstanding Detail"
                                )
                              }
                            >
                              {formatNumberWithCommas(
                                report.total_outstanding_amount
                              )}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="clickable-column text-end"
                              onClick={() =>
                                toggleModal(
                                  "0-15",
                                  report.customer_id,
                                  "1-15 Days Due"
                                )
                              }
                            >
                              {formatNumberWithCommas(
                                report.zero_to_fifteen_days
                              )}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="clickable-column text-end"
                              onClick={() =>
                                toggleModal(
                                  "16-30",
                                  report.customer_id,
                                  "16-30 Days Over Due"
                                )
                              }
                            >
                              {formatNumberWithCommas(
                                report.sixteen_to_thirty_days
                              )}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="clickable-column text-end"
                              onClick={() =>
                                toggleModal(
                                  "31-60",
                                  report.customer_id,
                                  "31-60 Days Over Due"
                                )
                              }
                            >
                              {formatNumberWithCommas(
                                report.thirtyone_to_sixty_days
                              )}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="clickable-column text-end"
                              onClick={() =>
                                toggleModal(
                                  "61-90",
                                  report.customer_id,
                                  "61-90 Days Over Due"
                                )
                              }
                            >
                              {formatNumberWithCommas(
                                report.sixtyone_to_ninety_days
                              )}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              className="clickable-column text-end"
                              onClick={() =>
                                toggleModal(
                                  "90+",
                                  report.customer_id,
                                  "91+ Days Over Due"
                                )
                              }
                            >
                              {formatNumberWithCommas(
                                report.more_than_ninety_days
                              )}
                            </td>
                          </tr>
                        ))}
                        {displayedData && displayedData.length > 0 && (<tr>
                          <th>Total</th>
                          <th></th>
                          <th className=" text-end">
                            {formatNumberWithCommas(parseFloat(agingListTotalForModel.total_outstanding_amount).toFixed(2))}
                          </th>
                          <th className=" text-end">
                            {formatNumberWithCommas(parseFloat(agingListTotalForModel.zero_to_fifteen_days).toFixed(2))}
                          </th>
                          <th className=" text-end">
                            {formatNumberWithCommas(parseFloat(agingListTotalForModel.sixteen_to_thirty_days).toFixed(2))}
                          </th>
                          <th className=" text-end">
                            {formatNumberWithCommas(parseFloat(agingListTotalForModel.thirtyone_to_sixty_days).toFixed(2))}
                          </th>
                          <th className=" text-end">
                            {formatNumberWithCommas(parseFloat(agingListTotalForModel.sixtyone_to_ninety_days).toFixed(2))}
                          </th>
                          <th className=" text-end">
                            {formatNumberWithCommas(parseFloat(agingListTotalForModel.more_than_ninety_days).toFixed(2))}
                          </th>
                        </tr>)}
                      </tbody>
                    </Table>
                  </div>
                  {/* {displayedData && displayedData.length > 0 && ( */}
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <Button
                        color="primary"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>
                      <Button
                        color="primary"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </Button>
                    </div>
                  {/* )} */}
                </CardBody>
              </Card>
            </Col>

            {/* Modal for overdue categories */}
            <Modal
              isOpen={modalOpen}
              toggle={() => setModalOpen(false)}
              className="modal-xl modal-dialog-centered"
            >
              <ModalHeader toggle={() => setModalOpen(false)}>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <span>{selectedColumn.type}</span>
                  
                  <button
                    type="button"
                    className="btn btn-primary btn-sm ms-4"
                    onClick={handleExportModal}
                    style={{ marginLeft: "auto" }} // Ensures the button stays at the right
                    >
                    Export
                  </button>
                   
                </div>
              </ModalHeader>
              <ModalBody>
                {/* Table displaying the overdue data based on selected column */}
                {agingListForModel[0]?.customer_name && (
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <strong>Customer Name : </strong>
                      {agingListForModel[0]?.customer_name}
                    </td>
                  </tr>
                )}
                {agingListForModel[0]?.customer_name && (
                  <tr>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <strong> Customer Code : </strong>{" "}
                      {agingListForModel[0]?.customer_code}
                    </td>
                  </tr>
                )}

                <Table className="table">
                  <thead>
                    <tr>
                      <th>Invoice No.</th>
                      <th>Invoice Date</th>
                      <th>Invoice Amount</th>
                      <th>Due Date</th>
                      <th>Credit Amount</th>
                      <th>Payment Amount</th>
                      <th>TDS</th>
                      <th>Outstanding Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(agingListForModel || []).map((agingModelData, index) => (
                      <tr key={index}>
                        <td>{agingModelData.bill_no} </td>
                        <td>{agingModelData.formatted_bill_date}</td>
                        <td className="text-end">
                          {formatNumberWithCommas(agingModelData.all_total)}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {agingModelData.formatted_due_date}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(agingModelData.credit_amount)}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(agingModelData.amount_rcd)}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(agingModelData.amount_withheld)}
                        </td>
                        <td className="text-end">
                          {formatNumberWithCommas(
                            agingModelData.outstanding_amount
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => setModalOpen(false)}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomerAgingReport
